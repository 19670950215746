/* =Components: Poll geolocation
===================================================== */

/*
 * Poll geolocation
 *
 * Poll geolocation
 */

.c-poll-geolocation {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;

  text-align: center;

  width: 100%;
  max-width: $question-width-narrow;

}

.c-poll-geolocation__illustration {

  width: 12rem;
  margin-bottom: $unit-m;

  transform-origin: center center;

  @include breakpoint(small) {

    width: 16rem;

  }

}

.c-poll-geolocation__title {

  margin-bottom: $unit-xs;

  font-size: 1.25rem;
  font-weight: $bold;
  letter-spacing: -.0125em;

  @include breakpoint(small) {

    font-size: 1.5rem;

  }
  @include breakpoint(medium) {

    font-size: 1.75rem;

  }

}

.c-poll-geolocation__content {

  text-align: left;

  @include rtl {

    direction: rtl;
    text-align: right;

  }

}