/* =Components: Marketing logo list
===================================================== */

.c-m-logo-list {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;

}

.c-m-logo-list__item {

  margin-top: 0;

  opacity: .8;

}

.c-m-logo-list {

  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  margin-bottom: 0;

  @include breakpoint(medium) {

    justify-content: space-between;

  }

  @include breakpoint(large) {

    flex-flow: row nowrap;

  }

  .c-m-block--dark & {

    filter: invert(1);
    opacity: .9;

  }

}

.c-m-logo-list__item {

  margin: 0 .5rem;

  img {

    height: 5rem;
    display: block;
    margin-bottom: 0;

  }

  @include breakpoint(large) {

    margin: 0;

    img {

      height: 6rem;

    }

  }

}

/*
 * Modifiers
 */

.c-m-logo-list--row {

  height: 5rem;
  overflow: hidden;

  @include breakpoint(large) {

    height: 6rem;

  }

}