/* =Objects: Cards grid
===================================================== */

/*
 * Grid cards
 *
 * Shows different cards in a grid
 */

.l-grid-cards {

  @include breakpoint(medium) {

    margin-bottom: $unit-xl;

  }

  &:last-child {

    margin-bottom: 0;

  }

  /*
   * Flexbox layout
   *
   * Using flexbox to createa a layout with columns
   * for project cards.
   */

  display: flex;
  flex-flow: row wrap;

  @include breakpoint(medium) {

    justify-content: space-between;

    margin-left: -1.5%;
    margin-right: -1.5%;
    margin-top: -1.5%;
    margin-bottom: -1.5%;

    > * {

      width: 47%;
      margin: 1.5%

    }

  }

  /*
   * CSS Grid layout
   *
   * If the browser supports CSS Grid, we are using it
   * to enhance the layout (and override flexbox).
   */

  @supports (display: grid) {

    display: grid;
    grid-row-gap: $unit-m;
    grid-column-gap: $unit-m;
    margin-bottom: $unit-m;

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr;
      grid-row-gap: $unit-xl;
      grid-column-gap: $unit-xl;

      margin: 0;

      > * {

        width: 100%;
        margin: 0;

      }

      .c-card-poll--figure {

        grid-row: span 3;

      }

      .c-card-feedback,
      .c-card-panel--figure {

        grid-column: span 2;

      }

    }

  }

}