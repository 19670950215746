/* =Objects: Video
===================================================== */

.o-video {

  position: relative;

  // 800x480: Default landscape image size
  // similar to the 16:9 video aspect ratio (800x450)
  padding-bottom: 60%;

  background-color: $color-primary-light;

  iframe {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

  }

}