/* =Components: Section panel
===================================================== */

/*
 * Card panel
 *
 * Small panel card with title, description, image and action
 */

.c-section-panel {

  @include responsive-spacing(padding-top, $container-proportion);
  @include responsive-spacing(padding-bottom, $container-proportion);
  @include responsive-spacing(margin-bottom, $container-proportion);

  &:first-child {

    @include responsive-spacing(margin-top, $container-proportion, negative);

  }

  .l-app--blocks & {

    @include responsive-spacing(margin-top, $container-proportion, negative);
    @include responsive-spacing(margin-bottom, $container-proportion, negative);
    border-bottom: .0625rem solid darken($color-primary-light, 5%);

  }

  /* Fallback layout */
  display: flex;
  flex-direction: column;

  /* CSS Grid layout */
  @supports (display: grid) {

    display: grid;
    grid-gap: $unit-m;

    @include breakpoint(medium) {

      grid-gap: $unit-l;

    }

  }

  /* Styles */
  box-shadow:
    10rem 0 0 0 $color-primary-light,
    -10rem 0 0 0 $color-primary-light,
    40rem 0 0 0 $color-primary-light,
    -40rem 0 0 0 $color-primary-light,
    60rem 0 0 0 $color-primary-light,
    -60rem 0 0 0 $color-primary-light,
    80rem 0 0 0 $color-primary-light,
    -80rem 0 0 0 $color-primary-light,
    100rem 0 0 0 $color-primary-light,
    -100rem 0 0 0 $color-primary-light;
  background-color: $color-primary-light;

  text-align: left;

  @include rtl{

    direction: rtl;
    text-align: right;

  }

}

.c-section-panel__figure {

  overflow: hidden;

  margin-bottom: 0;

  background-color: $color-button-regular;
  border-radius: $border-radius-small;

  img {

    @include object-fit();

    margin-bottom: 0;
    max-width: none;
    width: 100%;
    display: block;

    transition: all .1s ease-in;

  }

  /* Fallback layout */
  margin-bottom: $unit-m;

  /* CSS Grid layout */
  @supports (display: grid) {

    margin-bottom: 0;

  }

}

.c-section-panel__title {

  margin-top: 0;
  margin-bottom: 0;

  font-size: ms(0);

  @include breakpoint(extra-small) {

    align-self: center;

    font-size: ms(1);

  }

}

.c-section-panel__description {

  margin-top: $unit-s;
  margin-bottom: 0;

  &:first-child {

    margin-top: 0;

  }

  p {

    margin-bottom: 0;
    margin-top: 0;

    font-family: $font-body;
    font-size: ms(-1);
    line-height: ms(2);

    color: $color-text;

  }

  @include breakpoint(small) {

    p {

      font-size: ms(0);

    }

  }

}

.c-section-panel__action {

  margin-top: $unit-m;

  &:first-child {

    margin-top: 0;

  }

}

/*
 * Modifiers
 */

.c-section-panel--figure {

  /* Fallback layout */
  @include breakpoint(medium) {

    flex-direction: row;
    align-items: center;

    .c-section-panel__figure {

      flex: 1;
      margin-bottom: 0;
      padding-right: $unit-m;

    }

    .c-section-panel__content {

      flex: 1;
      padding-left: $unit-m;

    }

  }

  /* CSS Grid layout */
  @supports (display: grid) {

    display: grid;
    align-items: center;

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr;

      .c-section-panel__figure,
      .c-section-panel__content {

        padding: 0;
        margin: 0;

      }

    }

  }

}

.c-section-panel--figure.c-section-panel--compact {

  /* Fallback layout */
  @include breakpoint(extra-small) {

    flex-direction: row;
    align-items: center;

    .c-section-panel__figure {

      flex: 1;
      margin-bottom: 0;
      padding-right: $unit-s;

    }

    .c-section-panel__content {

      flex: 2;
      padding-left: $unit-s;

    }

  }

  @include breakpoint(medium) {

    .c-section-panel__figure {

      flex: 1;
      padding-right: $unit-m;

    }

    .c-section-panel__content {

      flex: 3;
      padding-left: $unit-m;

    }

  }

  /* CSS Grid layout */
  @supports (display: grid) {

    display: grid;
    align-items: center;

    .c-section-panel__figure,
    .c-section-panel__content {

      padding: 0;
      margin: 0;

    }

    @include breakpoint(extra-small) {

      grid-template-columns: 1fr 2fr;

    }

    @include breakpoint(medium) {

      grid-template-columns: 1fr 3fr;

    }

  }

  .c-section-panel__description {

    @include breakpoint(small) {

      p {

        font-size: ms(-1);

      }

    }

    @include breakpoint(medium) {

      p {

        font-size: ms(0);

      }

    }

  }

}

.c-section-panel--compact {

  .c-section-panel__title {

    font-size: ms(1);

  }

  .c-section-panel__description {

    font-size: ms(0);

  }

}