/* =Components: Poll charities
===================================================== */

/*
 * Poll charities
 *
 * Pick between charities
 */

.c-poll-charities {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;

}

.c-poll-charities__illustration {

  margin-bottom: $unit-m;

  img {

    display: block;
    width: 28rem;
    max-width: 100%;
    margin-bottom: 0;

  }

  // Deprecating old SVG illustration with animation

  // width: 12rem;
  // margin-bottom: $unit-m;

  // transform-origin: center center;

  // @include breakpoint(small) {

  //   width: 16rem;

  // }

  // * {

  //   transform-origin: center;
  //   transform-box: fill-box;

  // }

  //
  // #person,
  // #scene,
  // #confetti {

  //   animation: explainer-popup .5s ease-in-out;
  //   animation-fill-mode: backwards;

  // }

  // #scene {

  //   animation-delay: .5s;

  // }

  // #person {

  //   animation-delay: 1s;

  // }

  // @for $i from 1 through 11 {

  //   #confetti-#{$i} {

  //     animation:
  //       explainer-pulse 1s infinite ease-in;

  //     animation-delay: ($i * random())#{s};

  //   }

  // }

  // #person__eyes1,
  // #person__eyes {

  //   animation: explainer-eyes-blink 2s infinite;

  // }

}

.c-poll-charities__title {

  margin-bottom: $unit-xl;

  font-size: 1.25rem;
  font-weight: $bold;
  letter-spacing: -.0125em;

  text-align: center;

  @include breakpoint(small) {

    font-size: 1.5rem;

  }
  @include breakpoint(medium) {

    margin-bottom: $unit-l;

    font-size: 1.75rem;

  }

}

.c-poll-charities__list {

  display: flex;
  flex-direction: column;
  width: 100%;

  .c-choice-charity {

    margin-bottom: $unit-m;

  }

  @include breakpoint(large) {

    flex-direction: row;
    align-items: stretch;

    margin-left: 0 - $unit-s;
    margin-right: 0 - $unit-s;
    margin-top: 0 - $unit-s;
    margin-bottom: 0 - $unit-s;

    .c-choice-charity {

      display: flex;
      flex: 1;
      margin: $unit-s;

    }

    .c-choice-charity label {

      height: 100%;

    }

  }

}