/* =Components: Choice reorder
===================================================== */

/*
 * Choice reorder
 *
 * Reorder choices
 */

.c-choice-reorder {

  // display: flex;
  // align-items: center;

  margin: $unit-s 0;
  padding: $unit-m;
  padding-right: rem(48);

  background-color: $color-primary-light;
  border-radius: $border-radius-small;
  box-shadow: inset 0 0 0 .0625rem darken($color-primary-light, 10%);

  cursor: grab;
  user-select: none;
  
  position: relative;
  
  @include rtl {
    padding-right: $unit-m;
    padding-left: rem(48);
  }

  .o-icon {

    font-size: 1.25rem;
    min-width: 1.25rem;
    margin-left: auto;

    transition: $transition-default;
    
    position: absolute;
    top: 50%;
    right: $unit-m;
    transform: translateY(-50%);
    
    @include rtl {
      right: auto;
      left: $unit-m;
    }

  }

  &:hover {

    box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);

  }

  &:active {

    cursor: grabbing;

    color: $color-text-weak;

    .o-icon {

      transform: scale(1.1);

    }

  }

}

.c-choice-reorder__title {

  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  font-weight: $semibold;
  font-family: $font-body;

  @include rtl {
    text-align: right;
  }

}

.c-choice-reorder--helper {

  z-index: $z-index-question;
  z-index: 1200; // After new modals, this might not be necessary

  box-shadow: $box-shadow-large, inset 0 0 0 .125rem lighten($color-primary-dark, 10%);
  color: $color-text-weak;

  cursor: grabbing;

  .o-icon {

    transform: scale(1.1);

  }

}

/*
 * Reorder animations: Transform
 *
 * Note: Not used
 */

// @keyframes reorder-visual-cue {

//   0% {

//     box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);
//     opacity: .7;

//   }

//   20% {

//     transform: translateY(.5rem);
//     box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);

//   }

//   40% {

//     transform: translateY(-.5rem);
//     box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);

//   }

//   60% {

//     transform: translateY(0);
//     box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);

//   }

//   80% {

//     box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);
//     opacity: .7;

//   }

// }

// .u-a-reorder-move-cue {

//   .c-choice-reorder:nth-child(2) {

//     transition: $transition-default;

//     animation:
//       reorder-visual-cue 2.5s $transition-timing-cubic 2s;

//   }

// }


/*
 * Reorder animations: Swapping
 *
 * Note: Deprecated (See v2 description)
 */

// @keyframes reorder-swap {

//   0% {

//     transform: translate3d(0, 0, 0);

//   }

//   100% {

//     transform: translate3d(0, 4rem, 0);

//   }

// }

// @keyframes reorder-swap-highlight {

//   0% {

//     transform: translate3d(0, 0, 0);

//   }

//   20% {

//     transform: translate3d(0, 0, 0);
//     box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);
//     opacity: .5;

//   }

//   90% {

//     transform: translate3d(4rem, -3.5rem, 0);
//     box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);
//     opacity: .5;

//   }

//   100% {

//     transform: translate3d(0, -4rem, 0);
//     box-shadow: inset 0 0 0 .0625rem darken($color-primary-light, 10%);
//     opacity: 1;

//   }

// }

// @keyframes reorder-swap-back-highlight {

//   0% {

//     transform: translate3d(0, -4rem, 0);

//   }

//   100% {

//     transform: translate3d(0, 0, 0);

//   }

// }

// .u-a-reorder-swap-cue {

//   .c-choice-reorder:nth-child(1) {

//     animation:
//       reorder-swap .5s ease-out forwards 2s,
//       reorder-swap .25s ease-out reverse forwards 3s;

//   }

//   .c-choice-reorder:nth-child(2) {

//     animation:
//       reorder-swap-highlight 1s ease-out forwards 1.5s,
//       reorder-swap-back-highlight .25s ease-out forwards 3s;

//   }

// }


/*
 * Reorder animations
 *
 * Since reorder items are interactive elements, the cue
 * animation shouldn't interfere with the interaction of
 * these elements. That's why I'm abanboning the inital
 * approach of using `animation-fill-mode` and several
 * chained smaller animations to do the whole cue.
 * Now we have only two animations, one for the first and
 * the other for the second element. They are of the same
 * duration, but are timed on the keyframe (%) level.
 */

@keyframes reorder-swap-v2 {

  // Scene: Item base position (doesn't start right away)
  30% {

    transform: translate3d(0, 0, 0);

  }

  // Scene: Item swapped
  40% {

    transform: translate3d(0, 4rem, 0);

  }

  // Scene: Item base position
  80% {

    transform: translate3d(0, 0, 0);

  }

}

@keyframes reorder-swap-highlight-v2 {

  // Scene: Item base position
  0% {

    transform: translate3d(0, 0, 0);

  }

  // Scene: Item almost there
  20% {

    transform: translate3d(0, 0, 0);
    box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);

  }

  // Scene: Item swapped
  30% {

    transform: translate3d(4rem, -3.5rem, 0);
    box-shadow: inset 0 0 0 .125rem lighten($color-primary-dark, 10%);

  }

  // Scene: Item effect reset
  40% {

    transform: translate3d(0, -4rem, 0);
    box-shadow: inset 0 0 0 .0625rem darken($color-primary-light, 10%);

  }

  // Scene: Item base position
  80% {

    transform: translate3d(0, 0, 0);

  }

}

.u-a-reorder-swap-cue {

  // Original numbers for reference
  // Duration: 2.25s
  // Delay: .75s

  .c-choice-reorder:nth-child(1) {

    animation: reorder-swap-v2 3.25s cubic-bezier(0.88,-0.01, 0.17, 1.01) 1s;

  }

  .c-choice-reorder:nth-child(2) {

    animation: reorder-swap-highlight-v2 3.25s cubic-bezier(0.88,-0.01, 0.17, 1.01) 1s;

  }

}