/* =Components: Charity
===================================================== */

.c-charity {

  position: relative;

  display: flex;
  align-items: flex-start;

  width: 100%;
  margin-top: $unit-m;
  min-height: 3rem;

  text-align: left;

  &:first-child {

    margin-top: 0;

  }

  @include rtl {

    direction: rtl;
    text-align: right;

  }

  @include breakpoint(large) {

    flex-direction: column;
    align-items: center;
    margin-top: 0;

    text-align: center;

    @include rtl {

      text-align: center;
      direction: rtl;

    }

  }

}

.c-charity__logo {

  margin-right: $unit-s;
  margin-bottom: 0;
  width: 3rem;

  @include rtl {

    margin-right: 0;
    margin-left: $unit-s;

  }

  img {

    margin-bottom: 0;
    max-width: 100%;
    display: block;

    border-radius: $border-radius-small;
    box-shadow: $box-shadow-medium;

  }

  @include breakpoint(extra-small) {

    margin-right: $unit-m;

    @include rtl {

      margin-left: $unit-m;

    }

  }

  @include breakpoint(medium) {

    width: 4rem;

  }

  @include breakpoint(large) {

    margin-right: 0;
    margin-bottom: $unit-m;
    width: 5rem;

    @include rtl {

      margin-left: 0;

    }

  }

}

.c-charity__title {

  margin-bottom: 0;

  font-size: .875rem; // 14px
  line-height: 1.7142857143; // 24/14
  font-weight: $semibold;
  font-family: $font-body;
  text-align: inherit;

  @include breakpoint(small) {

    font-size: 1rem;
    line-height: 1.5;

  }

}

.c-charity__content {

  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

}

.c-charity__points {

  position: relative;

  margin-bottom: 0;

  font-size: .875rem; // 14px
  line-height: 1.7142857143; // 24/14

  .o-icon {

    position: relative;
    top: .125rem;

  }

  @include breakpoint(small) {

    font-size: .875rem; // 14/16
    line-height: 1.7142857143; // 24/14

  }

  @include breakpoint(medium) {


  }

}

.c-charity__description {

  margin-top: $unit-xs;
  margin-bottom: 0;

  font-size: .8125rem; // 13px
  line-height: 1.5384615385; // 20/13
  color: $color-text-weak;

  @include breakpoint(small) {

    font-size: .875rem;
    line-height: 1.4285714286;

  }

}


/*
 * Switchable
 *
 * Switchable states on mobile and desktop
 */

.c-charity--switchable {

  @include breakpoint(to-large) {

    .c-charity__toggle {

      position: absolute;
      z-index: $z-index-main;
      top: 0 - $unit-xs;
      right: 0;

      width: 2rem;
      height: 2rem;
      padding: 0;

      border: none;
      background-color: transparent;
      border-radius: 50%;

      @include rtl {

        right: auto;
        left: 0;

      }

      .c-button__label {

        @include hide-visually();

      }

      .o-icon {

        font-size: 1rem;

      }

      &:before {

        position: absolute;
        top: -1rem;
        right: -1rem;
        bottom: -1rem;
        left: -1rem;

        content: "";

      }

      @include states {

        background-color: $color-link;
        color: $color-text-strong-invert;

      }

    }

    .c-charity__content {

      overflow: hidden;

      padding-right: 2rem;

      @include rtl {

        padding-right: 0;
        padding-left: 2rem;

      }

      &:before {

        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;

        height: $unit-s;
        background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 70%);

        content: "";

        @include breakpoint(large) {

          display: none;

        }

      }

    }

    &.is-opened {

      .c-charity__toggle .o-icon {

        transform: rotate(180deg);

      }

      .c-charity__content {

        max-height: 20rem;

        &:before {

          opacity: 0;

        }

      }

    }

    &.is-closed {

      .c-charity__content {

        max-height: 3rem;

      }

    }

  }

  @include breakpoint(large) {

    .c-charity__toggle {

      display: none;

    }

  }

}