/* =Components: Slider
===================================================== */

/*
 * Slider
 *
 * Slider control that is used for distribute
 * and slider (emoji) questions.
 */

$slider-control-height: 3rem;
$slider-indicator-height: .375rem;
$slider-indicator-handle: 2rem;
$slider-indicator-handle-community: 1.25rem;
$slider-emoji: 2rem;
$slider-emoji-average-label-offset: 3rem;
$slider-emoji-average-label-offset-wide: 4rem;

.c-slider {

  display: flex;
  flex-direction: column;
  flex-shrink: 0; // Fixes an iOS bug with height on small screens
  margin-top: auto;
  margin-bottom: auto;

  padding-left: $slider-indicator-handle * 0.5;
  padding-right: $slider-indicator-handle * 0.5;

  @include rtl {

    direction: rtl;

  }

}

.c-slider__label {

  margin-bottom: $unit-s;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  font-weight: $semibold;
  text-align: left;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

}

.c-slider__group {

  display: flex;

}

.c-slider__control {

  position: relative;

  display: flex;
  width: 100%;
  height: $slider-control-height;

  // background: red;

  input[type="range"] {

    position: relative;
    z-index: $z-index-question;

    width: 100%;
    height: $slider-control-height;

    opacity: 0;
    cursor: pointer;

  }

}

.c-slider__control input[type="range"]:focus + .c-slider__indicator {

  background-color: darken($color-primary-light, 10%);

  .c-slider__handle {}

}

.c-slider__control input[type="range"]:hover + .c-slider__indicator {

  .c-slider__handle {

    transform: scale(1.1);

  }

}

.c-slider__control input[type="range"]:focus + .c-slider__indicator,
.c-slider__control input[type="range"]:hover + .c-slider__indicator {

  // .c-slider__emoji {

  //   margin-bottom: $unit-s;
  //   transform: scale(1.25);

  // }

}

.c-slider__indicator {
  
  $handle: 32px;

  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  height: $slider-indicator-height;
  display: flex;
  align-items: center;

  border-radius: $slider-indicator-height * 0.5;
  background: $color-primary-light;
  transition: $transition-default;
  
  //  Following two lines were added to fix the indicator
  //  handle going outside clickable area
  width: calc(100% - 32px);
  left: rem(calc($handle/2));
  
  &:before {
  //  Following :before styles were added to fix the indicator
  //  handle going outside clickable area
    content: "";
    position: absolute;
    top: 0;
    left: -rem(calc($handle/2));
    right: -rem(calc($handle/2));
    height: rem(6);
    border-radius: $slider-indicator-height * 0.5;
    background: $color-primary-light;
    z-index: 2;
  }

  label {

    position: relative;
    display: block;

    height: $slider-indicator-height;
    border-radius: $slider-indicator-height * 0.5;

    background-color: $color-accent-green;
    
    &:before {
      //  Following :before styles were added to fix the indicator
      //  handle going outside clickable area
      content: "";
      position: absolute;
      top: 0;
      left: -rem(calc($handle/2));
      right: -rem(calc($handle/2));
      z-index: 10;
      height: rem(6);
      border-radius: $slider-indicator-height * 0.5;
      background: $color-accent-green;
    }

  }

}

.c-slider__handle {

  position: absolute;
  top: 50%;
  right: 0;

  width: $slider-indicator-handle;
  height: $slider-indicator-handle;
  margin-top: 0 - ($slider-indicator-handle * 0.5);
  margin-right: 0 - ($slider-indicator-handle * 0.5);

  border-radius: $slider-indicator-handle * 0.5;
  border: .25rem solid #fff;

  background-color: $color-primary-dark;
  box-shadow: $box-shadow-medium;
  transition: $transition-default;

  z-index: 10;

  @include rtl {

    right: auto;
    left: 0;

    margin-left: 0 - ($slider-indicator-handle * 0.5);
    margin-right: 0;

  }

}

.c-slider__value {

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: $unit-l;
  padding-left: $unit-xs;
  padding-right: $unit-xs;
  min-width: 3rem;
  min-height: 3rem;

  font-size: 1rem;
  line-height: 1.5;
  text-align: center;

  background-color: $color-primary-light;
  border-radius: $border-radius-small;

  @include rtl {

    margin-right: $unit-l;
    margin-left: 0;

  }

}

/*
 * Community styles
 */

.c-slider__community {

  position: absolute;
  left: 0;
  top: 0;

  display: flex;

  height: $slider-indicator-height;

  opacity: 0;
  transition: $transition-default;
  pointer-events: none;

  @include rtl {

    left: auto;
    right: 0;

  }

}

.c-slider__community-handle {

  position: absolute;
  top: 50%;
  right: 0;
  transform: scale(.2);

  width: $slider-indicator-handle-community;
  height: $slider-indicator-handle-community;
  margin-top: 0 - ($slider-indicator-handle-community * 0.5);
  margin-right: 0 - ($slider-indicator-handle-community * 0.5);

  border-radius: $slider-indicator-handle-community * 0.5;
  border: none;

  background-color: darken($color-accent-green, 20%);
  transition: $transition-default;

  @include rtl {

    right: auto;
    left: 0;

    margin-left: 0 - ($slider-indicator-handle-community * 0.5);
    margin-right: 0;

  }

  &:before {

    opacity: 0;

  }

}


/*
 * Modifiers
 */

.c-slider.is-result {

  pointer-events: none;

  .c-slider__handle {

    animation: none;

  }

  .c-slider__community {

    opacity: 1;

  }

  .c-slider__community-handle {

    transform: scale(1);

  }

}

/*
 * Modifier: Distribute
 *
 * Component description
 */

.c-slider--distribute {


}

/*
 * Modifier: Emoji
 *
 * Several changes:
 * - Make more room for emoji
 * - Hide numberical value
 * - Restyle the label
 * - Show emoji as a background
 */

.c-slider--emoji {

  flex-direction: column-reverse; // Put label on the bottom
  padding-top: 4rem; // Make room for emoji

  input[type="range"] {

    margin-top: -3rem;
    height: $slider-control-height + 3rem;

  }

  .c-slider__value {

    @include hide-visually();

  }

  .c-slider__label {

    font-size: .75rem; // 12
    line-height: 1.6666666667; // 20/12
    color: $color-text-weak;
    font-weight: $regular;
    text-align: center;

    margin-bottom: 0;

    @include breakpoint(small) {

      font-size: .875rem;

    }

  }

  /*
   * Emojis
   *
   * 1. Define sizes for emojis based on a image sprite
   * 2. Define number of emoji states (first state is no action) NOTE: currently not in use
   */

  $emoji-number: 8;
  $emoji-size: 64px;

  .c-slider__handle:before {

    position: absolute;
    bottom: 100%;
    left: 50%;

    display: block;
    width: $emoji-size;
    height: $emoji-size;
    margin-left: -($emoji-size * 0.5);

    content: "";

    background-image: assets-path('/images/interface/slider-emoji.png');
    background-repeat: no-repeat;
    background-size: ($emoji-size * $emoji-number) $emoji-size;

    // Slider will always start at 50% (neutral) so we are showing the
    // neutral emoji (number 4).
    background-position: -($emoji-size * 4) 50%;


  }

  // Loop through all states,

  @for $emoji-number from 1 through $emoji-number {

    .c-slider__handle--emoji-#{$emoji-number}:before { background-position: -($emoji-size * $emoji-number) 0; }

  }

  .c-slider__community:before {

    display: flex;
    align-items: center;

    padding: $unit-xs $unit-m;
    margin-top: 2rem;
    min-height: 2rem;

    font-size: .875rem; // 14/16
    line-height: 1.4285714286; // 20/14
    font-weight: $semibold;
    white-space: nowrap;

    background-color: $color-accent-green;
    color: $color-text-strong;
    border-radius: $border-radius-small;
    border-radius: 1rem;

    content: attr(data-title);

    /* Positioning of the label */
    position: relative;
    margin-left: auto;
    position: relative;
    left: 0 - ($slider-emoji-average-label-offset * 0.5); // Added for minimum [0%] case
    margin-right: 0 - $slider-emoji-average-label-offset; // Added for maximum [100%] case

    @include rtl {

      left: auto;
      margin-right: auto;
      right: 0 - ($slider-emoji-average-label-offset * 0.5); // Added for minimum [0%] case
      margin-left: 0 - $slider-emoji-average-label-offset; // Added for maximum [100%] case

    }

    @include breakpoint(medium) {

      left: 0 - ($slider-emoji-average-label-offset-wide * 0.5); // Added for minimum [0%] case
      margin-right: 0 - $slider-emoji-average-label-offset-wide; // Added for maximum [100%] case

      @include rtl {

        right: 0 - ($slider-emoji-average-label-offset-wide * 0.5); // Added for minimum [0%] case
        margin-left: 0 - $slider-emoji-average-label-offset-wide; // Added for maximum [100%] case

      }

    }

  }

  .c-slider__community:after {

    position: absolute;
    top: 1rem;
    left: 100%;
    margin-left: -.5rem;

    border: .5rem solid transparent;
    border-bottom-color: $color-accent-green;

    content: "";

    @include rtl {

      left: auto;
      right: 100%;

      margin-left: 0;
      margin-right: -.5rem;

    }

  }

  &.is-result {

    .c-slider__label {

      opacity: 0;

    }

  }

}

/*
 * Slider animations
 */

@keyframes slider-handle-move {

  0%,
  50%,
  70% {

    transform: translateX(0) scale(1);

  }

  10% {

    transform: scale(1.2);

  }

  20% {

    transform: scale(1);

  }

  40%,
  60% {

    transform: translateX(1rem);

  }

}

.u-a-slider-move-cue {

  .c-slider__handle {

    position: absolute;
    animation:
      slider-handle-move 2s ease 1 1s;

  }

}

/*
 * Media
 */

.c-slider-media {

  padding-bottom: 60%;

  img {

    width: 100%;
    display: block;
    margin-bottom: -60%;

    border-radius: $border-radius-small;

  }

}