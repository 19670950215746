/* =Components: Marketing Logo
===================================================== */

.c-m-logo {

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-top: 0;
  min-height: 3rem;

  font-size: 1.5rem;
  line-height: 1.5;
  font-family: $font-headline;
  font-weight: $semibold;
  color: inherit;

  @include rtl {

    direction: rtl;

  }

  svg {

    width: 2rem;
    height: 2rem;
    margin-right: .5rem;

    stroke: currentColor;
    fill: currentColor;

    @include rtl {

      margin-right: 0;
      margin-left: .5rem;

    }

  }

  @include breakpoint(medium) {

    justify-content: flex-start;

  }

  
  .c-m-header & {
    text-indent: -999rem;
    font-size: 0;
    
    svg {
      width: rem(170);
      height: rem(48);
      transform: scale(1) !important;
    }
    
    @include breakpoint(medium) {
      
      svg {
        width: rem(220);
        height: rem(64);
      }
    }
  }

}

.c-m-logo__link {

  display: flex;
  color: inherit;

  svg {

    // transition: all .1s ease-in;

  }

  &:hover,
  &:focus,
  &:active {

    color: inherit;

    svg {

      transform: scale(1.05);

    }

  }



}

.c-m-logo--footer {

  font-size: 1rem;

  svg {

    width: 1.5rem;
    height: 1.5rem;

  }

}

.c-m-logo__powered {

  margin-left: .5rem;

  font-size: .875rem;
  line-height: 1.5;
  font-weight: $regular;
  color: $color-text-weak;

  @include rtl {

    margin-left: 0;
    margin-right: .5rem;

  }

}