/* =Components: Homepage — Media
===================================================== */

/*
 * Media
 *
 * Gallery of images and videos
 */

.c-media {

  margin-bottom: $container-unit-small * 2;

  @include breakpoint(medium) {

    margin-bottom: $container-unit-medium * 2;

  }

  @include breakpoint(large) {

    margin-bottom: $container-unit-large * 2;

  }

  &:last-child {

    margin-bottom: 0;

  }

}

.c-media__heading {

  margin-top: 0;
  margin-bottom: $unit-m;

  font-size: ms(0);
  line-height: ms(1);

  @include breakpoint(small) {

    font-size: ms(1);
    line-height: ms(2);

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-l;

  }

  .c-block--dark & {

    color: $color-text-strong-invert;

  }

}

.c-media__carousel {



}

.c-media__items {

  background-color: #fff;

  overflow: hidden;

}

.c-media__item {}

.c-media__figure {

  position: relative;

  margin-bottom: 0;

  border-radius: $border-radius-small;

  img {

    display: block;
    width: 100%;

    border-radius: $border-radius-small;

  }

  @include breakpoint(medium) {

    img {

      border-radius: $border-radius-medium

    }

  }

  &.c-media__figure--caption {

    margin-bottom: $unit-m;

    @include breakpoint(small) {

      margin-bottom: $unit-l;

    }

    @include breakpoint(medium) {

      margin-bottom: $unit-xl;

    }


  }

}

.c-media__caption {

  position: absolute;
  bottom: 0 - $unit-m;
  left: $unit-m;
  right: $unit-m;

  display: block;
  margin: 0;
  padding: $unit-m;
  border-radius: $border-radius-small;

  font-family: $font-headline;
  font-size: ms(-1);
  line-height: ms(2);
  text-align: center;

  background-color: $color-primary-dark;
  color: $color-text-strong-invert;

  @include breakpoint(small) {

    left: $unit-l;
    right: $unit-l;
    bottom: 0 - $unit-l;

    font-size: ms(0);
    line-height: ms(1);

  }

  @include breakpoint(medium) {

    left: $unit-xl;
    right: $unit-xl;
    bottom: 0 - $unit-xl;

    font-size: ms(1);
    line-height: ms(2);

  }

  a {

    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {

      text-decoration: none;

    }

  }

}

.c-media__video {

  height: 100%;

  position: relative;

  margin-bottom: 0;

  border-radius: $border-radius-small;
  overflow: hidden;

  &:before {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: transparentize($color-primary-dark, .5);

    content: "";

  }

}