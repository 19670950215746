/* =Components: Card influenced decision
===================================================== */

/*
 * Influenced decisions
 *
 * Influenced decision card that is used in the listing
 */

.c-card-influenced {

  position: relative;

  padding: $unit-l $unit-m;

  background-color: $color-primary-light;

  transition: all .1s ease-in;

  @include rtl {

    direction: rtl;
    text-align: right;

  }

  @include breakpoint(to-small) {

    margin-left: 0 - $container-unit-small;
    margin-right: 0 - $container-unit-small;
    margin-bottom: $unit-l;

  }

  @include breakpoint(small) {

    padding: $unit-l;
    margin-bottom: $unit-l;

    border-radius: $border-radius-medium;

  }

  &:hover {

    // box-shadow: $box-shadow-large;

    // .c-card-influenced__figure img {

    //   transform: scale(1.025);

    // }

  }

}

.c-card-influenced + .c-card-influenced {

  @include breakpoint(to-small) {

    margin-top: 0 - $unit-l;
    border-top: .0625rem solid darken($color-primary-light, 5%);

  }

}

.c-card-influenced__content {

  text-align: left;

}

.c-card-influenced__figure {

  // display: none;

  flex: 1;
  width: 100%;
  margin-bottom: $unit-m;
  overflow: hidden;

  background-color: $color-button-regular;

  border-radius: $border-radius-small;

  img {

    @include object-fit();

    margin-bottom: 0;
    max-width: none;
    width: 100%;
    display: block;

    transition: all .1s ease-in;

  }

}

.c-card-influenced__title {

  margin-top: 0;
  margin-bottom: $unit-xs;

  font-size: ms(0);

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(extra-small) {

    align-self: center;

    font-size: ms(1);

  }

  @include breakpoint(small) {

    // font-size: ms(2);
    margin-bottom: $unit-s;

  }

}

.c-card-influenced__meta {

  margin-bottom: $unit-xs;

  font-size: ms(-1);
  line-height: ms(2);
  text-transform: uppercase;
  font-weight: $semibold;
  color: $color-text-weak;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(small) {

    margin-bottom: $unit-s;

  }

}

.c-card-influenced__summary {

  p {

    margin-bottom: 0;

    font-size: ms(-1);
    line-height: ms(2);

    @include rtl {

      text-align: right;
      direction: rtl;

    }

    @include breakpoint(medium) {

      font-size: ms(0);

    }

  }

}

/*
 * Modify spacing
 *
 * If c-card-news preceeds c-card-influenced, pull the c-card-influenced
 * to the start of the news and ignore the news margin.
 */

.c-card-news + .c-card-influenced {

  @include breakpoint(to-small) {

    margin-top: 0 - ($unit-l + .0625rem);

  }

}