/* =Components: Choice charity
===================================================== */

/*
 * Choice charity
 *
 * Pick charity to donate
 */

.c-choice-charity {

  position: relative;

  label {

    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $unit-s;
    padding-left: $unit-s + 4rem + $unit-s;

    font-family: $font-body;
    text-align: left;

    border: .0625rem solid $color-primary-light;
    border-radius: $border-radius-medium;

    overflow: hidden;
    transition: all .1s ease-in;

    @include breakpoint(small) {

      padding: $unit-m;
      padding-left: $unit-m + 5rem + $unit-m;
      min-height: $unit-m + 5rem + $unit-m;

    }

    @include breakpoint(large) {

      display: flex;

      padding: $unit-m;
      align-items: center;

      text-align: center;

    }

  }

  label:before {

    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    height: $unit-s + 1.25rem;
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1) 70%);

    content: "";

    @include breakpoint(medium) {

      display: none;

    }

  }

  // Hide original input element
  input[type="radio"],
  input[type="checkbox"] {

    position: absolute;

    opacity: 0;

  }

  input[type="checkbox"]:hover + label,
  input[type="checkbox"]:focus + label {


  }

  input[type="radio"]:focus + label,
  input[type="checkbox"]:focus + label {

    box-shadow: 0 0 0 .1875rem $color-accent-green;

  }

  // Basic visual styles on checked state
  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {

    box-shadow: 0 0 0 .125rem $color-accent-green;
    border-color: $color-accent-green;
    background-color: tint($color-accent-green, 80%);

    animation: choice-pulse .2s ease-out;

    &:before {

      background: none;

      background: linear-gradient(to bottom, rgba(255,255,255, 0), tint($color-accent-green, 80%) 70%);

    }

  }

}

.c-choice-charity__media {

  position: absolute;
  top: $unit-s - .0625rem;
  left: $unit-s - .0625rem;

  display: block;
  width: 4rem;

  img {

    display: block;
    margin: 0;
    max-width: 100%;

    border-radius: $border-radius-small;

  }

  @include breakpoint(small) {

    top: $unit-m - .0625rem;
    left: $unit-m - .0625rem;

    width: 5rem;

  }

  @include breakpoint(large) {

    position: relative;
    top: auto;
    left: auto;

    margin-bottom: $unit-s;

    // position: relative;
    // top: auto;
    // left: auto;

    // float: left;
    // margin-right: $unit-s;
    // margin-bottom: $unit-s;

  }

  @include breakpoint(large) {

    // width: 6rem;

  }

}

.c-choice-charity__title {

  display: block;

  font-size: .875rem; // 14px
  line-height: 1.7142857143; // 24/14
  font-weight: $semibold;

  @include breakpoint(small) {

    font-size: 1rem;
    line-height: 1.5;

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-xs;

  }

}

.c-choice-charity__description {


  font-size: .8125rem; // 13px
  line-height: 1.5384615385; // 20/13

  @include breakpoint(small) {

    font-size: .875rem;

  }

  @include breakpoint(medium) {

    line-height: 1.4615384615; // 19/13

  }

}

.c-choice-charity__toggle {

  position: absolute;
  top: 0;
  right: 0;

  background-color: transparent;
  border-color: transparent;

  .c-button__label {

    @include hide-visually;

  }

  @include states {

    background-color: transparent;
    border-color: transparent;

  }

  .is-opened & {

    transform: rotate(180deg);

  }

  @include breakpoint(medium) {

    display: none;

  }

}

/*
 * Toggle
 *
 * Choice charities are switchable on mobile
 */

@include breakpoint(to-medium) {

  .c-choice-charity.is-closed {

    label {

      height: $unit-s + 4rem + $unit-s;
      overflow: hidden;

    }

  }

  .c-choice-charity.is-opened {
    
    label {
      
      min-height: $unit-s + 4rem + $unit-s;
      
      &:before {
  
        display: none;
  
      }
      
    }


  }

}