/* =Generic: Other
===================================================== */

body {

  font-family: $font-body;
  color: $color-text;
  line-height: 1.5;

  background: #fff;

}

::selection {

  color: $color-primary-dark;
  background-color: $color-primary-light;

}

[class^="c-"],
[class*=" c-"] {

  // line-height: 1.5;

}

.visuallyhidden {
  @include hide-visually(hide);
}