/* =Components: Fact
===================================================== */

/*
 * Fact
 *
 * Styles for:
 * - fact text
 * - fact media
 * - fact compare
 */

$decoration-dots-left:    "fact-dots-left-alt.svg";
$decoration-dots-right:   "fact-dots-right-alt.svg";
$decoration-dots-width:   240px;
$decoration-dots-height:  50px;

.c-fact {

  // Sax has a problem with compile if `position: relative` (or any style) is above background
  // so we are keeping it below in this reference.
  background:
    assets-path('/images/facts/#{$decoration-dots-right}') no-repeat top right,
    assets-path('/images/facts/#{$decoration-dots-left}') no-repeat bottom left
  ;
  background-size: $decoration-dots-width $decoration-dots-height;

  position: relative;

  padding: $unit-m;

  padding-top: 5.5rem!important;
  padding-bottom: 5.5rem!important;

  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0 - $unit-m;
  margin-right: 0 - $unit-m;

  &:before {

    $fact-clip-start: 8%;
    $fact-clip-end: 2%;

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: $color-primary-light;

    clip-path: polygon(0 $fact-clip-start, 100% $fact-clip-end, 100% (100% - $fact-clip-start), 0 (100% - $fact-clip-end));

    content: "";

  }

  @include rtl {

    // Sax has a problem with compile if `position: relative` (or any style) is above background
    // so we are keeping it below in this reference.
    background:
      assets-path('/images/facts/#{$decoration-dots-left}') no-repeat top left,
      assets-path('/images/facts/#{$decoration-dots-right}') no-repeat bottom right
    ;
    background-size: $decoration-dots-width $decoration-dots-height;

    direction: rtl;

    &:before {

      $fact-clip-start: 2%;
      $fact-clip-end: 8%;

      clip-path: polygon(0 $fact-clip-start, 100% $fact-clip-end, 100% (100% - $fact-clip-start), 0 (100% - $fact-clip-end));

    }

  }

  @include breakpoint(small) {

    background-size: ($decoration-dots-width * 1.15) ($decoration-dots-height * 1.15);

    @include rtl {

      background-size: ($decoration-dots-width * 1.15) ($decoration-dots-height * 1.15);

    }

  }

  @include breakpoint(medium) {

    padding: $unit-l;
    margin-left: 0 - $unit-l;
    margin-right: 0 - $unit-l;

  }

  @include breakpoint(large) {

    padding: $unit-xl;
    margin-left: 0 - $unit-xl;
    margin-right: 0 - $unit-xl;

  }

}

.c-fact__header {

  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: $unit-s;

  @include breakpoint(small) {

    margin-bottom: $unit-m;

  }

}

.c-fact__title {

  margin-top: 0;
  margin-bottom: 0;

  font-size: .875rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: $color-text-weak;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(small) {

    font-size: 1rem;

  }

}

.c-fact__illustration {

  position: absolute;
  left: 0;
  bottom: 100%;

  margin-bottom: $unit-s;

  img {

    max-width: 100%;
    height: 5rem;
    display: block;

  }

  @include rtl {

    left: auto;
    right: 0;

  }

  @include breakpoint(small) {

    margin-bottom: $unit-m;

  }

  // * {

  //   transform-origin: center;
  //   transform-box: fill-box;

  // }

  // #eyes {

  //   animation: eyes-blink 2s infinite;

  // }

}

.c-fact__content {

  p {

    font-size: rem(17);
    line-height: 1.4;
    letter-spacing: -0.3px;
    font-weight: $medium;
    font-family: $font-headline;
    text-align: left;
    color: $color-text-strong;

    margin-top: $unit-m;
    margin-bottom: 0;

    &:first-child {

      margin-top: 0;

    }

    @include rtl {

      text-align: right;
      direction: rtl;

    }

    @include breakpoint(small) {

      font-size: rem(18);

    }

    @include breakpoint(medium) {

      font-size: rem(20);
      line-height: 1.5;

    }

  }

}

.c-fact__media {

  margin-top: $unit-m;
  margin-bottom: $unit-m;

  &:last-child {

    margin-bottom: 0;

  }

  img {

    width: 100%;
    display: block;
    margin-bottom: 0;

    border-radius: $border-radius-small;

  }

}

.c-fact__compare {

  margin-top: $unit-l;
  margin-bottom: 0;

  img {

    width: 100%;
    display: block;
    margin-bottom: 0;

    border-radius: $border-radius-small;

  }

}

/*
 * Modifiers
 */

.c-fact--compare {

  @include breakpoint(medium) {

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $unit-l;
    grid-row-gap: $unit-m;

    .c-fact__header {

      grid-row: 1;
      grid-column: 1;

      margin-top: auto;
      margin-bottom: 0;

    }

    .c-fact__content {

      grid-row: 2;
      grid-column: 1;

      margin-bottom: auto;
      
      word-break: break-word;

      p {

        font-size: 1.25rem;

      }

    }

    .c-fact__media {

      grid-row: span 2;
      grid-column: 2;

      margin-top: 0 - $unit-l;
      margin-bottom: 0 - $unit-l;
      
      min-width: rem(364);

    }

  }

}