/* =Headings
===================================================== */

// Headings
//
// Heading examples
//
// Markup:
// <h1>Nikola Tesla — Heading 1</h1>
// <h2>War of Currents — Heading 2</h2>
// <h3>X-ray experimentation — Heading 3</h3>
// <h4>AC and the induction motor — Heading 4</h4>
//
// Style guide: 4.headings

h1,
h2,
h3,
h4 {

  margin: 0;

  // margin-bottom: 1rem;
  // margin-top: 0;

  font-family: $font-headline;
  font-weight: $semibold;
  line-height: 1.25;
  color: $color-text-strong;

}

// h1 {

//   // font-size: 2rem;
//   font-size: ms(3);

//   @include breakpoint(medium) {

//     font-size: ms(4);

//   }

//   @include breakpoint(large) {

//     font-size: ms(5);

//   }

// }

// h2 {

//   // font-size: 1.5rem;
//   font-size: ms(2);

//   @include breakpoint(medium) {

//     font-size: ms(3);

//   }

//   @include breakpoint(large) {

//     font-size: ms(4);

//   }

// }

// h3 {

//   // font-size: 1.25rem;
//   font-size: ms(1);

//   @include breakpoint(medium) {

//     font-size: ms(2);

//   }

//   @include breakpoint(large) {

//     font-size: ms(3);

//   }

// }

// h4 {

//   // font-size: 1.125rem;
//   font-size: ms(0);

//   @include breakpoint(medium) {

//     font-size: ms(1);

//   }

//   @include breakpoint(large) {

//     font-size: ms(2);

//   }

// }