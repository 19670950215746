/* =Components: Lead image
===================================================== */

/*
 * Lead image
 *
 * This component is used for lead images.
 * Lead image can be a carousel, regular lead image
 * or pan&zoom enabled lead image.
 * 
 */


.c-lead-image {
  border-radius: $border-radius-small;
  margin-bottom: $unit-m;
  position: relative;
  
  &.c-lead-image--pan-zoom {
    z-index: 9999;
    overflow: hidden;
  }
  
  figure {
    margin: 0;
  }
}

.c-lead-image__media {
  
  img {
    max-width: 100%;
    display: block;
  }
}

.c-carousel__item {
  overflow: hidden;
  
  //  =note
  //  The above line was added to prevent wide images going
  //  outside of the carousel item area. Please note that we're using
  //  slightly different code structure in the prototype and in the client.
  
  img {
    max-width: 100%;
    
    //  =note
    //  Above line was added to prevent images breaking the parent slide container
    //  when size is larger than the container itself.
  }
}

.c-lead-image .c-carousel__items {
  max-width: calc(100vw - 64px);

  //  =note: 2022-10-31
  //  The above line was added to make sure having a relative .c-modal__poll
  //  in certain resolutions (e.g. "to-extra-small-height breakpoint") won't
  //  break the entire container.
  
  //  For more info check:
  //  https://github.com/sdesregistry/IH-Engage/issues/2065#issuecomment-1295646097
  //  https://github.com/sdesregistry/IH-Engage/issues/2065#issuecomment-1291237989
}

.c-lead-image .c-carousel__prev {
  
  @include breakpoint(to-small) {
    bottom: 0;
  }
  
  //  =note: 2022-11-02
  //  The above line was added because of an issue mentioned here 
  //  https://github.com/sdesregistry/IH-Engage/issues/2065#issuecomment-1290373697
  //  I specifically added it as an update for lead image component so we don't
  //  interfere with anything else.
}