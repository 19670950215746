/* =Components: Language navigation
===================================================== */

.c-nav-lang {}

.c-nav-lang__items {

  list-style-type: none;
  margin: 0;
  padding: 0;

  min-width: 10rem;

}

.c-nav-lang__item {

  margin-top: 0;
  border-top: .0625rem solid $color-grey-x-light;

  font-size: .875rem;
  line-height: 1.4285714286;
  text-align: center;
  font-family: $font-body;
  font-weight: $semibold;

  &:first-child {

    border-top: none;

  }

  @include breakpoint(small) {

    font-size: 1rem;
    line-height: 1.5;

  }

}

.c-nav-lang__link {

  display: block;
  padding: $unit-s $unit-m;

  color: $color-text;

  @include states {

    color: $color-text-strong;
    background-color: $color-link-tint;

  }

}


.c-nav-lang__item.is-active {

  .c-nav-lang__link {

    color: $color-text-strong-invert;
    background-color: $color-primary-dark;

  }

}