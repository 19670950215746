/* =Components: Preview notice
===================================================== */

/*
 * Preview notice
 *
 * Visual indicator which can be removed to show that
 * the project is in the preview mode (no votes registered).
 */

.c-preview-notice {

  position: fixed;
  bottom: $unit-s;
  left: $unit-s;
  right: $unit-s;
  z-index: $z-index-cookie-notice;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: $unit-s $unit-m;

  box-shadow: $box-shadow-large;
  border-radius: $border-radius-medium;
  background-color: rgba($color-accent-green, .95);

  p {

    width: 100%;
    margin: $unit-xs;

    font-size: .875rem;
    line-height: 1.4285714286;
    text-align: center;

    @include breakpoint(extra-small) {

      font-size: 1rem;
      line-height: 1.5;

    }

    @include breakpoint(extra-large) {

      width: auto;

    }

  }

  em {

    font-weight: bold;
    font-style: normal;
    background-color: lighten($color-accent-green, 20%);
    padding: .125rem .5rem;
    border-radius: $border-radius-small;

    .o-icon {

      position: relative;
      top: .125rem;

    }

  }

  .c-button {

    margin: $unit-xs;
    padding: .125rem $unit-s;
    min-width: 9rem;

    @include breakpoint(small) {

      min-width: 10rem;

    }

  }

  @include breakpoint(large) {

    bottom: $unit-l;
    left: $unit-l;
    right: $unit-l;

    padding: $unit-m $unit-l;

  }

}