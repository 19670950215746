/* =Components: Global modal styles
===================================================== */

/*
 * Parent container
 */

.c-modal-global-container {

  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;

  padding: $unit-m;

  background: rgba($color-primary-light, .9);

  &[aria-hidden="true"] {

    display: none;

  }

}

@keyframes modal-animate {

  0% {

    opacity: 0;
    transform: scale(.5);

  }

  100% {

    opacity: 1;
    transform: scale(1);

  }

}

/*
 * Modal
 */

$modal-poll-header: 3rem;
$modal-poll-progress: .25rem;
$modal-poll-header-and-progress: $modal-poll-header + $modal-poll-progress;

.c-modal-global {

  display: flex;
  flex-direction: column;

  margin: auto 0;
  max-width: $container-max-width;
  border: none;

  border-radius: $border-radius-medium;

  background-color: #fff;
  box-shadow: $box-shadow-large;

  animation: modal-animate .25s $transition-timing-cubic forwards;
  
  /*
   * "No Title" variation
   *
   * Modifier for situations where modal won't have a title.
   */

   &.c-modal-global--no-title {
     
     .c-modal-global__header {
       border: none;
       min-height: auto;
     }
     
     .c-modal-global__close {
       transform: none;
       top: 0;
     }

     .c-modal-global__label {
       display: none;
     }
     
     .c-modal-global__content {
       padding: 2rem 3rem;
     }
   }


  /*
   * Page reflow tweak
   *
   * When the page has a short viewport height (e.g. zoom 400%)
   * make the whole window scrollable by removing any absolute
   * positioning.
   */

  @include breakpoint(to-extra-small-height) {

    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;

  }

  &:focus {

    outline: none;

  }

  &:after {

    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;

    content: "";

  }

}

.c-modal-global__header {

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: $modal-poll-header;

  transition: $transition-default;
  
  border-bottom: 1px solid $color-primary-light;

}

.c-modal-global__label {

  min-width: 10rem;
  padding: 1rem 2rem;

  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: $semibold;
  text-align: center;

}

.c-modal-global__close {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  min-height: $modal-poll-header;
  line-height: 1;
  width: rem(48);
  background-color: transparent;
  text-align: center;
  z-index: $z-index-header;
  
  .o-icon {
    top: 0;
    left: 0;
    margin: 0 auto;
  }

  @include states {

    background-color: transparent;
    border: none;

  }

}

.c-modal-global__close {

  left: 0;

  color: $color-text;

  @include rtl {

    left: auto;
    right: 0;

  }

  .o-icon {

    font-size: 1.25rem;
    display: block;

  }

  .c-button__label {

    @include hide-visually();

  }

  @include states {

    color: $color-text-strong;

  }

}

.c-modal-global__content {

  position: relative;
  height: calc(100% - #{$modal-poll-header}); // Compensate for header
  padding: 2rem;
  
  :last-child {
    margin-bottom: 0;
  }

}

/*
 * Modifiers
 */

.c-modal-global--invert {

  background-color: $color-primary-dark;

  .c-modal-global__label {

    color: $color-text-invert;

  }

  .c-modal-global__close {

    color: $color-text-invert;

    @include states {

      color: $color-text-strong-invert;

    }

  }
}
