/* =Helpers: Animation
===================================================== */

/*
 * Animations
 *
 * `.u-a-` prefix corresponds to `.u-animation`.
 */

/*
 * Children fade in
 *
 * Show images subsequently, one by one from the left side,
 * from zero opacity to full visibility
 */

@keyframes fade-in-from-bottom {

  0% {

    opacity: 0;
    bottom: -1rem;

  }

  100% {

    opacity: 1;
    bottom: 0;

  }

}

@keyframes fade-in {

  0% {

    opacity: 0;

  }

  100% {

    opacity: 1;

  }

}

.u-a-children-fade-in {

  > * {

    position: relative;
    opacity: 0;

    animation: fade-in-from-bottom .25s ease-in forwards;


  }

  @for $i from 1 through 100 {

    > :nth-child(#{$i}) {

      animation-delay: ($i * .125) + s;

    }

  }

}

.u-a-fade-in {

  animation: fade-in-from-bottom .25s ease-in forwards;

}

/*
 * Scale in show
 *
 * Used for modals or dropdowns
 */


@keyframes scale-in-show {

  0% {

    opacity: 0;
    transform: scale(.5);

  }

  100% {

    opacity: 1;
    transform: scale(1);

  }

}

.u-a-children-scale-in {

  > * {

    position: relative;
    opacity: 0;

    animation: scale-in-show .25s $transition-timing-cubic forwards;
    transform-origin: 50% 50%;

  }

  @for $i from 1 through 100 {

    > :nth-child(#{$i}) {

      animation-delay: ($i * .125) + s;

    }

  }

}

.u-a-scale-in {

  animation: scale-in-show .25s $transition-timing-cubic forwards;
  transform-origin: 50% 50%;

}

/*
 * Illustration animations
 *
 * Different styles of animation for illustrations
 * - Explainer illustrations
 * - Finish poll illustration
 */

@keyframes explainer-popup {

  0% {

    opacity: 0;
    transform: scale(.2);

  }

  90% {

    transform: scale(1.1);

  }

  100% {

    opacity: 1;
    transform: scale(1);

  }

}

@keyframes explainer-eyes-blink {

  0%, 100% {

    transform: scale(1, .05);

  }

  5%, 95% {

    transform: scale(1, 1);

  }

}

@keyframes explainer-flag-wave {

  0%, 100% {

    transform: scale(.75, 1);

  }

  50% {

    transform: scale(1.25, 1);

  }

}

@keyframes explainer-coin-flip {

  0%, 100% {

    transform: translateY(0) scale(.05, 1);

  }

  5%, 95% {

    transform: scale(1, 1);

  }

  50% {

    transform: translateY(3px);

  }

}

@keyframes explainer-flow {

  0%, 100% {

    transform: translateY(0);

  }

  50% {

    transform: translateY(3px);

  }

}

@keyframes explainer-rotate {

  0% {

    transform: rotate(0);

  }

  100% {

    transform: rotate(360deg);

  }

}

@keyframes explainer-pulse {

  0%, 100% {

    transform: scale(1);
    opacity: 1;

  }

  50% {

    transform: scale(.9);
    opacity: .7;

  }

}

@keyframes eyes-blink {

  0%, 100% {

    transform: scale(1, .05);

  }

  5%, 95% {

    transform: scale(1, 1);

  }

}

@keyframes timeline-teaser-gradient {

  0% {

    background-position: 0 0;

  }

  100% {

    // background-position: ($unit-xl * 2) 0;
    background-position: 1rem 0;

  }

}

@keyframes loading-rotate {

  0% {

    transform: rotate(0);

  }

  100% {

    transform: rotate(360deg);

  }

}