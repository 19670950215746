/* =Components: Annotate image
===================================================== */

/*
 * Annotate image
 */

.c-annotate-image {

  // Important to show the whole image within
  // the overflown container
  position: absolute;

  // Make sure the pan/zoom on the image is calculated
  // correctly and consistently
  transform-origin: 0 0;

  // Apply a move cursor style on the whole area
  cursor: move;

  // Visual cue
  animation: annotation-image-move 3.5s ease 1 1s;

}

.c-annotate-image__group {

  position: relative;

  background: $color-primary-dark;
  background-image: linear-gradient(30deg, $color-accent-red, $color-accent-blue, $color-accent-green);
  background-size: cover;

  box-shadow: 0 0 $unit-xl 0 rgba($color-primary-dark, .25);

}

.c-annotate-image__proportion {

  display: block;
  visibility: hidden;

}

.c-annotate-image__pin {

  position: absolute;

  pointer-events: none;
  user-select: none;

  img {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: block;
    width: $annotate-area-pin-size;
    height: $annotate-area-pin-size;
    margin-bottom: 0;

    filter: drop-shadow(0 .125rem .5rem rgba($color-primary-dark, .75));
    transition: $transition-default;

    @include breakpoint(medium) {

      width: $annotate-area-pin-size-medium;
      height: $annotate-area-pin-size-medium;

    }

  }

  // Keep pins visible on the edit screen
  // but reduce the size so they are not as obvious
  .c-modal-poll--annotate-edit & {

    img {

      transform: translate(-50%, -50%) scale(.75);

    }

  }

}

/*
 * Visual cue
 *
 * Indicating that user can interact with the map
 */

@keyframes annotation-image-move {

  0%,
  50%,
  70% {

    left: 0;

  }

  40%,
  60% {

    left: -2rem;

  }

}

/*
 * Pin popup
 *
 * Once the pin is added the to the image
 * add a small popup animation to make it more
 * obvious.
 *
 * This is visible only on annotation image question.
 */

.c-annotate-image__pin {

  img {

    animation: pin-popup .75s $transition-timing-cubic forwards;

  }

}

@keyframes pin-popup {

  20%,
  100% {

    transform: translate(-50%, -50%) scale(.875);

  }

  50% {

    transform: translate(-50%, -50%) scale(1.25);

  }

}