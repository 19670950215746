/* =Objects: Tooltip
===================================================== */

$z-index-tooltip: 10000;
$tooltip-background-color: $black;

.o-tooltip {
  position: relative;
  cursor: help;
  
  .c-question__title + & {
    margin-top: .25rem;
    display: inline-block;
  }
  

  &__action {
    color: $color-text-weak;
    transition: all .15s ease-in;
  
    .o-label {
      font-weight: $regular;
    }
  
    .o-icon {
      position: relative;
      top: .125rem;
      margin-left: .25rem;
    }
  
    .o-icon:first-child {
      margin-left: 0;
    }

    &:hover,
    &:focus {
      color: $color-text-strong;
    }
    
    &:hover + .o-tooltip__description,
    &:focus + .o-tooltip__description {
      opacity: 1;
    }
  }
  
  
  &__description {
    opacity: 0;
    position: absolute;
    z-index: $z-index-tooltip;
    padding: .75rem;
    max-width: 24rem;
    min-width: 12rem;
    font-size: .75rem;
    line-height: 1.5;
    font-weight: $regular;
    color: $color-text;
    background-color: #fff;
    border-radius: $border-radius-small;
    white-space: normal;
    pointer-events: none;
    transition: all .1s ease-in;
    box-shadow: $box-shadow-small;
    // border: 1px solid rgba(0,0,0,.05);
    
    body > &,
    body > .o-tooltip-container > &,
    #root > .o-tooltip-container > & {
      position: fixed;
      opacity: 1;
      display: block;
      transition: none;
      z-index: 100000;
      
      position: fixed;
      top: 0;
      left: 0;
      
      @include breakpoint(to-medium) {
        margin-right: $unit-l;
      }
    }
  
    &:before {
      position: absolute;
      border: .25rem solid transparent;
      content: "";
    }
  }
}



/*
 * Button
 */

.o-tooltip--button {
  background: $color-grey-x-light;
  padding: rem(4) rem(6);
  border-radius: $border-radius-small;
  text-transform: uppercase;
  font-weight: $bold;
  font-size: rem(12);

  &:focus,
  &:active,
  &:hover, {
    outline: none;
    
    .o-tooltip__description {
      
      //  Info 2024-04-23 (Emanuel)
      //  I removed this for time being, since tooltip is used as part of advanced
      //  map question type only and is rendered in the root, so this is not needed.
      //  With this active, there are situations where tooltip would get visible
      //  in prototype, when focus is removed on mobile, and the tooltip would get back
      //  to its original position in DOM.
      
      // opacity: 1;
    }
  }
  
  &.is-focused {
    color: $black;
    background: $color-primary-light;
  }
  
  .o-tooltip__action {
    outline: none;
  }
}



/*
 * States
 */

.o-tooltip--danger {

  .o-tooltip__action {
    color: $color-danger;
  }
}

.o-tooltip--success {

  .o-tooltip__action {
    color: $color-success;
  }
}




/*
 * Tooltip container
 * This is used for client app only, not in prototype.
 */

.o-tooltip-container {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
}