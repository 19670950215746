/* =Components: Cookie panel
===================================================== */

/*
 * Cookie panel
 *
 * Floating notice accessible from footer enabling you to
 * manage cookie preferences.
 */

.c-cookie-panel-container {

  /*
   * Overlay on bigger screen
   */

  &:before {

    transition: $transition-default;

    position: fixed;
    z-index: $z-index-cookie-panel;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba($color-primary-light, .9);

    content: "";

  }

  &[aria-hidden="true"] {

    display: none;

    &:before {

      opacity: 0;

    }

  }

  &[aria-hidden="false"] {

    display: block;

    &:before {

      opacity: 1;

    }

  }

}

.c-cookie-panel {

  position: fixed;
  z-index: $z-index-cookie-panel + 1;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: $z-index-cookie-panel;
  transform: translateX(-100%);
  transform-origin: left center;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: $unit-m;

  text-align: left;

  box-shadow: $box-shadow-large;
  background-color: #fff;

  animation: cookie-panel-animate .25s $transition-timing-cubic forwards;

  @include breakpoint(small) {

    max-width: 28rem;
    padding: $unit-l;

  }

  @include breakpoint(medium) {

    max-width: 32rem;
    padding: $unit-xl;

  }

  /*
   * Fixing scrolling on mobile
   */

  > *:last-child:after {

    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;

    content: "";

  }

}

/*
 * Disable scroll on mobile only
 */

.is-cookie-panel-opened {

  @include breakpoint(to-small) {

    overflow: hidden;

  }

}

/*
 * Elements styles
 */

.c-cookie-panel__title {

  margin-bottom: $unit-s;

  font-size: ms(1);
  line-height: ms(2);

}

.c-cookie-panel__description {

  margin-bottom: $unit-m;

  p {

    margin-top: $unit-s;
    margin-bottom: 0;

    font-size: .875rem;
    line-height: 1.4285714286;

    &:first-child {

      margin-top: 0;

    }

    @include breakpoint(medium) {

      margin-top: $unit-m;

      font-size: 1rem;
      line-height: 1.5;

    }

  }

}

/*
 * Animations
 */

@keyframes cookie-panel-animate {

  0% {

    opacity: 0;
    transform: translateX(-100%);

  }

  100% {

    opacity: 1;
    transform: translateX(0);

  }

}

// @keyframes cookie-panel-overlay {

//   0% {

//     opacity: 0;

//   }

//   100% {

//     opacity: 1;

//   }

// }