/* =Components: Homepage — Location
===================================================== */

/*
 * Location
 *
 * Location on a Google map
 */

.c-location {

  margin-bottom: $container-unit-small * 2;

  @include breakpoint(medium) {

    margin-bottom: $container-unit-medium * 2;

  }

  @include breakpoint(large) {

    margin-bottom: $container-unit-large * 2;

  }

  &:last-child {

    margin-bottom: 0;

  }

}

.c-location__heading {

  margin-top: 0;
  margin-bottom: $unit-m;

  font-size: ms(0);
  line-height: ms(1);

  @include breakpoint(small) {

    font-size: ms(1);
    line-height: ms(2);

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-l;

  }

  // position: relative;
  // z-index: 1000;
  // padding-top: $container-unit-large * 2;
  // margin-top: 0 - ($container-unit-large * 2);

  .c-block--dark & {

    color: $color-text-strong-invert;

  }

}

.c-location__map {

  margin-left: 0 -  $container-unit-small;
  margin-right: 0 -  $container-unit-small;
  margin-bottom: 0 - ($container-unit-small * 2);
  margin-top: $container-unit-small * 2;

  @include breakpoint(small) {

    margin-left: 0 - $container-unit-medium;
    margin-right: 0 - $container-unit-medium;
    margin-bottom: 0 - ($container-unit-medium * 2);
    margin-top: $container-unit-medium * 2;

  }

  @include breakpoint(large) {

    margin: 0;

    border-radius: $border-radius-medium;

  }

}