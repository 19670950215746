/* =Components: Choice icon
===================================================== */

/*
 * Choice icon
 *
 * Select icon (reaction)
 */

$choice-icon-width: 6rem;
$choice-icon-height: 7rem;
$choice-icon-image-width: 4rem;

.c-choice-icon {

  position: relative;

  transition: all .2s ease-in;

  label {

    display: block;
    padding: $unit-s $unit-xs $unit-xs $unit-xs;
    width: $choice-icon-width;
    flex: 1 0 $choice-icon-width;
    height: $choice-icon-height;

    text-align: center;
    border-radius: $border-radius-small;
    cursor: pointer;

  }

  // Hide original input element
  input[type="radio"],
  input[type="checkbox"] {

    position: absolute;

    opacity: 0;

  }

  // Basic visual styles for hover state
  input[type="radio"]:hover + label,
  input[type="checkbox"]:hover + label {

    img {

      filter: drop-shadow(0 .25rem .5rem rgba($color-primary-dark, .3));

    }

    .c-choice-icon__title {

      color: $color-text-strong;

    }

  }

  // Basic visual styles on checked state
  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {

    background: $color-accent-green;

    img {

      filter: drop-shadow(0 .25rem .5rem rgba($color-primary-dark, .3));

      animation: choice-pulse .2s ease-out;

    }

    .c-choice-icon__title {

      color: $color-text-strong;

    }

  }

}

.c-choice-icon__media {

  position: relative;

  display: block;

  border-radius: $border-radius-small;
  transition: all .1s ease-in;

  img {

    position: relative;
    z-index: $z-index-question;

    margin-bottom: 0;
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    max-width: $choice-icon-image-width;

    border-radius: $border-radius-small;
    transition: all .1s ease-in;

  }

}

.c-choice-icon__title {

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.25rem;

  font-size: .875rem;
  line-height: 1.2857142857; // 18 / 14
  font-weight: 600;
  color: $color-text-weak;

  @include breakpoint(small) {

    // font-size: 1rem;
    // line-height: 1.25; // 20 / 16

  }

}

/*
 * Visual cue
 *
 * Indicating that the reactions are scrollable
 */

@keyframes choice-reaction-move {

  0%,
  50%,
  70% {

    transform: translateX(0) scale(1);

  }

  40%,
  60% {

    transform: translateX(-1rem);

  }

}

.u-a-choice-reaction-scroll-cue {

  .c-choice-icon {

    animation:
      choice-reaction-move 2s ease 1 1s;

  }

}