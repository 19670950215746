/* =Components: Annotate pin
===================================================== */

.c-annotate-pin {

  display: none;

  background-color: #fff;
  transition: $transition-default;

  @include breakpoint(to-medium) {

    padding: $unit-m;
    padding-bottom: 0;

  }

  @include breakpoint(medium) {

    padding: $unit-l;

  }

  @include breakpoint(large) {

    padding: $unit-xl;

  }

  @include rtl {

    direction: rtl;

  }

  .c-modal-poll--annotate-edit & {

    display: block;

  }

}

/*
 * Form group
 */

$annotate-textarea: 7rem;

.c-annotate-pin__group {

  margin-top: $unit-m;

  &:first-child {

    margin-top: 0;

  }

  .o-textarea textarea {

    min-height: $annotate-textarea;

  }

  &.is-opened {

    .c-annotate-pin__toggle {

      padding-top: 0;
      padding-bottom: 0;
      max-height: 0;
      opacity: 0;

    }

    .c-field {

      max-height: 20rem;

    }

  }

  &.is-closed {

    .c-annotate-pin__toggle {

      max-height: 3rem;

    }

    .c-field {

      max-height: 0;
      opacity: 0;

    }

  }

}

/*
 * Toggle
 */

.c-annotate-pin__toggle {

  display: flex;
  align-items: center;
  height: 3rem;
  padding: $unit-xs $unit-m $unit-xs $unit-s;
  width: 100%;

  overflow: hidden;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-headline;
  font-weight: $semibold;

  color: $color-text-strong;
  border-radius: $border-radius-small;
  background-color: $color-button-regular;
  transition: $transition-default;

  @include rtl {

    padding-left: $unit-m;
    padding-right: $unit-s;

  }

  @include states {

    background-color: $color-button-regular-hover;

  }

  &[disabled],
  &.is-disabled {

    opacity: .2;
    pointer-events: none;

  }

  .c-annotate-pin__group--reaction & {

    background: $color-accent-green;

  }

}

.c-annotate-pin__toggle-icon {

  min-width: 2rem;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {

    display: block;
    width: 2.25rem;

  }

  .c-annotate-pin__group--reaction & {

    margin-right: $unit-s;

    @include rtl {

      margin-left: $unit-s;

    }

  }

}
.c-annotate-pin__toggle-label {


}

.c-annotate-pin__toggle-action {

  margin-left: auto;

  font-size: .875rem;
  text-transform: uppercase;

  @include rtl {

    margin-left: 0;
    margin-right: auto;

  }

}

.c-annotate-pin__action {

  margin-top: $unit-m;

  @include breakpoint(to-medium) {

    margin-left: 0 - $unit-m;
    margin-right: 0 - $unit-m;

    .c-button {

      border-radius: 0 0 $border-radius-medium $border-radius-medium;

    }

  }

}