/* =Objects: Polls page grid
===================================================== */

/*
 * Polls
 *
 * Show different polls in a grid
 */

.l-grid-polls {

  display: grid;
  grid-gap: $unit-m;

  .c-card-poll {

    margin-bottom: 0;

  }

  @include breakpoint(small) {

    grid-gap: $unit-l;

  }

  @include breakpoint(medium) {

    grid-template-columns: 1fr 1fr;

    .c-card-feedback,
    .c-card-poll--wide,
    .c-card-poll--floating {

      grid-column: span 2;

    }

  }

  .no-cssgrid & {

    .c-card-poll {

      margin-bottom: $unit-m;

    }

  }

  /*
   * Explainer toggle
   *
   * Polls are styled differently when explainer is active
   */

  transition: $transition-default;

  .is-explainer-opened & {

    filter: blur($unit-s);
    opacity: .25;
    pointer-events: none;

  }

}