/* =Components: Blocks
===================================================== */

.c-block {}

.c-block + .c-block {

  box-shadow: inset 0 -.0625rem 0 0 $color-border-weak;

}


.c-block--light {

  background-color: $color-primary-light;
  box-shadow: none;

}


.c-block--dark {

  background-color: $color-primary-dark;
  box-shadow: none;

}

.c-block--color-green {

  background-color: $color-accent-green;
  box-shadow: none;

}

.c-block--background-1,
.c-block--background-2,
.c-block--background-3 {

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 200vw 200vw;

  @include breakpoint(large) {

    background-size: 1500px 3000px;

  }

}

.c-block--background-1 {

  background-image: assets-path('/images/background-decoration-1.svg');

}

.c-block--background-2 {

  background-image: assets-path('/images/background-decoration-2.svg');

}

.c-block--background-3 {

  background-image: assets-path('/images/background-decoration-3.svg');

}