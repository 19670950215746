/* =Components: Nav section
===================================================== */

.c-section-nav {

  position: relative;
  z-index: $z-index-navigation;

  box-shadow: $box-shadow-large;
  background-color: #fff;

  transition: $transition-default;

}

/*
 * Modifier
 */

.c-section-nav.is-sticky {

  position: fixed;
  z-index: $z-index-navigation;
  top: 0;
  right: 0;
  left: 0;

  box-shadow: $box-shadow-small;

}

.c-section-nav.is-sticky + * {

  // Test with padding or margin on real code
  padding-top: $header-nav-height;

}