/* =Components: Cookie notice
===================================================== */

/*
 * Cookie notice
 *
 * Simple floating notice for explaining cookies on the website
 */

.c-cookie-notice {

  position: fixed;
  bottom: $unit-s;
  left: $unit-s;
  right: $unit-s;
  z-index: $z-index-cookie-notice;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: $unit-s $unit-m;

  // background-color: hsla(213, 52%, 96%, .95);
  box-shadow: $box-shadow-large;
  border-radius: $border-radius-medium;
  background-color: rgba($color-accent-green, .95);

  p {

    width: 100%;
    margin: $unit-xs;

    font-size: .875rem;
    line-height: 1.4285714286;
    text-align: center;

    @include breakpoint(extra-small) {

      font-size: 1rem;
      line-height: 1.5;

    }

    @include breakpoint(extra-large) {

      width: auto;

    }

  }
  
  a {
    text-decoration: underline;
  }

  .c-button {

    margin: $unit-xs;
    padding: .125rem $unit-s;
    min-width: 9rem;

    @include breakpoint(small) {

      min-width: 10rem;

    }

  }



  @include breakpoint(large) {

    bottom: $unit-l;
    left: $unit-l;
    right: $unit-l;

    padding: $unit-m $unit-l;

  }

}

/*
 * Cookie notice: Minimal
 *
 * This update adds option for cookie notice to be positioned minimally,
 * at the bottom of the screen, but without overlaying other elements.
 * Use carefully and only when needed!
 */

html.is-cookie-notice-opened.is-cookie-notice-minimal {
  $cookie-notice-height: 100;
  
  @include breakpoint(to-medium) {
    
    .c-cookie-notice {
      
      p {
        font-size: rem(12);
        line-height: 1.2;
        letter-spacing: -0.3px;
      }
      
      .c-button {
        min-width: 0;
        font-size: rem(12);
      }
    }
  }
  
  @include breakpoint(to-large) {

    .c-modal-poll-container {
      bottom: rem($cookie-notice-height);
    }
    
    .c-cookie-notice {
      height: rem($cookie-notice-height);
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0;
      background-color: $color-accent-green;
      align-content: center;
      grid-gap: rem(3) 0;
      
      p {
        margin: 0;
      }
    }
  }
}