/* =Components: Links
===================================================== */

/*
 * Links
 *
 * Default links styling. Useful when buttons should
 * look like a link but they should still have the interaction
 * of the button because of the React app.
 */

.c-link {

  color: $color-link;
  text-decoration: underline solid tint($color-link, 80%);
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {

    color: $color-link-hover;
    text-decoration: underline solid tint($color-link-hover, 80%);

  }

}