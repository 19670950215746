/* =Components: Masthead
===================================================== */

$masthead-logo-size: 5rem;
$masthead-logo-size-medium: 7rem;
$masthead-logo-size-large: 9rem;

.c-masthead {

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $unit-xs 0;

  line-height: 1.5;

  @include breakpoint(medium) {

    padding: $unit-m 0;

  }

}

.c-masthead__background {

  position: relative;

  display: block;
  width: 100%;
  margin-top: 0 - $unit-xs;
  margin-bottom: 0;
  padding-bottom: 25%; // Aspect ratio 4:1

  background-color: $color-primary-light;
  // background: linear-gradient(to bottom, $color-primary-light, rgba($color-primary-light, 0));

  img {

    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    display: block;
    margin-bottom: 0;

    @include object-fit();

    max-width: none;
    width: 100%;

  }

  @include breakpoint(medium) {

    margin-top: 0 - $unit-m;

  }

}

.c-masthead__logo {

  position: relative;

  margin-top: 0 - ($masthead-logo-size * 0.5);
  margin-bottom: 0;
  max-width: $masthead-logo-size;
  overflow: hidden;

  border-radius: 50%;
  box-shadow: $box-shadow-large;

  a {

    display: block;

  }

  img {

    display: block;
    max-width: 100%;
    margin-bottom: 0;

    border-radius: 50%;

  }

  @include breakpoint(medium) {

    margin-top: 0 - ($masthead-logo-size-medium * 0.5);
    max-width: $masthead-logo-size-medium;

  }

  @include breakpoint(large) {

    margin-top: 0 - ($masthead-logo-size-large * 0.5);
    max-width: $masthead-logo-size-large;

  }

}

.c-masthead__logo + .c-masthead__title {

  margin-top: $unit-s;

  @include breakpoint(medium) {

    margin-top: $unit-m;

  }

}

.c-masthead__title {

  margin-top: $unit-s;
  margin-bottom: 0;

  font-size: 1rem;
  line-height: 1.25;
  font-weight: $semibold;
  text-align: center;

  @include breakpoint(small) {

    font-size: 1.25rem;
    line-height: 1.2;

  }

  @include breakpoint(medium) {

    margin-top: $unit-m;

    font-size: 1.5rem;
    line-height: 1.2;

  }

}

.c-masthead__tagline {

  margin-bottom: 0;

  font-size: ms(-2);
  font-family: $font-headline;
  font-weight: $regular;
  text-align: center;
  color: rgba($color-text-weak, .5);

  @include breakpoint(small) {

    font-size: ms(0);

  }

}