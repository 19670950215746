/* =Objects: Loading
===================================================== */

$loading-dot: .5rem;
$loading-space: .375rem;

.o-loading {

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: ($loading-dot * 4) + ($loading-space * 8);
  height: $loading-dot * 2;

}

.o-loading--center {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.o-loading__dot {

  width: $loading-dot;
  height: $loading-dot;
  margin: $loading-space;

  border-radius: 50%;

  animation: loading-dot .9s linear infinite;

  &:nth-child(1) {
    animation-delay: 0s;
    background-color: $color-primary-dark;

  }
  &:nth-child(2) {
    animation-delay: .2s;
    background-color: $color-accent-green;

  }
  &:nth-child(3) {
    animation-delay: .4s;
    background-color: $color-accent-red;

  }
  &:nth-child(4) {
    animation-delay: .6s;
    background-color: $color-accent-blue;

  }

}

@keyframes loading-dot {

  0% {

    transform: translateY(0);

  }

  25% {

    transform: translateY(-.4rem);

  }

  50% {

    transform: translateY(-.5rem);

  }

  100% {

    transform: translateY(0);

  }

}