/* =Components: Annotate area
===================================================== */

/*
 * Annotate area
 *
 * Map or image area where people put pins
 */

.c-annotate-area {

  position: relative;

  display: flex;
  height: auto;
  flex: 1;

}

.c-annotate-area__content {

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow: hidden;

  background-color: $color-primary-light;
  background-image: $background-pattern-annotate;

}

$annotate-area-placeholder-size: 3rem;       // Placeholder circle width/height
$annotate-area-pin-size: 5rem;               // Pin on the image on mobile
$annotate-area-pin-size-active: 5rem;        // When adding new pin on mobile
$annotate-area-pin-size-medium: 6rem;        // Pin on the image on tablets
$annotate-area-pin-size-medium-active: 6rem; // When adding new pin on tablet

.c-annotate-area__pin {

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: $z-index-question;

  opacity: 0;

  pointer-events: none;
  user-select: none;

  .c-modal-poll--annotate-edit & {

    opacity: 1;

  }

  img {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: block;
    width: $annotate-area-pin-size;
    height: $annotate-area-pin-size;
    margin-bottom: 0;

    filter: drop-shadow(0 .125rem .5rem rgba($color-primary-dark, .5));

    .c-modal-poll--annotate-edit & {

      width: $annotate-area-pin-size-active;
      height: $annotate-area-pin-size-active;

    }

    @include breakpoint(medium) {

      width: $annotate-area-pin-size-medium;
      height: $annotate-area-pin-size-medium;

      .c-modal-poll--annotate-edit & {

        width: $annotate-area-pin-size-medium-active;
        height: $annotate-area-pin-size-medium-active;

      }

    }

  }

  // Pin visible until the reaction is selected
  &:before {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: $annotate-area-placeholder-size;
    height: $annotate-area-placeholder-size;
    border: 1rem solid $color-accent-green;

    background-color: $color-primary-dark;
    border-radius: 50%;
    opacity: 0;
    transition: $transition-default;

    animation: 2s placeholder-pin-pulse infinite;

    content: "";

  }

  &.c-annotate-area__pin--placeholder {

    &:before {

      opacity: 1;

    }

  }

}

.c-annotate-area__image {

  transform: $transition-default;

}

/*
 * Note
 */

.c-annotate-area__note {

  position: absolute;
  z-index: $z-index-main;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  min-height: 2.5rem;
  margin-bottom: 0;
  padding: $unit-xs $unit-m;

  font-size: .75rem;
  line-height: 1rem;
  font-weight: $medium;
  background: linear-gradient(to top,
    rgba($color-primary-light, .9) 25%,
    rgba($color-primary-light, .7) 50%,
    rgba($color-primary-light, 0));
  transition: $transition-default;

  pointer-events: none;
  opacity: 0;

  .o-icon {

    margin-right: $unit-xs;

    font-size: 1rem;

  }

  @include breakpoint(extra-small) {

    min-height: 3rem;

  }

  .c-modal-poll--annotate-edit.c-modal-poll--annotate-multiple & {

    opacity: 1;

  }

}


/*
 * Placeholder pin
 *
 * Pulsating shadow underneath the pin
 */

@keyframes placeholder-pin-pulse {

  0% {

    box-shadow: 0 0 0 0 transparentize($color-primary-dark, .5);

  }

  50% {

    box-shadow: 0 0 0 .75rem transparentize($color-primary-light, 1);

  }

  100% {

    box-shadow: 0 0 0 0 transparentize($color-primary-light, 1);

  }

}

/*
 * Touch indicator cue
 *
 * This touch indicator movement should always be synced
 * with the `annotation-image-move` in terms of movement
 * and duration.
 *
 * This should be applied to `c-annotate-area` in cases where
 *
 *
 */

.u-a-annotation-area-touch-cue {

  &:before {

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $z-index-question;
    transform: translate(-50%, -50%);

    width: $unit-xl;
    height: $unit-xl;

    background-color: $color-accent-green;
    border-radius: 50%;
    opacity: 0;
    transition: $transition-default;

    content: "";
    pointer-events: none;

    animation: annotation-image-touch-point 3.5s ease 1 1s;

    // Hide if user clicks edit within the animation duration
    .c-modal-poll--annotate-edit & {

      opacity: 0!important;

    }

  }

}

@keyframes annotation-image-touch-point {

  // Initial state
  0% {

    opacity: 1;
    transform: translate(-50%, -50%) scale(0);

  }

  // Popup
  5% {

    transform: translate(-50%, -50%) scale(1.5);

  }

  // Normalise
  10% {

    transform: translate(-50%, -50%) scale(1);

  }

  // Same value as annotation-image cue.
  0%,
  50%,
  70% {

    margin-left: 0;

  }

  40%,
  60% {

    margin-left: -2rem;

  }

  // Normalise
  80% {

    opacity: 1;
    transform: translate(-50%, -50%) scale(1);

  }

  // Popup again
  90% {

    transform: translate(-50%, -50%) scale(1.5);

  }

  // End, hide the pin
  100% {

    opacity: 0;
    transform: translate(-50%, -50%) scale(0);

  }

}