/* =Components: Annotate map
===================================================== */

/*
 * Annotate map
 */

.c-annotate-map {

  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}