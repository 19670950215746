/* =Components: Choice grid
===================================================== */

/*
 * Choice grid
 *
 * Different grid for choices inside the question.
 * Used only for image questions so far.
 *
 * The grid is done with flexbox for older browsers
 * and then with CSS Grid for browsers that support it.
 *
 * For older browsers, each choice has 3% margin so
 * the remaining space is given out to the choices.
 * E.g.
 *    5 image choices in a row.
 *.   5 choices * 3% = 15% (85% remains)
 *    85% / 5 choices = 17%.
 *
 * For CSS Grid we just show the number of fractions.
 * `grid-template-columns: 1fr 1fr 1fr 1fr 1fr;` // 5 items
 * `grid-template-columns: repeat(5, minmax(0, 1fr));`  // 5 items with minmax lock
 */

.l-choice-grid {

  margin-top: auto;
  margin-bottom: auto;

  /*
   * Flexbox layout
   *
   * Using flexbox to createa a layout with columns
   * for project cards.
   */

  display: flex;
  flex-flow: row wrap;

  margin-left: -1.5%;
  margin-right: -1.5%;
  margin-top: -1.5%;
  margin-bottom: -1.5%;

  > * {

    margin: 1.5%

  }

  /*
   * CSS Grid layout
   *
   * If the browser supports CSS Grid, we are using it
   * to enhance the layout (and override flexbox).
   */

  @supports (display: grid) {

    margin: auto 0;

    display: grid;
    grid-column-gap: $unit-m;
    grid-row-gap: $unit-m;

    > * {

      margin: 0;

    }

    @include breakpoint(extra-small) {

      grid-row-gap: $unit-l;

    }

    @include breakpoint(medium) {

      grid-column-gap: $unit-l;
      grid-row-gap: $unit-xl;

    }

    @include breakpoint(large) {

      grid-column-gap: $unit-xl;
      grid-row-gap: $unit-xl;

    }

  }

}

/*
 * Two wide images
 *
 * For:
 * - two image choice question
 *
 * On mobile display in column
 * On mobile landscape display in row
 */

.l-choice-grid--wide-two {

  > * {

    @include breakpoint(small) {

      width: 47%;

    }

  }

  @supports (display: grid) {

    > * {

      width: 100%;

    }

    grid-template-columns: 1fr;

    @include breakpoint(small) {

      grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(2, minmax(0, 1fr));

    }

  }

}


/*
 * Three wide images
 *
 * For:
 * - other image choice question
 *
 * On mobile and tablet display in column
 * On desktop display in a row
 */

.l-choice-grid--wide-three {

  > * {

    @include breakpoint(large) {

      width: 30.333333333333333%;

    }

  }

  @supports (display: grid) {

    > * {

      width: 100%;

    }

    grid-template-columns: 1fr;

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(3, minmax(0, 1fr));

    }

  }

}

/*
 * Five square images
 *
 * For:
 * - multiple image choice (10 choices)
 *
 * On mobile display 2 in a row
 * On tablet display 5 in a row
 */

.l-choice-grid--square-five {

  > * {

    width: 47%;

    @include breakpoint(medium) {

      width: 17%;

    }

  }

  @supports (display: grid) {

    > * {

      width: 100%;

    }

    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: repeat(5, minmax(0, 1fr));

    }

  }

}

/*
 * Four square images
 *
 * For:
 * - multiple image choice (8 choices)
 * - four image choice
 *
 * On mobile display 2 in a row
 * On tablet display 4 in a row
 */

.l-choice-grid--square-four {

  > * {

    width: 47%;

    @include breakpoint(medium) {

      width: 22%;

    }

  }

  @supports (display: grid) {

    > * {

      width: 100%;

    }

    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: repeat(4, minmax(0, 1fr));

    }

  }

}

/*
 * Three square images
 *
 * For:
 * - multiple image choice (6 choices)
 *
 * On mobile display 2 in a row
 * On tablet display 3 in a row
 */

.l-choice-grid--square-three {

  > * {

    width: 47%;

    @include breakpoint(medium) {

      width: 30.333333333333333%;

    }

  }

  @supports (display: grid) {

    > * {

      width: 100%;

    }

    grid-template-columns: 1fr 1fr;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(3, minmax(0, 1fr));

    }

  }

}

/*
 * Three square images (max 3 choices)
 *
 * For:
 * - three image choice
 *
 * On every device display 3 in a row
 */

.l-choice-grid--square-only-three {

  margin-top: 0;

  > * {

    width: 30.333333333333333%;

  }

  @supports (display: grid) {

    > * {

      width: 100%;

    }

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, minmax(0, 1fr));

  }

}

/*
 * Simple list of choices with no images
 *
 * For:
 * - demographic questions
 *
 * On every device display 1 in a row
 */

.l-choice-grid--list {

  margin-top: 0;

  @supports (display: grid) {

    grid-column-gap: $unit-m;
    grid-row-gap: $unit-m;

    > * {

      width: 100%;

    }

    // grid-template-columns: 1fr 1fr 1fr;
    // grid-template-columns: repeat(3, minmax(0, 1fr));

  }

}

/*
 * Simple list of choices with no images
 *
 * For:
 * - demographic questions
 *
 * On every device display 1 in a row
 */

.l-choice-grid--list-two {

  margin-top: 0;

  @supports (display: grid) {

    grid-column-gap: $unit-m;
    grid-row-gap: $unit-m;

    > * {

      width: 100%;

    }

    @include breakpoint(medium) {

      grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(2, minmax(0, 1fr));

    }

  }

}