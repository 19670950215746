/* =Components: Timeline
===================================================== */

/*
 * Timeline
 *
 * Timeline is composed from
 * phases and stages.
 */

.c-timeline {

  text-align: left;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

}

.c-timeline__phase {

  margin-bottom: $unit-l;

  &:last-child {

    margin-bottom: 0;

  }

}

.c-timeline__phase-title {

  padding-top: $unit-l;
  margin-top: 0 - $unit-l;
  margin-bottom: $unit-s;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

}

.c-timeline__phase-description {

  margin-bottom: 0;

  font-size: ms(-1);
  line-height: ms(2);

  @include breakpoint(medium) {

    font-size: ms(0);

  }

}

.c-timeline__stages {

  padding: $unit-l 0;

}


/*
 * Stage
 *
 * Stage is an independent component that can include
 * switchable content, image and icon.
 */

$stage-status-circle: 1rem;
$stage-status-circle-l: 1.5rem;
$stage-status-width: 2rem;
$stage-status-width-l: 3rem;

.c-stage {

  position: relative;

  padding-left: $stage-status-width;
  padding-top: $unit-xs;
  padding-bottom: $unit-xs;

  text-align: left;

  @include rtl {

    direction: rtl;

    padding-left: 0;
    padding-right: $stage-status-width;

    text-align: right;

  }

  @include breakpoint(medium) {

    padding-left: $stage-status-width-l;
    padding-top: $unit-s;
    padding-bottom: $unit-s;

    @include rtl {

      padding-left: 0;
      padding-right: $stage-status-width-l;

    }

  }

  // Status vertical line
  &:before {

    position: absolute;
    z-index: $z-index-timeline + 1;

    left: $stage-status-circle * 0.5;
    top: $unit-m + $stage-status-circle + $unit-xs;
    bottom: 0 - $unit-m - $unit-xs;

    width: .125rem;
    margin-left: -.0625rem;

    background-color: darken($color-primary-light, 10%);

    content: "";

    @include rtl {

      direction: rtl;
      left: auto;
      right: $stage-status-circle * 0.5;

    }

    @include breakpoint(medium) {

      left: $stage-status-circle-l * 0.5;
      top: $unit-l + $stage-status-circle-l + $unit-s;
      bottom: 0 - $unit-l - $unit-s;

      @include rtl {

        direction: rtl;
        left: auto;
        right: $stage-status-circle-l * 0.5;

      }

    }

  }

  &:last-child {

    &:before {

      display: none;

    }

  }

}

.c-stage__status {

  position: absolute;
  z-index: $z-index-timeline;
  top: $unit-m + $unit-xs;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $stage-status-circle;
  height: $stage-status-circle;

  font-size: 1rem;
  border-radius: 50%;

  @include rtl {

    left: auto;
    right: 0;

  }

  @include breakpoint(medium) {

    top: $unit-l + $unit-s;

    width: $stage-status-circle-l;
    height: $stage-status-circle-l;

    font-size: 1.125rem;

  }

}

.c-stage__group {

  border-radius: $border-radius-small;
  transition: $transition-default;

  .is-opened & {

    box-shadow: $box-shadow-large;
    background: none!important;

  }

}

.c-stage__header {

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: $unit-m;

  @include breakpoint(medium) {

    padding: $unit-l;

  }

}

.c-stage__content {

  overflow: hidden;

  padding-left: $unit-m;
  padding-right: $unit-m;

  transition: all .2s $transition-timing-cubic;

  @include breakpoint(medium) {

    padding-left: $unit-l;
    padding-right: $unit-l;

  }

  .is-opened & {

    max-height: 50rem;
    margin-top: 0 - $unit-s;
    padding-bottom: $unit-m;

    opacity: 1;

    @include breakpoint(medium) {

      padding-bottom: $unit-l;

    }

  }

  .is-closed & {

    max-height: 0;
    opacity: 0;

  }

}

.c-stage__title {

  font-size: .875rem;

  @include rtl {

    text-align: right;
    direction: rtl;

  }


  @include breakpoint(small) {

    font-size: 1rem;

  }

  @include breakpoint(medium) {

    // font-size: ms(1);
    font-size: 1.125rem;

  }

}

.c-stage__meta {

  margin-top: $unit-xs;
  margin-bottom: 0;

  font-size: .75rem;
  line-height: 1.5;
  font-family: $font-body;
  font-weight: $semibold;
  color: $color-text-strong;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(medium) {

    margin-top: $unit-s;

    font-size: .875rem;

  }

}

.c-stage__content {

  p {

    margin-top: $unit-s;
    margin-bottom: 0;

    font-size: .75rem;
    line-height: 1.5;

    &:first-of-type {

      margin-top: 0;

    }

  }

  @include breakpoint(medium) {

    p {

      font-size: .875rem;

    }

  }

}

.c-stage__figure {

  margin-bottom: $unit-s;

  img {

    display: block;
    margin-bottom: 0;
    margin-top: 0;
    max-width: 100%;

    border-radius: $border-radius-small;

  }

  @include breakpoint(medium) {

    float: left;
    margin-right: $unit-m;
    margin-bottom: 0;
    max-width: 50%;

    @include rtl {

      float: right;
      margin-right: 0;
      margin-left: $unit-m;

    }

  }

}

.c-stage__tag {

  position: relative;
  display: inline-block;

  padding-left: .75rem;
  margin-left: .375rem;

  border-radius: $border-radius-small;
  font-weight: $regular;
  color: $color-text-weak;

  @include rtl {

    padding-left: 0;
    margin-left: 0;

    padding-right: .75rem;
    margin-right: .375rem;

  }

  &:before {

    position: absolute;
    top: 50%;
    left: 0;

    width: .375rem;
    height: .375rem;
    margin-top: -.1875rem;

    vertical-align: middle;
    border-radius: 50%;
    background-color: $color-text-weak;

    content: "";

    @include rtl {

      left: auto;
      right: 0;

    }

  }

}

/*
 * Switchable
 *
 * Toggle content on and off
 */

.c-stage--switchable {

  .c-stage__header {

    padding: 0;

  }

  // .c-stage__group:hover {

  //   box-shadow: inset 0 0 0 .125rem $color-primary-light;

  // }

}

.c-stage__toggle {

  position: relative;

  padding: $unit-m;
  padding-right: $unit-m + 2.5rem;
  width: 100%;
  border: none;

  text-align: left;
  background-color: transparent;
  border-radius: $border-radius-small;

  @include rtl {

    padding-right: $unit-m;
    padding-left: $unit-m + 2.5rem;

  }

  &:hover,
  &:focus,
  &:active {

    border: none;

    opacity: 1;
    background-color: transparent;
    cursor: pointer;
    outline: none;

    .c-stage__toggle-indicator {

      color: $color-text-strong-invert;
      background-color: $color-primary-dark;
      // box-shadow: $box-shadow-small;

    }

  }

  .c-stage__toggle-indicator {

    position: absolute;
    top: $unit-m;
    right: $unit-m;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    border-radius: 1rem;
    transition: $transition-default;

    @include rtl {

      right: auto;
      left: $unit-m;

    }

    .o-icon {

      transition: $transition-default;

    }

    .is-opened & {

      // background-color: transparent;

      .o-icon {

        transform: rotate(180deg);

      }

    }

  }

  @include breakpoint(medium) {

    padding: $unit-l;
    padding-right: $unit-l + 2.5rem;

    @include rtl {

      padding-right: $unit-l;
      padding-left: $unit-l + 2.5rem;

    }

  }

}


/*
 * Modifiers
 */

.c-stage--completed {

  &:before {

    background-color: $color-accent-green;

  }

  .c-stage__status {

    color: darken($color-accent-green, 20%);

  }

  .c-stage__tag:before {

    background-color: darken($color-accent-green, 10%);

  }

  .c-stage__group {

    // background-image: linear-gradient(90deg,
    //   lighten($color-accent-green, 20%),
    //   transparentize($color-primary-light, 1)
    // );

    background-color: lighten($color-accent-green, 25%);

  }

}

.c-stage--active {

  &:before {

    background-color: $color-primary-dark;

  }

  .c-stage__status {

    color: darken($color-primary-dark, 20%);

    // box-shadow: 0 0 0 .5rem $color-primary-light;
    animation: 2s stage-active-pulse infinite;

  }

  .c-stage__tag {

    color: $color-text-strong;

  }

  .c-stage__tag:before {

    background-color: $color-primary-dark;

  }

  .c-stage__group {

    background-color: $color-primary-light;

    // background-image: linear-gradient(90deg,
    //   lighten($color-primary-light, 0%),
    //   transparentize($color-primary-light, 1)
    // );

  }

}

.c-stage--upcoming {

  &:before {

    // Create a dashed line with gradients
    background-color: transparent;
    background-image: repeating-linear-gradient(0,
      transparentize($color-primary-light, 1),
      transparentize($color-primary-light, 1) 2px,
      darken($color-primary-light, 10%) 2px,
      darken($color-primary-light, 10%) 4px
    );

  }

  .c-stage__status {

    color: darken($color-primary-light, 10%);

  }

  .c-stage__group {

    background-image: repeating-linear-gradient(135deg,
      transparentize($color-primary-light, 1),
      transparentize($color-primary-light, 1) 5px,
      lighten($color-primary-light, 0%) 5px,
      lighten($color-primary-light, 0%) 7px
    );

  }


}

.c-stage--disruptive {

  &:before {

    background-color: $color-accent-red;

  }

  .c-stage__status {

    color: $color-accent-red;

  }

  .c-stage__tag:before {

    background-color: darken($color-accent-red, 10%);

  }

  .c-stage__group {

    // background-image: linear-gradient(90deg,
    //   lighten($color-accent-red, 20%),
    //   transparentize($color-primary-light, 1)
    // );

    background-color: lighten($color-accent-red, 25%);

  }

}

@keyframes stage-active-pulse {

  0% {

    box-shadow: 0 0 0 0 transparentize($color-primary-dark, .5);

  }

  50% {

    box-shadow: 0 0 0 .75rem transparentize($color-primary-light, 1);

  }

  100% {

    box-shadow: 0 0 0 0 transparentize($color-primary-light, 1);

  }

}