/* =Images
===================================================== */

img {

  // display: block;
  // margin: 0;
  // margin-bottom: $unit-default;
  // max-width: 100%;
  // height: auto;

}

figure  {

  display: block;
  margin: 0;
  margin-bottom: $unit-default;
  max-width: 100%;
  height: auto;

}

figcaption {

  margin-top: $unit-xs;
  margin-bottom: 0;
  color: $color-text-weak;

}