/* =Components: Carousel
===================================================== */

/*
 * Carousel
 *
 * Carousel styles
 */

$carousel-button: 3rem;

.c-carousel {

  position: relative;

  @include rtl {

    direction: rtl;

  }

  @include breakpoint(medium) {

    padding-bottom: $carousel-button + $unit-m;

  }

}

.c-carousel__items {

  position: relative;

  padding: 0;

}

.c-carousel__item {


}

.c-carousel__controls {

  display: flex;
  justify-content: center;
  min-height: $carousel-button;
  padding-left: $carousel-button;
  padding-right: $carousel-button;
  margin-top: $unit-m;

  @include breakpoint(medium) {

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    min-height: auto;

    margin: 0;
    margin-bottom: $carousel-button + $unit-m;

    /* Disable interaction with div */
    pointer-events: none;

  }

}

/*
 * Prev and Next buttons
 *
 * Custom markup
 */

.c-carousel__prev,
.c-carousel__next {

  position: absolute;
  z-index: $z-index-carousel;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: .875rem 0;
  width: $carousel-button;
  border: none;

  color: $color-link;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: none;

  transition: all .1s ease-in;

  .o-icon {

    font-size: 1.25rem;
    line-height: 1;

  }

  @include states {

    color: $color-primary-dark;
    opacity: 1;
    outline: none;

  }

  &:focus {

    box-shadow: 0 0 0 .1875rem darken($color-primary-light, 10%);
    outline: none;

  }

  &.slick-disabled {

    opacity: 0;

  }

  @include breakpoint(medium) {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    background-color: #fff;
    box-shadow: $box-shadow-large;
    border-radius: 50%;

    /* Enable controls */
    pointer-events: auto;

    @include states {

      color: $color-text-strong-invert;
      background-color: $color-primary-dark;

    }

    &:focus {

      box-shadow: $box-shadow-large,
                  0 0 0 .1875rem darken($color-primary-light, 10%);

    }

  }

  .c-carousel__controls--green & {

    background-color: $color-accent-green;
    color: $color-text-strong;

    &:focus {

      box-shadow: $box-shadow-large,
                  0 0 0 .1875rem lighten($color-accent-green, 20%);

    }


  }

  .c-carousel__controls--blue & {

    background-color: $color-primary-dark;
    color: $color-text-strong-invert;

  }

  .c-block--dark & {

    color: $color-accent-green;

    @include states {

    color: $color-text-strong-invert;

    }

    @include breakpoint(medium) {

      color: $color-link;

      @include states {

        color: $color-text-strong;
        background-color: $color-accent-green;

      }

    }

  }

}

.c-carousel__prev {

  left: 0;

  @include breakpoint(medium) {

    left: 0 - $unit-m;

  }


  @include rtl {

    left: auto;
    right: 0;

    .o-icon {

      transform: scaleX(-1);

    }

    @include breakpoint(medium) {

      left: auto;
      right: 0 - $unit-m;

    }

  }

}

.c-carousel__next {

  right: 0;

  @include breakpoint(medium) {

    right: 0 - $unit-m;

  }

  @include rtl {

    right: auto;
    left: 0;

    .o-icon {

      transform: scaleX(-1);

    }

    @include breakpoint(medium) {

      right: auto;
      left: 0 - $unit-m;

    }

  }

}

/*
 * Dots
 *
 * Markup generated by slick slider.
 */

.c-carousel__dots {

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  min-height: $carousel-button;

  list-style: none;
  margin: 0;
  padding: 0;

  @include breakpoint(medium) {

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: $unit-m;

    /* Enable controls */
    pointer-events: auto;

  }

  li {

    margin-top: 0;
    padding: 0;

  }

  button {

    position: relative;
    display: block;
    border: none;
    margin: 0;
    padding: 0;
    width: 1.5rem;
    height: 3rem;

    background: none;

    @include hide-text();

    @include states {

      outline: none;

      &:before {

        width: .625rem;
        height: .625rem;

        background-color: $color-primary-dark;
        opacity: .5;

      }

    }

  }

  button:before {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: .375rem;
    height: .375rem;

    background-color: $color-primary-dark;
    border-radius: 50%;
    opacity: .25;

    content: "";

  }

  .slick-active button:before {

    width: .625rem;
    height: .625rem;

    background-color: $color-primary-dark;
    opacity: 1;

  }

  .c-block--dark & {

    button:before {

      background-color: $color-primary-light;
      opacity: 1;

    }

    .slick-active button:before {

      // background-color: $color-primary-light;
      background-color: $color-accent-green;

    }

  }

}