/* =Components: Annotate single
===================================================== */

/*
 * Annotate single
 *
 * Submit button. Feedback is optional.
 */

.c-annotate-single {

  position: relative;
  z-index: $z-index-question + 10;

  display: none;

  .c-modal-poll--annotate-edit & {

    display: block;

  }

}

.c-annotate-single__feedback {

  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  padding: $unit-m;

  @include breakpoint(medium) {

    padding: $unit-l;

  }

  @include breakpoint(large) {

    padding: $unit-xl;

  }

  &.is-opened {

    background: linear-gradient(to top,
      rgba($color-primary-light, .95) 25%,
      rgba($color-primary-light, .7) 75%,
      rgba($color-primary-light, 0));

    .c-annotate-single__field {

      display: block;

      textarea {

        height: 8rem;
        max-height: 8rem;
        min-height: auto;

      }

    }

    .c-annotate-single__toggle {

      display: block;

    }

    @include breakpoint(medium) {

      padding-bottom: 0;

      background: linear-gradient(to top,
        rgba(#fff, 1) 25%,
        rgba(#fff, .7) 75%,
        rgba(#fff, 0));

    }

  }

  &.is-closed {

    padding: 0;

    .c-annotate-single__field {

      display: none;

    }

    .c-annotate-single__toggle {

      display: none;

    }

  }

}

.c-annotate-single__field {

  .c-field__count {

    top: auto;
    right: $unit-m;
    bottom: $unit-s;
    width: auto;

    text-align: right;
    background: transparent;

  }

  .c-field__count.c-field__count--limit {

    display: block;
    background: transparent;

  }

  textarea {

    padding-right: $unit-xl + $unit-s;

    resize: none;
    border-color: transparent;
    box-shadow: $box-shadow-medium;

    &:focus {

      box-shadow: $box-shadow-large;

    }

  }

}

.c-annotate-single__toggle {

  position: absolute;
  top: $unit-m;
  right: $unit-m;
  z-index: $z-index-main;
  transform: translateY(-50%);

  width: 2.5rem;
  height: 2.5rem;
  padding: 0;

  color: $color-text-strong;
  background-color: $color-button-alternate;
  border-radius: 50%;

  .o-icon {

    margin: 0;

  }

  .c-button__label {

    @include hide-visually();

  }

  @include states {

    color: $color-text-strong;
    background-color: darken($color-button-alternate, 10%);

  }

  @include breakpoint(medium) {

    top: $unit-l;
    right: $unit-l;

  }

  @include breakpoint(large) {

    top: $unit-xl;
    right: $unit-xl;

  }

}

.c-annotate-single__action {

  @include breakpoint(to-medium) {

    margin-left: 0 - $unit-m;
    margin-right: 0 - $unit-m;

    .c-button {

      border-radius: 0 0 $border-radius-medium $border-radius-medium;

    }

  }

  @include breakpoint(medium) {

    padding: $unit-l;

  }

  @include breakpoint(large) {

    padding: $unit-xl;

  }

}