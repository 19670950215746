/* =Components: Annotate 2021
===================================================== */

/*
 * Annotate Advanced
 *
 * Temporary CSS for the new Annotate Advanced functionality.
 * Needs to be separated, once done.
 * 
 * Additional interactions (added as a class on each state change,
 * i.e. on first drag&drop, on reaction drop, on submit etc.
 *
 * (1) First interaction: .c-modal-poll--annotate-advanced-first-interaction 
 * (2) Edit:              .c-modal-poll--annotate-advanced-edit
 * (3) Interactive:       .c-modal-poll--annotate-advanced-interactive
 * (4) Interaction:       .c-modal-poll--annotate-advanced-interaction
 * (5) Finish:            .c-modal-poll--annotate-advanced-finish
 *
 * (5) Done:              .c-modal-poll--annotate-done
 */
 
 $transition-annotate: all ease-in .25s;
 $transition-annotate-fast: all ease-out .15s;
 $reactions-height: 119; //px
 $reactions-height-m: 151; //px

//  Advanced annotate overrides
.c-modal-poll--annotate-advanced {
  
  //  Animations
  .c-modal-poll__label,
  .c-modal-poll__skip,
  .c-modal-poll__progress {
    //  Visible
    opacity: 1;
    visibility: visible;
    transition: $transition-timing-cubic;
  }

  .c-annotate-menu {
    //  Hidden
    display: block;
    background: #fff;
    padding: rem(12);
    position: absolute;
    bottom: 0;
    transform: translateY(rem(12));
    opacity: 0;
    visibility: hidden;
    transition: $transition-timing-cubic;
  }
  
  &.c-modal-poll--annotate-advanced-edit .c-annotate-menu {
    //  Visible
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  
  .c-annotate-menu {
    // Visible
  }


  .c-question__header {
    transition: $transition-annotate;
    transition-delay: .1s;

    .c-question__title {
      transition: $transition-timing-cubic;
    }
  }
  
  .c-annotate-menu__user-feedback {
    display: flex;
    flex-wrap: wrap;
    gap: $unit-s;
    
    div {
      flex-grow: 1;
    }
    
    .o-label--short,
    .o-label--long {
      @include hide-visually();
    }
    
    &.options--1 {
      
      .c-annotate-menu__item {
        
        .o-label--long {
          @include hide-visually(unhide);
        }
      }
    }
    
    @include breakpoint('extra-small') {
      
      &.options--2 {
        
        .c-annotate-menu__item {
          
          .o-label--short {
            @include hide-visually(unhide);
          }
        }
      }
    }
    
    @include breakpoint('small') {
      
      &.options--3 {
        
        .c-annotate-menu__item {
          
          .o-label--short {
            @include hide-visually(unhide);
          }
        }
      }

      &.options--2 {
        
        .c-annotate-menu__item {
          
          .o-label--short {
            @include hide-visually();
          }
          
          .o-label--long {
            @include hide-visually(unhide);
          }
        }
      }

      &.options--1 {
        
        .c-annotate-menu__item {
          
          .o-label--short {
            @include hide-visually();
          }
          
          .o-label--long {
            @include hide-visually(unhide);
          }
        }
      }
    }
  }
  
  .c-annotate-menu__item {
    
    .c-button {
      width: 100%;
    }
  }
  
  .c-annotate-menu__action,
  .c-annotate-menu__item--media .c-upload__select,
  .c-annotate-menu__item--media .c-upload__placeholder {
    background: $color-primary-light;
    box-shadow: none;
  }
  
  .c-annotate-menu__user-final-actions {
    display: grid;
    grid-template-columns: rem(48) auto;
    margin: rem(12) rem(-12) rem(-12) rem(-12);
    
    .c-button {
      border-radius: 0;
      border: none;
      padding: rem(10) rem(16);
      
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    
    .c-annotate-menu__item--delete .c-button {
      padding: 0;
      width: rem(48);
      height: rem(48);
      transition: none;
      
      .o-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: none;
        margin: 0;
      }
    }
    
    .c-annotate-menu__item--submit .c-button {
      line-height: rem(28);
    }
  }

  .c-annotate-advanced-pin .c-reactions__items__container {
    // opacity: .3;
  }

  .c-annotate-area__pin {
    opacity: 1;
  }
  
  .c-annotate-actions__finish {

    @include rtl {
      right: 0;
    }
  }
}

.c-annotate-advanced {
  
  //  =todo: see if this is a bug in app, then fix here if problematic...
  
  // .c-annotate-image {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   
  //   .c-annotate-image__group {
  //     height: 100%;
  //   }
  // }
  
  .c-annotate-area__content {
    //  This is a temporary fix to allow for underlying image to be positioned
    //  and centred properly. Here, we are defining sizes for annotate 
    //  reaction area for each breakpoint where the size changes.
    margin-bottom: rem(120);
    
    @include rtl() {
      direction: ltr;
    }
    
    @include breakpoint(medium) {
      margin-bottom: rem(144);

      .c-modal-poll--annotate-advanced-edit & {
        margin-bottom: rem(120);
      }
    }
    

    @include breakpoint(large) {
      
    }
  }
  
  .c-annotate-actions__finish--confirm {
    position: absolute;
    bottom: rem($reactions-height + 16);
    left: auto;
    right: $unit-m;
    
    .c-modal-poll--annotate-advanced-edit &,
    .c-modal-poll--annotate-done & {
      display: none;
    }
    
    @include breakpoint(medium) {
      right: $unit-l;
      bottom: rem($reactions-height-m + 24);
    }
    
    @include breakpoint(large) {
      right: $unit-xl;
      bottom: rem($reactions-height-m + 32);
    }
    
    @include rtl {
      right: auto !important;
      left: $unit-m;
    
      @include breakpoint(medium) {
        left: $unit-l;
      }
      
      @include breakpoint(large) {
        left: $unit-xl;
      }
    }
  }
  
  .c-annotate-area__note {
    bottom: rem(119);
    justify-content: center;
    
    @include breakpoint(medium) {
      bottom: rem(151);
    }
  }
}

/* Annotate Advanced Pin */
.c-annotate-advanced-pin {
  
  .c-field {
    margin: 0;
  }
  
  //  Background
  .c-annotate-pin__group {

    //  Visible
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    background: #fff;
    visibility: visible;
    opacity: 1;
    transition: $transition-timing-cubic;

    .c-modal-poll--annotate-advanced-edit &,
    .c-modal-poll--annotate-done & {

      //  Hidden
      visibility: hidden;
      opacity: 0;
      transform: translateY(rem(12));
    }
  }
  
  //  Description for when adding annotations
  .c-field__label {
    font-weight: $semibold;
    font-size: rem(12);
    line-height: rem(18);
    color: $color-grey-dark;
    text-align: center;
    padding: $unit-s;
    margin: 0;
    
    @include breakpoint(medium) {
      font-size: rem(14);
      line-height: rem(20);
      padding: rem(10) $unit-m;
      font-weight: $medium;
    }
  }
  
  .c-reactions--scroll {
    position: relative;

  }
  
  .c-reactions__items__container {
    position: relative;
    width: auto;
    margin-bottom: rem(-50);
    padding: 0 0 rem(50);
    overflow-x: scroll;
    transition: $transition-annotate-fast;
    
    .c-modal-poll--annotate-advanced-edit & {
      opacity: 0;
      visibility: hidden;
    }
    
    &:after {
      display: none;
      //   =todo =fix
    }
  }

  .c-reactions__items {
    width: 100%;
    overflow-x: initial !important;
  }

  .c-choice-icon {

    label {
      height: auto !important;
      width: auto !important;
      flex: auto !important;
      padding: 0 $unit-xs $unit-s !important;
      cursor: move;
      
      @include breakpoint(medium) {
        padding: 0 $unit-s $unit-m !important;
      }
    }
    
    input[type="radio"]:checked + label {
      background: none !important;
    }
  }
  
  .c-choice-icon__media {
    
    img {
      max-width: rem(54);
      
      @include breakpoint(medium) {
        max-width: rem(64);
      }
    }
  }
  
  .c-choice-icon__title {
    min-height: rem(24);
    font-size: rem(12);
    line-height: rem(14);
  }
}

//  Reactions controls
.c-annotate-advanced {
  
  .c-reactions__controls {
    position: absolute;
    top: 0;
    height: rem(86);
    width: 100%;
    pointer-events: none;
  }
  
  .c-reactions__prev,
  .c-reactions__next {
    transform: none;
    top: 0;
    margin: 0;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    
    height: 100%;
    width: rem(48);
    
    pointer-events: auto;
    
    button {
      width: rem(48);
      height: rem(48);
      position: absolute;
      top: rem(2);
      
      &:focus {
        color: $color-grey-dark;
        box-shadow: none !important;
      }
    }
    
    .o-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: rem(24);
    }
  }
  
  .c-reactions__prev {
    left: $unit-m;
    background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%) !important;
    justify-content: flex-end;
  }
  
  .c-reactions__next {
    right: $unit-m;
    justify-content: flex-start;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%) !important;
  }

    
  @include breakpoint(medium) {
  
    .c-reactions__controls {
      position: absolute;
      top: 0;
      height: rem(104);
      width: 100%;
    }

    .c-reactions__prev,
    .c-reactions__next {
      width: rem(64);
      
      button {
        width: rem(64);
        height: rem(64);
      }
    }
    .c-reactions__prev {
      left: 0;
    }
    
    .c-reactions__next {
      right: 0;
    }
  }
}

//  Annotate Feedback Toggle
.c-annotate-advanced__toggle {
  position: absolute;
  top: $unit-m;
  right: $unit-m;
  z-index: $z-index-main;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  color: $color-text-strong;
  background-color: $color-button-alternate;
  border-radius: 50%;

  .o-icon {
    margin: 0;
  }

  .c-button__label {
    @include hide-visually();
  }

  @include states {
    color: $color-text-strong;
    background-color: darken($color-button-alternate, 10%);
  }

  @include breakpoint(medium) {
    top: $unit-l;
    right: $unit-l;
  }

  @include breakpoint(large) {
    top: $unit-xl;
    right: $unit-xl;
  }
}

.c-annotate-advanced__feedback {
  position: absolute;
  bottom: rem(48);
  left: 0;
  right: 0;
  padding: $unit-m;

  @include breakpoint(medium) {
    padding: $unit-l;
  }

  @include breakpoint(large) {
    padding: $unit-xl;
  }

  &.is-opened {
    background: linear-gradient(to top,
      rgba(255,255,255, 1) 50%,
      rgba(255,255,255, .7) 85%,
      rgba(255,255,255, 0));

    .c-annotate-advanced__field {
      display: block;

      textarea {
        height: 8rem;
        max-height: 8rem;
        min-height: auto;
      }
    }

    .c-annotate-advanced__toggle {
      display: block;
    }

    @include breakpoint(medium) {
      background: linear-gradient(to top,
        rgba(#fff, 1) 25%,
        rgba(#fff, .7) 75%,
        rgba(#fff, 0));
    }
  }

  &.is-closed {
    padding: 0;

    .c-annotate-advanced__field {
      display: none;
    }

    .c-annotate-advanced__toggle {
      display: none;
    }
  }
}

.c-annotate-advanced__field {

  .c-field__count {
    top: auto;
    right: $unit-m;
    bottom: $unit-s;
    width: auto;
    text-align: right;
    background: transparent;
  }

  .c-field__count.c-field__count--limit {
    display: block;
    background: transparent;
  }

  textarea {
    padding-right: $unit-xl + $unit-s;
    resize: none;
    border-color: transparent;
    box-shadow: $box-shadow-medium;

    &:focus {
      box-shadow: $box-shadow-large;
    }
  }
}

.c-pseudo-modal--delete-annotation {
  
  .c-button {
    width: rem(48);
    height: rem(48);
    
    .o-icon {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    .o-icon-check {
      
      @include rtl {
        transform: translate(-50%, -50%) scaleX(-1);
      }
    }
  }
}

.c-pseudo-modal--finish-adding-annotations {
  
  .c-annotate-actions__finish {
    position: relative;
    bottom: auto;
    left: auto;
  }
}

//  Choice Icon (when being dragged)
.c-choice-icon.is-being-dragged {
  position: absolute;
  z-index: 1000;
  
  label {
    padding: 0;
    width: auto;
    height: auto;
  }
  
  .c-choice-icon__title {
    opacity: 0;
    visibility: hidden;
    min-height: 0 !important;
    line-height: 0;
  }
  
  .c-choice-icon__media img {
    max-width: rem(80);
    -webkit-filter: drop-shadow(0 0 5px rgba(0,0,0,.5));
  }
}


//  -------------------------
//  First interaction
.c-modal-poll--annotate-advanced-first-interaction {

  //  Styles for first user interaction with 
  //  Annotate Advanced question type

  .c-annotate-advanced-pin .c-reactions__items__container {
    opacity: .3;
  }
}

//  -------------------------
//  All other interactions (i.e. annotation is dragged)
.c-modal-poll--annotate-advanced-interaction {

  .c-annotate-advanced-pin .c-reactions__items__container {
    opacity: .3;
  }
}


//  -------------------------
//  Other interactions
//  Annotation edit
//  Add more annotations
//  (shared styles)
.c-modal-poll--annotate-advanced-edit,
.c-modal-poll--annotate-advanced-interactive,
.c-modal-poll--annotate-advanced-interaction,
.c-modal-poll--annotate-advanced-finish {
  
  //  Modal header (current question, skip, etc.)
  .c-modal-poll__header {
    z-index: 1000;
    min-height: 0 !important;
  }

  .c-modal-poll__label,
  .c-modal-poll__skip,
  .c-modal-poll__progress,
  .c-modal-poll__powered-by {
    //  Hidden
    opacity: 1;
    visibility: hidden;
    height: 0 !important;
    min-height: 0 !important;
    max-height: 0 !important;
    overflow: hidden;
    padding: 0;
    transform: translate3d(0, -12px, 0);
  }
  
  //  Modal content area (where image/map is shown)
  .c-modal-poll__content {
    height: 100%;
  }
  
  //  Question header (question title)
  .c-question__header {
    padding: $unit-m;
    padding-left: rem(48);
    border-radius: $border-radius-medium;
    
    @include rtl {
      padding-left: $unit-m;
      padding-right: rem(48);
    }
    
    @include breakpoint(medium) {
      margin: -$unit-l;
    }
    
    @include breakpoint(large) {
      margin: -$unit-xl;
    }
  }
  
  .c-question__title {
    font-size: rem(16);
    line-height: 1.25;
    letter-spacing: -0.1px;
    font-weight: $medium;
    
    @include breakpoint(small) {
      font-size: rem(20);
      letter-spacing: -0.3px !important;
    }
  }
}

//  -------------------------
.c-modal-poll--annotate-advanced-edit {

  //  Styles for annotation edit screen for
  //  Annotate advanced question type
  
  //  On this screen we have a few options:
  //  (1) User can leave feedback
  //  (2) User can add a photo
  //  (3) User can add a voice recording
  //  (4) User can submit annotation (annotation is then saved)
  //  (5) User can delete current annotation (including feedback, photo and voice)
}


//  -------------------------
//  Add more annotations
.c-modal-poll--annotate-advanced-interactive {

  
  //  Annotate actions ()
  .c-annotate-actions {
    
  }
}



//  -------------------------
//  "Finish" interaction

.c-modal-poll--annotate-advanced-finish {
  
  .c-annotate-pin__group {
    //  Hidden
    visibility: hidden;
    opacity: 0;
    transform: translateY(rem(12));
  }
  
  .c-annotate-actions__finish--confirm {
    bottom: rem(32);
  }
}