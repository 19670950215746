/* System font stack */
// $system-ui: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

/* Fonts */
$font-brand-primary:              'Work Sans', $font-stack-helvetica;
$font-brand-secondary:            $font-stack-system;

/* Font use case */
$font-body:                       $font-brand-secondary;
$font-headline:                   $font-brand-primary;

/* Font weights */
$hairline:                        100;
$thin:                            200;
$light:                           300;
$regular:                         400;
$medium:                          500;
$semibold:                        600;
$bold:                            700;
$black:                           900;

/* Modular scale */
$modularscale: (
  base: 1em,
  ratio: 1.25
);

// ratio: 1.125

/* Work Sans */
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700&display=swap');

// 21/32 extra-large
// 18/26 large
// 16/24 medium
// 14/22 small
// 13/20 extra-small