/* =Components: Annotate actions
===================================================== */

.c-annotate-actions {

  @include rtl {

    direction: rtl;

  }

  .c-button {

    transition: all .1s $transition-timing-cubic;
    box-shadow: $box-shadow-medium;

  }

  .c-button.is-disabled {

    opacity: 1;

    .o-icon {

      opacity: .2;

    }

  }

}

/*
 * Close
 *
 * Visible when the user adds a pin
 */

.c-annotate-actions__close {

  position: absolute;
  top: $unit-m;
  left: $unit-m;
  transform: scale(.5);

  width: 3rem;
  height: 3rem;
  padding: 0;

  font-size: 1.25rem;
  line-height: 1;
  color: $color-danger;
  border-radius: 50%;
  background-color: #fff;

  opacity: 0;
  pointer-events: none;

  .c-button__label {

    @include hide-visually();

  }

  .c-button__icon {

    transition: all .1s $transition-timing-cubic;

  }

  @include states {

    opacity: 0;

  }

  &:focus {

    box-shadow: $box-shadow-medium, 0 0 0 .1875rem lighten($color-accent-red, 20%);

  }

  @include breakpoint(medium) {

    top: $unit-l;
    left: $unit-l;

  }

  @include breakpoint(large) {

    top: $unit-xl;
    left: $unit-xl;

  }

  @include rtl {

    right: $unit-m;

    @include breakpoint(medium) {

      right: $unit-l;

    }

    @include breakpoint(large) {

      right: $unit-xl;

    }

  }

  .c-modal-poll--annotate-edit.c-modal-poll--annotate-single &,
  .c-modal-poll--annotate-edit.c-modal-poll--annotate-multiple & {

    transform: scale(1);
    opacity: 1;
    pointer-events: auto;

    @include states {

      // background-color: #fff;
      color: $color-text-strong-invert;
      background-color: $color-accent-red;

    }

  }

}

/*
 * Add
 *
 * Visible on every screen. Has a modifier
 * class on the first screen to center it
 * while it's the only button on the page.
 */

.c-annotate-actions__add {

  position: absolute;
  bottom: $unit-m;
  right: $unit-m;
  transform: translateY(0);

  display: flex;

  white-space: nowrap;

  &:focus {

    box-shadow: $box-shadow-medium, 0 0 0 .1875rem rgba($color-accent-green, .5);

  }

  .c-button__value {

    display: inline-block;
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: .5rem;
    margin-right: -.375rem;

    font-size: .875rem;
    color: $color-text-strong;
    border-radius: 50%;
    background-color: #fff;

  }

  @include breakpoint(medium) {

    bottom: $unit-l;
    right: $unit-l;

    .c-button__value {

      font-size: 1rem;

    }

  }

  @include breakpoint(large) {

    bottom: $unit-xl;
    right: $unit-xl;

  }

  @include rtl {

    right: auto;
    left: $unit-m;

    .c-button__value {

      margin-right: .5rem;
      margin-left: -.375rem;

    }

    @include breakpoint(medium) {

      left: $unit-l;

    }

    @include breakpoint(large) {

      left: $unit-xl;

    }

  }

  .c-modal-poll--annotate-done &,
  .c-modal-poll--annotate-edit & {

    opacity: 0;
    transform: translateY($unit-m);
    pointer-events: none;

  }

  &.c-annotate-actions__add--center {

    right: 50%;
    transform: translateX(50%);
    
    @include rtl {
      left: auto;
    }

  }

}

/*
 * Finish
 *
 * Submitting all pins after adding them.
 */

.c-annotate-actions__finish {

  position: absolute;
  bottom: $unit-m;
  left: $unit-m;
  transform: translateY(0);

  transition: all .1s $transition-timing-cubic;

  &:focus {

    box-shadow: $box-shadow-medium, 0 0 0 .1875rem rgba($color-primary-dark, .2);

  }

  @include breakpoint(medium) {

    bottom: $unit-l;
    left: $unit-l;

  }

  @include breakpoint(large) {

    bottom: $unit-xl;
    left: $unit-xl;

  }

  @include rtl {

    right: $unit-m;
    left: auto;

    @include breakpoint(medium) {

      right: $unit-l;

    }

    @include breakpoint(large) {

      right: $unit-xl;

    }

  }

  .c-modal-poll--annotate-done &,
  .c-modal-poll--annotate-edit & {

    opacity: 0;
    transform: translateY($unit-m);
    pointer-events: none;

  }

  &.c-annotate-actions__finish--center {

    left: 50%;
    transform: translateX(-50%);

  }

}

/*
 * Map controls
 *
 * Controls for interacting with the map
 * on desktop: zoom in, zoom out, center.
 */

.c-annotate-actions__area {

  position: absolute;
  top: $unit-m;
  right: $unit-m;

  display: flex;
  flex-direction: column;

  // Hide zoom in/out/fill controls on mobile: Disabled
  @include breakpoint(to-medium) {

    // display: none;

  }

  @include breakpoint(medium) {

    top: $unit-l;
    right: $unit-l;

  }

  @include breakpoint(large) {

    top: $unit-xl;
    right: $unit-xl;

  }

  .c-button__label {

    @include hide-visually;

  }

  .c-button {

    padding: 0;

    min-width: 2.5rem;
    min-height: 2.5rem;

    font-size: 1rem;
    background-color: #fff;
    user-select: none;

    @include states {

      background-color: $color-button-regular-hover;

    }

  }

  @include rtl {

    right: auto;
    left: $unit-m;

    @include breakpoint(medium) {

      left: $unit-l;

    }


    @include breakpoint(large) {

      left: $unit-xl;

    }

  }

}

.c-annotate-actions__zoom-in {

  border-radius: 1.25rem 1.25rem 0 0;

}

.c-annotate-actions__zoom-out {

  border-radius: 0 0 1.25rem 1.25rem;

}
.c-annotate-actions__center {

  margin-top: $unit-s;

  border-radius: 1.25rem;

}
