/* =Components: Section intro
===================================================== */

.c-section-intro {

  display: flex;
  align-items: center;

  @include responsive-spacing(margin-top, $container-proportion, negative);
  @include responsive-spacing(padding-top, $container-proportion);
  @include responsive-spacing(padding-bottom, $container-proportion);
  @include responsive-spacing(margin-bottom, $container-proportion);

  box-shadow: inset 0 -.0625rem 0 0 $color-border-weak;

  @include rtl {

    direction: rtl;

  }

}

.c-section-intro__title {

  font-size: ms(0);
  line-height: ms(2);

  @include breakpoint(extra-small) {

    font-size: ms(1);

  }

}

.c-section-intro__action {

  display: flex;
  align-items: center;

  margin-left: auto;

  @include rtl {

    margin-left: 0;
    margin-right: auto;

  }

  p {

    margin-bottom: 0;

    font-weight: $regular;
    color: $color-text-weak;

    margin-right: $unit-xs;

    &:last-child {

      margin-right: 0;

    }

    @include rtl {

      margin-right: 0;
      margin-left: $unit-xs;

    }

  }

  .c-button {

    margin-left: $unit-xs;

  }

}

/*
 * Modifiers
 */

.c-section-intro--no-margin {

  box-shadow: none;
  margin-bottom: 0;

}