/* =Components: Card news item
===================================================== */

.c-card-news {

  position: relative;

  display: flex;
  flex-direction: column;

  margin-bottom: $unit-l;
  padding-bottom: $unit-l;
  border-bottom: .0625rem solid $color-border-weak;

  transition: all .1s ease-in;

  @include rtl {

    direction: rtl;

  }

  @include breakpoint(small) {

    flex-direction: row;
    margin-bottom: $unit-l;
    padding-bottom: 0;
    border-bottom: none;

    border-radius: $border-radius-medium;
    background-color: #fff;

    box-shadow: inset 0 0 0 .0625rem $color-border-weak;

  }

  &:hover {

    // .c-card-news__figure img {

    //   transform: scale(1.025);

    // }

  }

}

.c-card-news__content {

  flex: 1;
  width: 100%;

  text-align: left;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(small) {

    padding: $unit-l;

  }

}

.c-card-news__title {

  margin-top: 0;
  margin-bottom: $unit-xs;

  font-size: ms(0);

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(extra-small) {

    align-self: center;

    font-size: ms(1);

  }

  @include breakpoint(small) {

    margin-bottom: $unit-s;

  }

  a {

    text-decoration: none;

    @include states {

      color: $color-link-hover;

    }

    &:focus {

      box-shadow: 0 .125rem 0 0 $color-accent-green;
      outline: none;

    }

  }

}

.c-card-news__meta {

  margin-bottom: $unit-xs;

  font-size: ms(-1);
  line-height: ms(2);
  text-transform: uppercase;
  font-weight: $semibold;
  color: $color-text-weak;

  @include breakpoint(small) {

    margin-bottom: $unit-s;

  }

}

.c-card-news__summary {

  p {

    margin-bottom: 0;

    font-size: ms(-1);
    line-height: ms(2);

  }

}

.c-card-news__figure {

  flex: 1;
  width: 100%;
  margin-bottom: $unit-m;
  overflow: hidden;

  background-color: $color-button-regular;

  border-radius: $border-radius-small;

  img {

    @include object-fit();

    margin-bottom: 0;
    max-width: none;
    width: 100%;
    display: block;

    transition: all .1s ease-in;

  }

  @include breakpoint(small) {

    margin-bottom: 0;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  }

  @include breakpoint(medium) {

    border-radius: $border-radius-medium;

  }

}

.c-card-news__action {

  margin-top: $unit-m;

  @include breakpoint(small) {

    margin-top: $unit-m;

  }

  // display: none;

}

.c-card-news__more {

  margin-top: $unit-m;

  font-weight: $bold;

  @include breakpoint(top) {

    // margin-bottom: $unit-m;

  }

}

.c-card-news--figure {

  @include breakpoint(medium) {

    flex-direction: row;
    align-items: flex-start;
    align-items: stretch;

    box-shadow: none;

    .c-card-news__content {

      padding-left: 0;
      min-height: 100%;

      border: .0625rem solid $color-border-weak;
      border-left: none;

      border-top-right-radius: $border-radius-medium;
      border-bottom-right-radius: $border-radius-medium;

      @include rtl {

        padding-left: $unit-l;
        padding-right: 0;

        border: .0625rem solid $color-border-weak;
        border-right: none;

        border-radius: 0;
        border-top-left-radius: $border-radius-medium;
        border-bottom-left-radius: $border-radius-medium;

      }

    }

    .c-card-news__figure {

      margin-bottom: auto;
      margin-right: $unit-l;

      background-color: transparent;

      @include rtl {

        margin-left: $unit-l;
        margin-right: 0;

      }

    }

  }

}