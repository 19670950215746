/* =Media queries
===================================================== */

// Media queries & breakpoints
@mixin breakpoint($point) {

  // Does browser support media queries
  $media-query-support: true !global !default;

  // Style changes at:
  $extra-small-value:        24em !default; // 384px
  $small-value:              36em !default; // 576px
  $medium-value:             48em !default; // 768px
  $large-value:              62em !default; // 992px
  $extra-large-value:        80em !default; // 1280px

  // Style changes at:
  $extra-small-height-value: 28em !default; // 448px
  $small-height-value:       32em !default; // 512px
  $medium-height-value:      40em !default; // 560px
  $large-height-value:       48em !default; // 768px
  $extra-large-height-value: 64em !default; // 1024px

  // Mobile first (min-width or min-height)
  @if ($media-query-support) {

    $extra-small:        "(min-width: "$extra-small-value")";
    $small:              "(min-width: "$small-value")";
    $medium:             "(min-width: "$medium-value")";
    $large:              "(min-width: "$large-value")";
    $extra-large:        "(min-width: "$extra-large-value")";

    $extra-small-height: "(min-height: "$extra-small-height-value")";
    $small-height:       "(min-height: "$small-height-value")";
    $medium-height:      "(min-height: "$medium-height-value")";
    $large-height:       "(min-height: "$large-height-value")";
    $extra-large-height: "(min-height: "$extra-large-height-value")";

    // Width
    @if $point == extra-large {
      @media #{$extra-large}                  { @content; }
    }
    @else if $point == large {
      @media #{$large}                        { @content; }
    }
    @else if $point == medium {
      @media #{$medium}                       { @content; }
    }
    @else if $point == small {
      @media #{$small}                        { @content; }
    }
    @else if $point == extra-small {
      @media #{$extra-small}                  { @content; }
    }

    // Height
    @else if $point == extra-large-height {
      @media #{$extra-large-height}           { @content; }
    }
    @else if $point == large-height {
      @media #{$large-height}                 { @content; }
    }
    @else if $point == medium-height {
      @media #{$medium-height}                { @content; }
    }
    @else if $point == small-height {
      @media #{$small-height}                 { @content; }
    }
    @else if $point == extra-small-height {
      @media #{$extra-small-height}           { @content; }
    }

  }

  // Desktop down (max-width or max-height)
  @if ($media-query-support) {

    $to-extra-small:        "(max-width: "($extra-small-value) - .0625em")"; // -1px
    $to-small:              "(max-width: "($small-value) - .0625em")";  // -1px
    $to-medium:             "(max-width: "($medium-value) - .0625em")";  // -1px
    $to-large:              "(max-width: "($large-value) - .0625em")";  // -1px
    $to-extra-large:        "(max-width: "($extra-large-value) - .0625em")"; // -1px

    $to-extra-small-height: "(max-height: "($extra-small-height-value) - .0625em")"; // -1px
    $to-small-height:       "(max-height: "($small-height-value) - .0625em")";  // -1px
    $to-medium-height:      "(max-height: "($medium-height-value) - .0625em")";  // -1px
    $to-large-height:       "(max-height: "($large-height-value) - .0625em")";  // -1px
    $to-extra-large-height: "(max-height: "($extra-large-height-value) - .0625em")"; // -1px

    // Width
    @if $point == to-extra-large {
      @media #{$to-extra-large}               { @content; }
    }
    @else if $point == to-large {
      @media #{$to-large}                     { @content; }
    }
    @else if $point == to-medium {
      @media #{$to-medium}                    { @content; }
    }
    @else if $point == to-small {
      @media #{$to-small}                     { @content; }
    }
    @else if $point == to-extra-small {
      @media #{$to-extra-small}               { @content; }
    }

    // Height
    @else if $point == to-extra-large-height {
      @media #{$to-extra-large-height}        { @content; }
    }
    @else if $point == to-large-height {
      @media #{$to-large-height}              { @content; }
    }
    @else if $point == to-medium-height {
      @media #{$to-medium-height}             { @content; }
    }
    @else if $point == to-small-height {
      @media #{$to-small-height}              { @content; }
    }
    @else if $point == to-extra-small-height {
      @media #{$to-extra-small-height}        { @content; }
    }

  }

  @else {
    @content;
  }

}