/* =Components: Upload
===================================================== */

.c-upload {

  position: relative;

  display: flex;
  width: 100%;

}

.c-upload__select {

  width: 100%;

  input[type="file"] {

    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 3rem;

  }

  input[type="file"]:focus + .c-upload__placeholder,
  input[type="file"]:hover + .c-upload__placeholder {

    background-color: $color-link-tint;
    color: $color-text-strong;

    border-color: $color-input-focus;
    color: $color-text-strong;
    box-shadow: inset 0 0 0 .0625rem $color-input-focus;

  }

}

.c-upload__placeholder {

  display: flex;
  align-items: center;
  justify-content: center;
  padding: $unit-s $unit-m;
  border: .0625rem solid $color-input-regular;
  min-height: 3rem;

  border-radius: $border-radius-small;
  color: $color-text-weak;
  background-color: $color-input-regular;

  transition: $transition-default;
  pointer-events: none;

  .o-label {

    margin-left: $unit-s;
    
    @include rtl {
      margin-left: 0;
      margin-right: $unit-s;
    }

  }

  .o-icon {

    font-size: 1.25rem;

  }

}

.c-upload__preview {

  position: relative;

  display: flex;
  max-width: 100%;
  align-items: center;
  min-height: 3rem;

  border-radius: $border-radius-small;
  background-color: $color-input-regular;

  // Loading indicator
  &:before {

    position: absolute;
    top: .75rem;
    right: .75rem;

    width: 1.5rem;
    height: 1.5rem;
    border: .125rem solid rgba($color-link, .15);
    border-top-color: $color-link;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;

    animation: loading-rotate 1s linear infinite;
    transition: $transition-default;

    content: "";

  }

}

.c-upload__image {

  height: 3rem;
  border: .125rem solid $color-input-focus;
  border-radius: $border-radius-small;

}

.c-upload__image-label {

  margin-left: $unit-m;
  margin-right: $unit-s;
  @include ellipsis;
  flex: 1;

  font-size: 1rem;
  color: $color-text-weak;
  border-radius: $border-radius-small;

}

.c-upload__image + .c-upload__image-label {

  margin-left: $unit-s;

}

.c-upload__remove {

  flex: 0 0 2rem;

  width: 2rem;
  height: 2rem;
  margin-left: auto;
  margin-right: $unit-s;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $color-danger;
  color: $color-text-strong-invert;
  border-radius: 50%;

  transition: $transition-default;
  opacity: 1;

  .o-icon {

    font-size: 1rem;

  }

  @include states {

    background-color: darken($color-danger, 5%);
    color: $color-text-strong-invert;

  }

  &:focus {

    box-shadow: 0 0 0 .1875rem lighten($color-danger, 10%);
    outline: none;

  }

}

.c-upload__image + .c-upload__remove {

  margin-left: $unit-s;

}

/*
 * State
 */

.c-upload__preview--loading {

  .c-upload__remove {

    opacity: 0;
    pointer-events: none;

  }

  &:before {

    opacity: 1;

  }

}

/*
 * Slim overrides
 *
 * We are using Slim for uploading functionality. The component
 * has been tweaked to accommodate our design as much as possible.
 * The following CSS overrides are used in conjuction with our
 * component to make it as seamless as possible.
 */

.c-upload {

  /*
   * Slim component
   */

  .slim {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: none;
    cursor: pointer;
    border-radius: $border-radius-small;

    &:focus,
    &:focus-within,
    &:hover {

      background: none;
      box-shadow: inset 0 0 0 .125rem $color-input-focus;

    }

    &[data-state*=upload],
    &[data-state*=preview],
    &[data-state*=busy] {

      box-shadow: none;

    }

    .c-annotate-menu__item--media & {

      box-shadow: none;

    }

  }

  /*
   * Slim status (hide as we already have a placeholder)
   */

  .slim .slim-area .slim-status {

    opacity: 0;

  }

  /*
   * Slim uploaded image
   */

  .slim .slim-area .slim-result {

    img {

      height: 3rem;
      width: auto!important;
      border: .125rem solid $color-input-focus;
      border-radius: $border-radius-small;

    }

    .c-annotate-menu__item--media & {

      margin: .25rem;

      img {

        height: 2.5rem;

      }

    }

  }

  /*
   * Slim loader
   */

  .slim .slim-area .slim-loader {

    transform: scale(1.25);
    top: .75rem;
    right: .75rem;

  }

  .slim[data-state*=preview] .slim-loader-foreground {

    stroke: $color-link;

  }

  .slim[data-state*=preview] .slim-loader-background {

    stroke: rgba($color-link, .15);

  }

  /*
   * Slim states (hide placeholder visually)
   */

  [data-state*=upload] + .c-upload__placeholder,
  [data-state*=preview] + .c-upload__placeholder,
  [data-state*=busy] + .c-upload__placeholder {

    .o-label,
    .o-icon {

      opacity: 0;

    }

  }

}