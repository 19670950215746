/* =Components: Personal Data Collection Form, 2022
===================================================== */

//  This file makes changes to "poll modal" due to
//  PDC Form differences

.c-modal-poll--pdc-form {
  
  
  
  //  ---------------
  //  General layout changes

  background: $color-accent-green;
  background-image: assets-path('/images/pdc-form/pdc-form--background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  
  .c-modal-poll__powered-by a {
    background-image: assets-path('/images/powered-by-gmv-alternative@4x.png');
  }
  
  .c-question--scroll .c-question__header:before,
  .c-modal-poll__skip,
  .c-modal-poll__progress {
    display: none;
  }
  
  .c-question {
    overflow: hidden;
    overflow-y: auto;
    display: block;
  }
  
  .c-question__header {
    background: none;
    padding-bottom: 0;
    text-align: center;
    
    > *:last-child {
      margin-bottom: 0;
    }
  }
  
  .c-question__title {
    display: block;
  }
  
  .c-question__content {
    overflow: visible;
    height: auto;
    padding-bottom: rem(48);
    position: relative;
  }



  //  ---------------
  //  Layout optimisations for higher resolutions
  
  .c-question__header,
  .l-form,
  .c-question__description {
    margin-left: auto;
    margin-right: auto;
  }
  
  .c-question__description {
    max-width: rem(400);
  }
  
  .l-form  {
    max-width: rem(400);
  }
  
  
  
  //  ---------------
  //  Intro graphic + header shadow when scrolling
  
  .c-question {
    
    //  We added header shadow for PDC Form only, since we're not using it
    //  elsewhere (at this point). The shadow is the same as the one used
    //  on .c-demographic (copied from there, classes were adopted).
    
    &:before {
      position: absolute;
      z-index: 999;
      top: 0;
      left: 0;
      right: 0;
      height: 1rem;
      background-image: radial-gradient(farthest-side at 50% 0, rgba($color-primary-dark,.1), rgba(0,0,0,0));
      opacity: 0;
      pointer-events: none;
      transition: all .1s ease-in;
      content: "";
  
      @include breakpoint(medium) {
        opacity: 0;
      }
    }
    
    &.c-question--scroll {
      
      &:before {
        opacity: 1;
      }
    }
  }

  .c-question__content {
    padding-top: rem(120);
    margin-top: 0;
    
    &:before {
      content: "";
      background: assets-path('/images/pdc-form/pdc-form--people.png') no-repeat center top;
      background-size: cover;
      width: calc(100% + 32px);
      height: rem(240);
      position: absolute;
      top: 0;
      left: 0;
    }

    @include breakpoint(medium) { 
      
      &:before {
        left: rem(-32);
        width: calc(100% + 64px);
      }
    }
    
    
    //  Following rows improve on how PDC Form is rendered on landscape mode 
    //  on smaller devices (like iPhone SE, small Android  smartphones etc.). 
    //  It removes some whitespace that is present  when using PDC Form in 
    //  portrait mode (on all devices)
    @media only screen and (orientation: landscape) and (max-height: rem(448px)) {
      padding-top: $unit-l;
    }
  }
  
  .c-question__description {
    margin-top: $unit-s;
    font-size: rem(14);
    
    @include breakpoint(small) {
      font-size: rem(16);
    }
  }
  
  @include breakpoint(to-medium) {
     
    .c-question__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    
    .c-modal-poll__progress + .c-modal-poll__content .c-question__footer {
      bottom: rem(-4px);
    }
  }
  
  
  
  //  ---------------
  //  Input types changes
  
  [type="text"], [type="email"], [type="tel"], select {
    border: 0;
    box-shadow: 0 6px 10px -5px rgba(0,0,0,.15);
    background: rgba(255,255,255,.85);
    
    &:focus {
      background: rgba(255,255,255,1);
    }
  }
  
  .o-select {
    background: none;
    box-shadow: none;
  }
  
  .o-checkbox label > .o-icon-check {
    color: $color-primary-light;
  }
  
  .o-checkbox label:before {
    border-color: $color-primary-dark;
  }
  
  .o-checkbox input[type="checkbox"]:hover + label:before {
    border-color: $color-primary-dark;
    box-shadow: 0 0 0 .25rem rgba(0,0,0,.1);
  }
  
  .o-checkbox .c-link svg {
    position: relative;
    top: rem(1);
    left: rem(4);
  }
}