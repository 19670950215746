/* =Objects: Layouts
===================================================== */

/*
 * Marketing layout
 *
 * Marketing pages use different spacing in containers
 * & different overrides
 */

.l-marketing {

  font-family: $font-body;
  color: $color-text;
  line-height: 1.5;

}

/*
 * App layout
 *
 * App pages currently coexist with old code.
 */

.l-app {}

.l-app--blocks {

  .c-section-main > .l-container {

    padding: 0;
    max-width: 100%;

  }

}

.l-app--preview {

  /* Overrides for preview mode are in `components.preview.scss` */

}

/*
 * Polls only
 *
 * These are styles that are used to hide certain parts of GMV frontend
 * if current view is "polls only".
 */
 
.l-app--polls-only {

  /* These styles can be found in `objects.layout.polls-only.scss`. */

}


/*
 * Container element
 *
 * Used for giving padding & max-width to the inner elements.
 * Varies based on type of page (marketing or app).
 */

.l-container {

  position: relative;

  margin-left: auto;
  margin-right: auto;
  @include responsive-spacing(padding);

  max-width: $container-max-width;

  &.l-container--spacing {

    @include responsive-spacing(padding-top, $container-proportion-large);
    @include responsive-spacing(padding-bottom, $container-proportion-large);

  }

  /*
   * Marketing
   */

  .l-marketing & {

    @include responsive-spacing(padding-top, $container-proportion-large);
    @include responsive-spacing(padding-bottom, $container-proportion-large);

  }

  /*
   * Style guide
   */

  .l-style-guide & {

    @include responsive-spacing(padding-top, $container-proportion-large);
    @include responsive-spacing(padding-bottom, $container-proportion-large);

  }

}

/*
 * Container tweaks
 *
 * Paddings tweaked for specific sections.
 */


.l-m-header .l-container {

  padding-top: $container-unit-small;
  padding-bottom: $container-unit-small;
  
  @include breakpoint(medium) {
    padding-top: calc($container-unit-small / 2);
    padding-bottom: calc($container-unit-small / 2);
  }

}

.l-m-footer .l-container {

  padding-top: $container-unit-small;
  padding-bottom: $container-unit-small;

}

.l-section-nav .l-container {

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding-top: 0;
  padding-bottom: 0;

}

.l-section-header .l-container {

  padding: 0;

  max-width: 100%;

}



