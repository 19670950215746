/* =Settings: Colors
===================================================== */

/*
 * Brand
 */

// Primary color
$color-primary-dark:       hsl(246, 100%, 14%); // 10% increments, 2 steps up and down
$color-primary-light:      hsl(213, 52%, 96%);  // 10% increments, 2 steps up and down

// Accent colors
$color-accent-green:       hsl(144, 57%, 67%);  // 10% increments, 2 steps up and down
$color-accent-yellow:      hsl(47, 100%, 69%);  // 10% increments, 2 steps up and down
$color-accent-red:         hsl(8, 96%, 67%);    // 10% increments, 2 steps up and down
$color-accent-blue:        hsl(205, 74%, 70%);  // 10% increments, 2 steps up and down

// Neutral colors
$color-grey:               hsl(185, 6%, 63%);   // 5% increments, 3 steps up and down

/*
 * Grey steps
 */

$color-grey-x-dark:        darken($color-grey, 30%);
$color-grey-dark:          darken($color-grey, 20%);
$color-grey-mid-dark:      darken($color-grey, 10%);
$color-grey-mid-light:     lighten($color-grey, 10%);
$color-grey-light:         lighten($color-grey, 20%);
$color-grey-x-light:       lighten($color-grey, 30%);

/*
 * Text
 */

$color-text:               hsla(246, 100%, 14%, .75);
$color-text-weak:          hsla(246, 100%, 14%, .6);
$color-text-strong:        hsla(246, 100%, 14%, 1);
$color-text-invert:        hsla(0, 0%, 100%, .75);
$color-text-weak-invert:   hsla(0, 0%, 100%, .6);
$color-text-strong-invert: hsla(0, 0%, 100%, 1);

/*
 * Social colors
 */

$color-facebook:  #3B5998;
$color-twitter:   #1DA1F2;
$color-x:         #000;
$color-linkedin:  #0077b5;
$color-instagram: #405de6;
$color-whatsapp:  #25D366;
$color-messenger: #0084FF;

// Links
$color-link:                   $color-primary-dark;
$color-link-hover:             darken($color-primary-dark, 5%);
$color-link-tint:              tint($color-link, 90%);
$color-link-outline:           $color-accent-green;

// Buttons
$color-button-regular:         $color-primary-light;
$color-button-regular-hover:   darken($color-button-regular, 10%);

$color-button-primary:         $color-primary-dark;
$color-button-primary-hover:   rgba($color-primary-dark, .8);

$color-button-alternate:       $color-accent-green;
$color-button-alternate-hover: rgba($color-accent-green, .8);

// Forms
$color-input-regular:  tint($color-primary-dark, 90%);
$color-input-focus:    tint($color-primary-dark, 60%);
$color-input-active:   tint($color-primary-dark, 60%);

// Borders
$color-border-weak:    $color-grey-x-light;
$color-border-normal:  $color-grey-light;
$color-border-strong:  $color-grey-mid-light;

// Various
$color-success:        $color-accent-green;
$color-success-tint:   tint($color-success, 90%);

$color-warning:        $color-accent-yellow;
$color-warning-tint:   tint($color-warning, 90%);

$color-danger:         $color-accent-red;
$color-danger-tint:    tint($color-danger, 90%);

$color-coins:          darken($color-accent-yellow, 10%);
$color-coins-tint:     tint($color-coins, 90%);