/* =Components: Marketing header
===================================================== */

@use "sass:list";

.c-m-header {

  @include rtl {

    direction: rtl;

  }

  background-color: $color-primary-dark;
  color: #fff;

  .l-container {

    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(list.slash(extra-small, small, medium, large, extra-large)) {

      justify-content: flex-start;

    }

  }

  .c-m-nav-header {

    display: none;

    @include breakpoint(extra-small) {

      display: block;
      margin-left: auto;

      @include rtl {

        margin-left: 0;
        margin-right: auto;

      }

    }

  }

}