/* =Components: Refactoring
===================================================== */

/*
 * Refactoring & Integration
 *
 * This document is used for temporary CSS overrides
 * that are needed due to integrating new code while
 * the old code is on its way out.
 *
 * Long term goal is to have this document empty, as that will
 * symbolise that we have swapped the original code completely.
 */


/*
 * Fact drag
 *
 * Fixing weird width/height issues
 */

.fact_drag {

  width: 100%!important;
  max-width: 100%!important;
  direction: ltr!important;

}