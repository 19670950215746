/* =Components: Profile action
===================================================== */

.c-profile-action {

  display: none!important;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-headline;
  color: $color-text-strong-invert;
  background-color: $color-link;

  border-radius: 0;
  transition: all .1s ease-in;

  .o-icon {

    font-size: 1.5rem;

  }

  @include states {

    background-color: $color-link-hover;
    opacity: 1; // For legacy reasons

  }


  @include breakpoint(to-medium) {

    position: fixed;
    z-index: $z-index-main + 10;
    right: $unit-m;
    bottom: $unit-l;

    width: 3.5rem;
    height: 3.5rem;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    border-radius: 50%;
    box-shadow: $box-shadow-medium;

    .o-icon {

      font-size: 2rem;

    }

  }

  @include breakpoint(medium) {

    margin-left: $unit-s;
    padding: .75rem 1rem;

    font-size: 1rem;
    line-height: 1.5;

    border-radius: $border-radius-small;

    .o-icon {

      margin-right: $unit-s;

    }

  }

}

.c-profile-action__label {

  color: inherit;

  @include breakpoint(to-medium) {

    @include hide-visually();

  }

}