/* =Components: Card feedback
===================================================== */

/*
 * Card feedback
 *
 * Small feedback card with CTA
 */

.c-card-feedback {

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: $unit-m;

  text-align: center;
  background-color: $color-primary-light;
  border-radius: $border-radius-medium;

  @include breakpoint(small) {

    padding: $unit-l;

  }

  @include breakpoint(large) {

    padding: $unit-xl;

  }

}

.c-card-feedback__content {

  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

}

.c-card-feedback__title {

  font-size: ms(0);
  margin-top: .125rem;
  margin-bottom: .125rem;

  @include breakpoint(extra-small) {

    font-size: ms(1);

  }

}

.c-card-feedback__description {

  max-width: 40em;
  margin-top: .5rem;

  font-size: ms(-1);
  line-height: ms(2);

  @include breakpoint(extra-small) {

    font-size: ms(0);

  }

}