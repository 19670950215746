/* =Components: Annotate menu
===================================================== */

/*
 * Annotate menu
 *
 * Simple annotate menu that can have following items:
 * - Feedback (toggles the feedback on)
 * - Upload (styles the input[type="file"] )
 */

.c-annotate-menu {

  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  padding: $unit-m;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  grid-gap: $unit-s;

  background: linear-gradient(to top,
    rgba($color-primary-light, .9) 25%,
    rgba($color-primary-light, .7) 50%,
    rgba($color-primary-light, 0));

  @include breakpoint(medium) {

    padding-top: 0;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 12rem));

    background: none;
    margin-left: auto;
    margin-right: auto;

  }

}

.c-annotate-menu__item {

  .o-label {

    font-size: 1rem;
    line-height: 1.5;

    margin-left: $unit-s;
    
    @include rtl {
      margin-left: 0;
      margin-right: $unit-s;
    }

    // Hide label by default on extra small devices
    @media (max-width: 22rem) {

      @include hide-visually();

    }

  }

  // Show label even on extra small screens
  // if there is only one item in the menu
  &:only-child {

    .o-label {

      @media (max-width: 22rem) {

        @include hide-visually(unhide);

      }

    }

  }

}

.c-annotate-menu__icon {

  position: relative;
  top: .125rem;

  font-size: 1.25rem;

}

.c-annotate-menu__action {

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $unit-s;
  min-height: 3rem;
  min-width: 3rem;
  width: 100%;

  color: $color-text;
  background-color: #fff;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-small;
  transition: $transition-default;

  @include breakpoint(extra-small) {

    padding-left: $unit-m;
    padding-right: $unit-m;

  }

  @include states {

    background-color: $color-primary-light;
    color: $color-text-strong;

  }

  &:focus,
  &:focus-within {

    outline: none;

  }

}

/*
 * Style overrides for the `c-upload` component
 */

.c-annotate-menu__item--media {

  .c-upload__select {

    border-radius: $border-radius-small;
    background-color: #fff;
    box-shadow: $box-shadow-small;

    input[type="file"]:focus + .c-upload__placeholder,
    input[type="file"]:hover + .c-upload__placeholder {

      background-color: $color-primary-light;
      box-shadow: none;
      border-color: transparent;

    }

  }

  .c-upload__placeholder {

    border-color: transparent;
    background-color: #fff;

  }

  .c-upload__preview {

    width: 100%;

    background-color: #fff;
    box-shadow: $box-shadow-small;

  }

  .c-upload__image {

    max-height: 2.5rem;
    width: auto;
    margin: .25rem;

  }

  .c-upload__image + .c-upload__remove {

    margin-left: auto;

  }

}