/* =Objects: Icons
===================================================== */

.o-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.o-icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.o-icon-microphone {
  width: 0.6875em;
}

.o-icon-trash {
  width: 0.875em;
}

.o-icon-swipe-indicator {
  width: 1.5em;
}

.o-icon-minus {
  width: 0.75em;
}

.o-icon-plus {
  width: 0.75em;
}

.o-icon-expand {
  width: 0.875em;
}

.o-icon-eye {
  width: 1.125em;
}

.o-icon-male-filled {
  width: 0.375em;
}

.o-icon-male {
  width: 0.5em;
}

.o-icon-arrow-down {
  width: 0.875em;
}

.o-icon-arrow-up {
  width: 0.875em;
}

.o-icon-calendar-star {
  width: 0.875em;
}

.o-icon-calendar {
  width: 0.875em;
}

.o-icon-bookmark {
  width: 0.75em;
}

.o-icon-share {
  width: 1.125em;
}

.o-icon-share-square {
  width: 1.125em;
}

.o-icon-globe {
  width: 0.96875em;
}

.o-icon-whatsapp {
  width: 0.875em;
}

.o-icon-facebook-messenger {
  width: 0.875em;
}

.o-icon-map-pin {
  width: 0.5625em;
}

.o-icon-lock-alt {
  width: 0.875em;
}

.o-icon-triangle {
  width: 1.125em;
}

.o-icon-times {
  width: 0.625em;
}

.o-icon-arrow-right {
  width: 0.875em;
}

.o-icon-arrow-left {
  width: 0.875em;
}

.o-icon-angle-down {
  width: 0.625em;
}

.o-icon-angle-left {
  width: 0.375em;
}

.o-icon-angle-right {
  width: 0.375em;
}

.o-icon-angle-up {
  width: 0.625em;
}
