/* =Components: Change language
===================================================== */

.c-change-language {

  position: absolute;

  top: $unit-m;
  right: $unit-m;
  z-index: $z-index-language;

  @include breakpoint(small) {

    top: $unit-l;
    right: $unit-l;

  }

  @include breakpoint(large) {

    top: $unit-xl;
    right: $unit-xl;

  }

  @include rtl {

    direction: rtl;

    right: auto;
    left: $unit-m;

    @include breakpoint(small) {

      left: $unit-l;

    }

    @include breakpoint(large) {

      left: $unit-xl;

    }

  }

}

.c-change-language__toggle {

  display: flex;
  align-items: center;

  padding: $unit-xs $unit-s;
  border: none;

  font-size: .875rem;
  line-height: 1.4285714286;
  font-family: $font-body;
  font-weight: $semibold;

  color: $color-text;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-small;

  background-color: #fff;

  transition: $transition-default;

  .o-icon {

    margin-left: $unit-xs;

    transition: $transition-default;

  }

  &[aria-expanded="true"] {

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .o-icon {

      transform: rotate(180deg);

    }

  }

  @include rtl {

    .o-icon {

      margin-left: 0;
      margin-right: $unit-xs;

    }

  }

  &[aria-expanded="true"],
  &:active,
  &:focus,
  &:hover {
  // @include states {

    color: $color-text-strong;
    background-color: $color-button-alternate;
    opacity: 1;

  }

  &:focus {

    box-shadow: 0 0 0 .1875rem lighten($color-accent-green, 20%);

  }

  &:focus:not(:focus-visible) {

    outline: 0;

  }

  @include breakpoint(small) {

    padding: $unit-s $unit-m;

    font-size: 1rem;
    line-height: 1.5;

  }

}

.c-change-language__dropdown {

  position: absolute;
  top: 100%;
  right: 0;

  box-shadow: $box-shadow-large;
  background-color: #fff;
  border-radius: $border-radius-small;
  border-top-right-radius: 0;

  overflow: hidden;

  @include rtl {

    right: auto;
    left: 0;

    border-top-left-radius: 0;
    border-top-right-radius: $border-radius-small;

  }

  &[aria-hidden="true"] {

    display: none;

  }

  &[aria-hidden="false"] {

    display: block;

  }

  animation: scale-in-show .25s $transition-timing-cubic forwards;
  transform-origin: center top;

}