/* =Components: Rating
===================================================== */

/*
 * Rating
 *
 * Rating control that is used for 
 * "out of 5" question type
 *
 * For mobile Safari (prior to iOS 15) viewport height/width
 * bug, see this link for more info https://stackoverflow.com/questions/56622926/safari-viewport-bug-issues-with-fixed-position-and-bottom-or-top
 * 
 * To overcome this bug, we had to make all rating controls fixed height (based on
 * how big (max height) the rating icon is). To fix another bug when low/high 
 * labels were used, we had to make rating control/label fixed width, 
 * based on the maximum number of rating icons.
 */
 

//  ---------------
//  Global rating rules

  $rating-size: 56px;
  $rating-horizontal-padding: 12px;
  $rating-vertical-padding: 4px;
  $background-image-size: 512px;
  
  // the following is used to calculate min-height for 
  // .c-rating__group only
  $rating-label-height: 26px;

//  ---------------
//  Minimum rating height

  // This is used in cases where rating is used standalone
  // (without lead image or feedback form)
  $minimum-rating-height: 300px;  

//  ---------------
//  Rating number colours

  $color1:  #B80028;
  $color2:  #D80016;
  $color3:  #E6411A;
  $color4:  #E98611;
  $color5:  #F4C700;
  $color6:  #F7DD00;
  $color7:  #3FD241;
  $color8:  #00BE79;
  $color9:  #00A04B;
  $color10: #007F3F;
  
  //  Change this to false in case we don't
  //  want to use number colouring!
  $coloured_numbers: true;

.c-rating {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: -$unit-m;
  height: calc(100% + 32px);
  padding: $unit-m;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  
  @include breakpoint(medium) {
    height: calc(100% + 48px);
    margin: -$unit-l;
    padding: $unit-l;
    min-height: rem($minimum-rating-height);
  }
  
  @include breakpoint(large) {
    height: calc(100% + 64px);
    margin: -$unit-xl;
    padding: $unit-xl;
  }
}

.c-rating {

  //  ---------------
  //  This piece is used to add background
  //  images and additional graphics and shouldn't
  //  be used for anything else. This is here just
  //  to tidy everything up :)

  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(228,234,243,1) 30%, rgba(228,234,243,1) 70%, rgba(255,255,255,0) 100%);
  
  .c-rating__media,
  .l-form {
    position: relative;
    z-index: 20;
  }
  
  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  
  &:before {
    width: rem(180);
    height: rem(100);
    top: rem(60);
    right: -$unit-m;
    background: assets-path('/images/rating/rating-marks-top.svg') no-repeat center center;
  }
  
  &:after {
    width: rem(180);
    height: rem(50);
    bottom: rem(80);
    left: -$unit-m;
    background: assets-path('/images/rating/rating-marks-bottom.svg') no-repeat center center;
  }
  
  @include breakpoint(medium) {
    
    &:before {
      top: rem(20);
    }
    
    &:after {
      bottom: rem(20);
    }
  }
  
  
  //  Hand
  &.has-feedback-form .c-rating__group:before {
    display: none;
  }

  .c-rating__group {
    
    $hand-width: 1000px;
    $hand-height: 1087px;
    $hand-scale: 0.2;
    
    .c-rating__control,
    .c-rating__labels {
      position: relative;
      z-index: 10;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: rem($hand-width * $hand-scale);
      height: rem($hand-height * $hand-scale);
      background: assets-path('/images/rating/rating-hand.png') no-repeat center center;
      background-size: rem($hand-width * $hand-scale) rem($hand-height * $hand-scale);
      display: none !important;
      
      //  =info:
      //  We removed the hand since it was buggy with aligning it to the rest
      //  of the UI. We might introduce it later.
    }
    
    @include breakpoint(medium) {
      
      $hand-scale: 0.2;
      
      &:before {
        bottom: rem(-20);
        right: rem(-35);
        transform: rotate(-25deg);
      }
    }
  }
  
  
  //  Additional styles to fix issue with image being cut-off when both
  //  lead image and feedback form are present
  &.has-lead-image.has-feedback-form {
    display: block;
    
    .c-rating__labels {
      min-width: auto;
    }
  }
}
 
.c-rating__group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-left: 0;
  max-width: rem(500);
  z-index: 10;
  
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  
  > * {
    margin: auto;
  }
  
  .c-rating__control {
    margin-bottom: 0;
  }
  
  .c-rating__labels {
    margin-top: 0;
  }
  
  .has-lead-image & {
    margin-top: $unit-xl;
  }
  
  .has-lead-image.has-feedback-form & {
    margin-bottom: $unit-xl;
  }
  
  @include breakpoint(large) {

    .has-lead-image & {
      margin-top: $unit-xl*1.5;
    }
  
    .has-lead-image.has-feedback-form & {
      margin-bottom: $unit-xl*1.5;
    }
  }
}

.c-rating__media {
  margin-bottom: 0;

  img {
    width: 100%;
    display: block;
    margin-bottom: 0;
    border-radius: $border-radius-small;
  }
}

.c-rating .l-form {
  margin-bottom: 0;
}

.c-rating__labels {
  min-width: 100%;

  ol {
    padding: 0;
    list-style: none;
    margin: auto;
    
    justify-content: center !important;
    //  The above line was at first implemented as a =fix for
    //  https://github.com/sdesregistry/IH-Engage/issues/1970#issuecomment-1185277321
    //  but then we left it as is, as it is a more bulletproof solution
  }
  
  li {
    margin: 0;
    text-align: center;
    background: rgba(0,0,0,.07);
    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
    font-size: rem(12);
    padding: rem($rating-vertical-padding) 0;
    font-weight: $bold;
  }
  
  .c-rating__labels-container {
    display: flex;
    justify-content: space-between;
    padding: 0 rem($rating-horizontal-padding);
    
    > span {
      width: 48%;
      min-width: rem(120);
      
      word-break: break-word;
      hyphens: auto;
      //  two lines above are =fix for https://github.com/sdesregistry/IH-Engage/issues/1970#issuecomment-1185651624
    }
  }
  
  span {
    font-size: rem(15);
    line-height: 1.2;
    padding: rem($rating-vertical-padding) 0;
    color: $color-text-weak;
  }
  
  .c-rating__label-item-low { 
    text-align: left;
  }
  
  .c-rating__label-item-high {
    text-align: right;
  }
}

 
.c-rating__control {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(172,236,255);
  background-image: 
    linear-gradient(90deg, rgba(129,192,238,1) 0%, rgba(129,192,238,0) 2%),
    linear-gradient(270deg, rgba(129,192,238,1) 0%, rgba(129,192,238,0) 2%),
    linear-gradient(180deg, rgba(172,236,255,1) 0%, rgba(172,236,255,1) 2%, rgba(248,255,255,1) 4%, rgba(154,219,255,1) 6%, rgba(142,206,255,1) 14%, rgba(137,200,248,1) 34%, rgba(135,198,246,1) 48%, rgba(129,192,238,1) 90%, rgba(125,187,232,1) 92%, rgba(122,184,227,1) 97%, rgba(125,188,232,1) 100%);
  border-radius: rem(6);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
  padding: rem($rating-vertical-padding) rem($rating-horizontal-padding);
  position: relative;
  z-index: 2;
  min-height: rem(64);  // =fix for https://github.com/sdesregistry/IH-Engage/issues/1970#issuecomment-1185427020
  
  .rating-item--0 {
    display: none;
  }

  //  ------------------------------
  //  Styles for inputs/labels are as follows

  //  ------------------------------  
  //  PNG Sprite Background Positions
  //  Normal: 0 0
  //  Hover: 100% 0
  //  Checked: 0 100%;
  //  Checked Hover: 100% 100%;
  //  ------------------------------  

  label {
    display: block;
    width: 8vw;
    height: 8vw;
    background-image: assets-path('/images/interface/rating--star.png');
    background-repeat: no-repeat;
    background-size: 200% 200%;
    background-position: 0 100%;
    cursor: pointer;
  }
  
  input {
    
    &:checked {
      ~ label {
        background-position: 0 0;
      }

      + label {
        background-position: 0 100% !important;
      }

      // &:hover {
      //   + label {
      //     background-position: 100% 100%;
      //   }
      // }
    }

    &:focus,
    &:hover {
      ~ label {
        background-position: 0 0;
      }

      + label {
        background-position: 100% 0;
      }
    }
    
  }
}

.c-rating--items-1,
.c-rating--items-2,
.c-rating--items-3 {
  
  $rating-size: 14vw;
  $max-rating-size: 80px;
  
  .c-rating__control,
  .c-rating__labels-container {
    grid-gap: rem(4);
  }
  
  .c-rating__control,
  .c-rating__labels {
    min-width: 100%;
    margin: 0 auto;
    
    @include breakpoint(extra-small) {
      min-width: rem(300);
    }
  }
  
  .c-rating__control label {
    width: $rating-size;
    height: $rating-size;
    max-width: rem($max-rating-size);
    max-height: rem($max-rating-size);
  }
  
  .c-rating__labels li {
    width: $rating-size;
    max-width: rem($max-rating-size);
  }
}

.c-rating--items-4,
.c-rating--items-5 {
  
  $rating-size: 12vw;
  $max-rating-size: 56px;
  
  .c-rating__control,
  .c-rating__labels {
    min-width: 100%;
    margin: 0 auto;
    
    @include breakpoint(extra-small) {
      min-width: rem(300);
    }
  }
  
  .c-rating__group {
    min-height: rem($max-rating-size + $rating-label-height);
  }
  
  .c-rating__control,
  .c-rating__labels-container {
    grid-gap: rem(3);
  }
  
  .c-rating__control label {
    width: $rating-size;
    height: $rating-size;
    max-width: rem($max-rating-size);
    max-height: rem($max-rating-size);
  }
  
  .c-rating__labels ol {
  }
  
  .c-rating__labels li {
    width: $rating-size;
    max-width: rem($max-rating-size);
  }
}

.c-rating--items-6,
.c-rating--items-7 {
  
  $rating-size: 10vw;
  $max-rating-size: 56px;
  
  .c-rating__group {
    min-height: rem($max-rating-size + $rating-label-height);
  }

  .c-rating__control,
  .c-rating__labels-container {
    grid-gap: rem(2);
    min-width: $rating-size*7;
    
    @include breakpoint(small) {
      min-width: rem($max-rating-size*7 + $rating-horizontal-padding*2);
    }
  }
  
  .c-rating__control label {
    width: $rating-size;
    height: $rating-size;
    max-width: rem($max-rating-size);
    max-height: rem($max-rating-size);
  }
  
  .c-rating__labels li {
    width: $rating-size;
    max-width: rem($max-rating-size);
  }
}

.c-rating--items-8,
.c-rating--items-9,
.c-rating--items-10 {
  
  $rating-size: 7.4vw;
  $max-rating-size: 48px;
  
  .c-rating__group {
    min-height: rem($max-rating-size + $rating-label-height);
  }

  .c-rating__control,
  .c-rating__labels-container {
    grid-gap: rem(1);
    min-width: $rating-size*10;
    
    @include breakpoint(small) {
      min-width: rem($max-rating-size*10 + $rating-horizontal-padding*2);
    }
  }
  
  .c-rating__control label {
    width: $rating-size;
    height: $rating-size;
    max-width: rem($max-rating-size);
    max-height: rem($max-rating-size);
  }
  
  .c-rating__labels li {
    width: $rating-size;
    max-width: rem($max-rating-size);
  }
}

@if $coloured_numbers {
  
  .c-rating .c-rating__labels-container > li {
    color: #fff;
    text-shadow: 0 0 2px rgba(0,0,0,.5);
  }
  
  .c-rating--items-1 {
    .c-rating__labels-container > li:nth-child(1) { background: $color9  }
  }
  
  .c-rating--items-2 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color9  }
  }
  
  .c-rating--items-3 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color6  }
    .c-rating__labels-container > li:nth-child(3) { background: $color9  }
  }
  
  .c-rating--items-4 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color5  }
    .c-rating__labels-container > li:nth-child(3) { background: $color8  }
    .c-rating__labels-container > li:nth-child(4) { background: $color9  }
  }
  
  .c-rating--items-5 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color4  }
    .c-rating__labels-container > li:nth-child(3) { background: $color6  }
    .c-rating__labels-container > li:nth-child(4) { background: $color9  }
    .c-rating__labels-container > li:nth-child(5) { background: $color10  }
  }
  
  .c-rating--items-6 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color4  }
    .c-rating__labels-container > li:nth-child(3) { background: $color6  }
    .c-rating__labels-container > li:nth-child(4) { background: $color7  }
    .c-rating__labels-container > li:nth-child(5) { background: $color8  }
    .c-rating__labels-container > li:nth-child(6) { background: $color9  }
  }
  
  .c-rating--items-7 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color4  }
    .c-rating__labels-container > li:nth-child(3) { background: $color5  }
    .c-rating__labels-container > li:nth-child(4) { background: $color6  }
    .c-rating__labels-container > li:nth-child(5) { background: $color7  }
    .c-rating__labels-container > li:nth-child(6) { background: $color8  }
    .c-rating__labels-container > li:nth-child(7) { background: $color9  }
  }
  
  .c-rating--items-8 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color3  }
    .c-rating__labels-container > li:nth-child(3) { background: $color5  }
    .c-rating__labels-container > li:nth-child(4) { background: $color6  }
    .c-rating__labels-container > li:nth-child(5) { background: $color7  }
    .c-rating__labels-container > li:nth-child(6) { background: $color8  }
    .c-rating__labels-container > li:nth-child(7) { background: $color9  }
    .c-rating__labels-container > li:nth-child(8) { background: $color10 }
  }
  
  .c-rating--items-9 {
    .c-rating__labels-container > li:nth-child(1) { background: $color2  }
    .c-rating__labels-container > li:nth-child(2) { background: $color3  }
    .c-rating__labels-container > li:nth-child(3) { background: $color4  }
    .c-rating__labels-container > li:nth-child(4) { background: $color5  }
    .c-rating__labels-container > li:nth-child(5) { background: $color6  }
    .c-rating__labels-container > li:nth-child(6) { background: $color7  }
    .c-rating__labels-container > li:nth-child(7) { background: $color8  }
    .c-rating__labels-container > li:nth-child(8) { background: $color9  }
    .c-rating__labels-container > li:nth-child(9) { background: $color10 }
  }
  
  .c-rating--items-10 {
    .c-rating__labels-container > li:nth-child(1)  { background: $color1  }
    .c-rating__labels-container > li:nth-child(2)  { background: $color2  }
    .c-rating__labels-container > li:nth-child(3)  { background: $color3  }
    .c-rating__labels-container > li:nth-child(4)  { background: $color4  }
    .c-rating__labels-container > li:nth-child(5)  { background: $color5  }
    .c-rating__labels-container > li:nth-child(6)  { background: $color6  }
    .c-rating__labels-container > li:nth-child(7)  { background: $color7  }
    .c-rating__labels-container > li:nth-child(8)  { background: $color8  }
    .c-rating__labels-container > li:nth-child(9)  { background: $color9  }
    .c-rating__labels-container > li:nth-child(10) { background: $color10 }
  }
}