/* =Components: Pseudo Modals
===================================================== */

/*
 * Pseudo Modals
 *
 * These are used to show additional information, either
 * within a modal or somewhere else on the page. They
 * are enclosed to a certain container area of the page.
 */

.c-pseudo-modal__container {
  
}

.c-pseudo-modal {
  background: #fff;
  border-radius: $border-radius-medium;
  padding: $unit-m;
  position: absolute;
  bottom: 0;
  left: 0;
  display: grid;
  grid-gap: $unit-s;
  width: 100%;
  z-index: 1000;
  transition: all ease-out .15s;
  transform: translateY(32px);
  opacity: 0;
  visibility: hidden;
  
  &.is-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .c-button {
    border-radius: 1.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    box-shadow: none !important;
  }
  
  @include breakpoint(extra-small) {
    padding: $unit-l;
  }
  
  @include breakpoint(small) {
    grid-gap: $unit-m;
  }
}

.c-pseudo-modal__title {
  font-size: rem(16);
  line-height: 1.25;
  font-weight: $medium;
  letter-spacing: -0.1px;
  
  @include breakpoint(small) {
    font-size: rem(18);
  }
  
  @include breakpoint(medium) {
    font-size: rem(20);
    letter-spacing: -0.2px;
  }
}

.c-pseudo-modal__content {
  font-size: rem(16);
  line-height: 1.25;
  font-weight: $medium;
  letter-spacing: -0.1px;
  
  * {
    font-size: rem(14);
    line-height: 1.4;
    
    @include breakpoint(small) {
      font-size: rem(15);
    }
    
    @include breakpoint(medium) {
      font-size: rem(16);
    }
  }
  
  *:last-child {
    margin-bottom: 0;
  }
}

.c-pseudo-modal__footer {
  display: flex;
  justify-content: space-between;
}