/* =Custom mixins
===================================================== */

/*
 * Responsive spacing
 *
 * Used on container or elements that need to overlap
 * container padding that changes on different
 * resolutions.
 */

@use "sass:math";

@mixin responsive-spacing($attribute, $proportion: false, $negative: false ) {

  @if ( $negative ) {

    #{$attribute}: 0 - ($container-unit-small * $proportion);

    @include breakpoint(small) {
      #{$attribute}: 0 - ($container-unit-medium * $proportion);
    }

    @include breakpoint(large) {
      #{$attribute}: 0 - ($container-unit-large * $proportion);
    }

  }

  @else if ( $proportion ) {

    #{$attribute}: $container-unit-small * $proportion;

    @include breakpoint(small) {
      #{$attribute}: $container-unit-medium * $proportion;
    }

    @include breakpoint(large) {
      #{$attribute}: $container-unit-large * $proportion;
    }

  }

  @else {

    #{$attribute}: $container-unit-small;

    @include breakpoint(small) {
      #{$attribute}: $container-unit-medium;
    }

    @include breakpoint(large) {
      #{$attribute}: $container-unit-large;
    }

  }

}

 /*
  * Object fit mixin
  *
  * This mixin can be used to set the object-fit:
  * @include object-fit(contain);
  * or object-fit and object-position:
  * @include object-fit(cover, top);
  *
  * Source: https://github.com/bfred-it/object-fit-images/blob/master/preprocessors/mixin.scss
 */

@mixin object-fit($fit: fill, $position: null){

  object-fit: $fit;

  @if $position {

    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';

  } @else {

    font-family: 'object-fit: #{$fit}';

  }

}


/*
 * States
 *
 * Shorthand for hover, focus and active states
 */

@mixin states(){

  &:hover,
  &:focus,
  &:active {

    @content;

  }

}


/*
 * Right to left
 *
 * Apply styles for RTL direction
 */

@mixin rtl {

  // Proposed class
  .direction-rtl &,
  // HHL class fallback
  ._rtl &, {

    @content;

  }

}

/*
 * Left to right
 *
 * Force LTR styles in a RTL layout
 * E.g. email form inputs
 */

@mixin force-ltr {

  .u-force-ltr & {

    @content;

  }

}


/*
 * PX to REM convert
 *
 * Converts values in pixels to REM
 */

@function rem($pxval) {

  @if not unitless($pxval) {
    $pxval: strip-unit($pxval);
  }

  $base: 16;
  @if not unitless($base) {
    $base: strip-unit($base);
  }

  @return math.div($pxval, $base) * 1rem;
}
