/* =Components: Card poll
===================================================== */

/*
 * Card poll
 *
 * Small card with poll informaiton and CTA
 */

.c-card-poll {

  position: relative;

  margin-bottom: $unit-l;

  .no-cssgrid & {

    display: flex;
    flex-direction: column;

  }

  @include rtl {

    direction: rtl;

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-xl;

  }

  /*
   * Styles
   */

  box-shadow: $box-shadow-small;
  border-radius: $border-radius-small;
  transition: $transition-default;

  @include breakpoint(medium) {

    box-shadow: $box-shadow-medium;

    border-radius: $border-radius-medium;

  }

}

.c-card-poll__content {

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: $unit-m;

  border-radius: $border-radius-small;

  @include breakpoint(medium) {

    padding: $unit-l;

    border-radius: $border-radius-medium;

  }

  .cssgrid & {

    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: "title action"
                         "meta meta";
    grid-gap: $unit-xs;

  }

  .no-cssgrid & {

    position: relative;
    display: flex;
    flex-direction: column;

  }

}

.c-card-poll__figure + .c-card-poll__content {

  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @include breakpoint(medium) {

    border-top-left-radius: 0;
    border-top-right-radius: 0;

  }

}

.c-card-poll__title {

  margin-top: 0;
  margin-bottom: $unit-xs;

  font-size: ms(0);

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  .no-cssgrid & {

    padding-right: 8rem;
    width: 100%;

  }

  .cssgrid & {

    margin-top: auto;
    margin-bottom: auto;

    grid-area: title;

  }

  @include breakpoint(large) {

    font-size: ms(1);

  }

}

.c-card-poll__meta {

  display: flex;

  font-size: ms(-1);
  color: $color-text-weak;

  .no-cssgrid & {

    margin-bottom: $unit-s;

  }

  .cssgrid & {

    margin-bottom: 0;
    grid-area: meta;

  }

  .o-icon {

    margin-top: auto;
    margin-bottom: auto;
    margin-right: $unit-s;

    font-size: 1.25rem;
    line-height: 1;

    @include rtl {

      margin-left: $unit-s;
      margin-right: 0;

    }

  }

  @include breakpoint(medium) {

    .no-cssgrid & {

      margin-bottom: 0;

    }

    .cssgrid & {

      grid-area: meta;

    }


  }

  @include breakpoint(large) {

    font-size: ms(0);

  }

}

.c-card-poll__figure {

  margin-bottom: 0;

  border-top-left-radius: $border-radius-small;
  border-top-right-radius: $border-radius-small;

  picture {

    display: block;

    border-radius: inherit;

  }

  img {

    margin-bottom: 0;
    max-width: none;
    width: 100%;
    display: block;

    border-radius: inherit;
    transition: all .1s ease-in;

  }

  @include breakpoint(medium) {

    border-top-left-radius: $border-radius-medium;
    border-top-right-radius: $border-radius-medium;

  }

}

.c-card-poll__action {

  position: static;

  .no-cssgrid & {

    position: absolute;
    top: $unit-s;
    right: $unit-s;

    @include breakpoint(small) {

      top: $unit-m;
      right: $unit-m;

    }

  }

  .cssgrid & {

    grid-area: action;

    margin-top: -.25rem;
    margin-bottom: -.25rem;

  }

  &:before {

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    border-radius: $border-radius-small;
    transition: $transition-default;

    content: "";

    @include breakpoint(small) {

      border-radius: $border-radius-medium;

    }

  }

  @include states {

    &:before {

      box-shadow: 0 0 0 .25rem $color-accent-green;

    }

  }

  @include breakpoint(to-medium) {

    padding: .25rem .75rem;

    font-size: .875rem;
    line-height: 1.4285714286;

    .o-icon {

      position: relative;
      top: 0;
      height: 1.25rem;

      vertical-align: top;
      font-size: 1rem;

    }

  }

}


/*
 * Flaoting
 */

.c-card-poll--floating {

  @include breakpoint(medium) {

    box-shadow: none;

    &:hover {

      .c-card-poll__figure {

        box-shadow: 0 0 0 .25rem $color-accent-green;

      }

      .c-card-poll__action:before {

        box-shadow: $box-shadow-large;

      }

    }

    .c-card-poll__figure {

      margin-bottom: $unit-l;

      border-radius: $border-radius-medium;
      box-shadow: $box-shadow-medium;

    }

    .c-card-poll__content {

      position: absolute;
      left: $unit-l;
      right: $unit-l;
      bottom: 0;

      height: auto;

      border-radius: $border-radius-medium;
      background-color: #fff;
      box-shadow: $box-shadow-medium;

      .cssgrid & {

        grid-template-areas: "title action"
                             "meta action";

      }

    }

    .c-card-poll__action {

      padding: .625rem 1rem;
      margin-top: auto;
      margin-bottom: auto;

      font-size: 1rem;
      line-height: 1.5;

      .no-cssgrid & {

        position: absolute;
        top: 50%;
        right: $unit-l;
        transform: translateY(-50%);

      }

      .o-icon {

        top: .125rem;

      }

      @include states {

        &:before {

          box-shadow: $box-shadow-large;

        }

      }

    }

    .c-card-poll__title {

      font-size: ms(1);

    }

    .c-card-poll__meta {

      font-size: ms(0);

    }

  }

}

.c-card-poll--wide {

  @include breakpoint(medium) {

    .c-card-poll__content {

      .no-cssgrid & {

        position: relative;

      }

      .cssgrid & {

        grid-template-areas: "title action"
                             "meta action";

      }

    }

    .c-card-poll__action {

      padding: .625rem 1rem;
      margin-top: auto;
      margin-bottom: auto;

      font-size: 1rem;
      line-height: 1.5;

      .no-cssgrid & {

        position: absolute;
        top: 50%;
        right: $unit-m;
        transform: translateY(-50%);

      }

      .o-icon {

        top: .125rem;

      }

    }

    .c-card-poll__title {

      font-size: ms(1);

    }

    .c-card-poll__meta {

      font-size: ms(0);

    }

  }

}

/*
 * Completed modifier
 */

.c-card-poll--completed {

  background-color: transparent;
  box-shadow: none;

  &:hover {

    box-shadow: none;

  }

  .c-card-poll__completed {

    position: absolute;
    top: 50%;
    right: $unit-m;
    transform: translateY(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;

    background-color: $color-accent-green;
    color: $color-text-strong;

    border-radius: 50%;

    @include rtl {

      left: $unit-m;
      right: auto;

    }

    @include breakpoint(medium) {

      right: $unit-l;

      width: 3rem;
      height: 3rem;

      font-size: 1.25rem;

      .cssgrid & {

        grid-area: action;

        position: static;
        top: auto;
        right: auto;
        transform: none;

        margin-top: auto;
        margin-bottom: auto;

      }

      @include rtl {

        left: $unit-l;
        right: auto;

      }

    }

    .o-icon {

      @include rtl {

        transform: scaleX(-1);

      }

    }

  }

  .c-card-poll__content {

    position: relative;

    grid-template-areas: "title action"
                         "meta action";

    background-color: lighten($color-accent-green, 20%);
    box-shadow: none;

  }


  &.c-card-poll--floating {

    .c-card-poll__content {

      @include breakpoint(medium) {

        position: absolute;

      }

    }

  }

}

.c-card-poll--continue {

  .c-card-poll__action {

    background-color: lighten($color-accent-yellow, 10%);
    color: $color-text;

    @include states {

      background-color: $color-accent-yellow;
      color: $color-text;

    }

  }

}