/* =Components: List navigation
===================================================== */

.c-nav-list {

  @include breakpoint(to-small) {

    &:first-child {

      margin-top: 0 - $container-unit-small;

    }

  }

}

.c-nav-list__items {

  list-style: none;
  margin: 0;
  padding: 0;

  @include breakpoint(to-small) {

    margin-left: 0 - $container-unit-small;
    margin-right: 0 - $container-unit-small;

  }

  @include breakpoint(small) {

    display: grid;
    grid-gap: $unit-m;

  }

}

.c-nav-list__item {

  margin-top: 0;

}

.c-nav-list__item + .c-nav-list__item {

  margin-top: -.0625rem;

  .no-cssgrid & {

    margin-top: $unit-m;

  }

}

.c-nav-list__link {

  display: flex;
  align-items: center;
  width: 100%;

  padding: $unit-m $unit-m;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-headline;
  font-weight: $regular;
  color: $color-link;
  text-align: left;

  transition: $transition-default;

  .o-icon {

    position: relative;
    left: 0;
    margin-left: auto;

    font-size: 1.25rem;

    transition: $transition-default;

  }

  @include states {

    opacity: 1;

    background-color: $color-accent-green;
    border-color: transparent;

    .o-icon-arrow-right {

      left: .125rem;

      @include rtl {

        left: 0;
        right: .125rem;

      }

    }

  }

  @include rtl {

    .o-icon {

      margin-left: 0;
      margin-right: auto;

    }

    .o-icon-comment-alt-dots,
    .o-icon-share,
    .o-icon-arrow-right {

      transform: scaleX(-1);

    }

  }

  @include breakpoint(to-small) {

    &:first-child {

      border-top-color: transparent;

    }

    border-top: .0625rem solid $color-border-weak;
    border-bottom: .0625rem solid $color-border-weak;

  }

  @include breakpoint(small) {

    border: .0625rem solid $color-border-weak;

    border-radius: $border-radius-small;

    @include states {

      border: .0625rem solid transparent;

    }

  }

}

.c-nav-list__label {

  margin-right: $unit-xs;

}

/*
 * Modifiers
 */

.c-nav-list--narrow {

  @include breakpoint(medium) {

    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;

  }

}

.c-nav-list--grid {

  @include breakpoint(medium) {

    .c-nav-list__items {

      grid-template-columns: 1fr 1fr;
      grid-gap: $unit-l;

    }

  }

}