/* =Components: Marketing footer
===================================================== */

.c-m-footer {

  box-shadow: $box-shadow-large;

  @include rtl {

    direction: rtl;

  }

  .l-container {

    @include breakpoint(medium) {

      display: flex;
      align-items: center;

    }

  }

  .c-m-nav-footer {

    @include breakpoint(extra-small) {

      margin-left: auto;

      @include rtl {

        margin-left: 0;
        margin-right: auto;

      }

    }

  }

}

.c-m-footer__info {

  margin-right: auto;
  margin-bottom: 0;

  @include rtl {

    margin-right: 0;
    margin-left: auto;

  }

}