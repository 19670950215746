/*
 * Choice button
 *
 * A choice element that looks like a button
 */

$choice-list-height: 3rem; // Item height
$choice-list-icon-area: 3rem; // Icon space and padding

.c-choice-list {

  position: relative;

  @include rtl {

    direction: rtl;

  }

  input[type="checkbox"],
  input[type="radio"] {

    position: absolute;
    opacity: 0;

  }

  label {

    position: relative;

    display: flex;
    align-items: center;
    min-height: $choice-list-height;
    padding: $unit-s $unit-m;
    padding-right: $choice-list-icon-area;

    font-size: 1rem;
    line-height: 1.5;
    font-weight: $semibold;

    border-radius: $border-radius-small;
    background-color: rgba($color-primary-dark, .1);
    // background-color: tint($color-primary-dark, 85%);
    // background-color: mix($color-primary-dark, $color-primary-light, 10%);

    transition: $transition-default;

  }

  label:before,
  label:after {

    display: none;

  }

  .o-icon-forward,
  .o-icon-pen,
  .o-icon-check {

    position: absolute;
    top: 50%;
    right: $unit-m;
    transform: translateY(-50%);

    font-size: 1.25rem;
    opacity: 0;

  }

  .o-icon-forward,
  .o-icon-pen {

    opacity: 1;

  }

  @include rtl {

    label {

      padding-left: $choice-list-icon-area;
      padding-right: $unit-m;

    }

    .o-icon-forward,
    .o-icon-check,
    .o-icon-pen {

      right: auto;
      left: $unit-m;
      transform: translateY(-50%) scaleX(-1);

    }

  }

  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {

    // background-color: $color-primary-dark;
    // color: $color-text-strong-invert;

    color: $color-text-strong;
    background-color: $color-accent-green;

    .o-icon-check {

      opacity: 1;

    }

  }

  input[type="checkbox"]:hover + label,
  input[type="radio"]:hover + label ,
  input[type="checkbox"]:focus + label,
  input[type="radio"]:focus + label {

    box-shadow: 0 0 0 .1875rem rgba($color-primary-dark, .1);

  }

  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {

    // animation: choice-pulse .1s ease-out;

  }

}

/*
 * Other
 *
 * Show additional text field and a button for "Other" choices
 * and tweak other interactions
 */

.c-choice-list--skip {

  /* Show other input group */
  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {

    .o-icon-forward {

      opacity: 0;

    }

  }

}

/*
 * Other
 *
 * Show additional text field and a button for "Other" choices
 * and tweak other interactions
 */

.c-choice-list--other {

  /* Show other input group */
  input[type="checkbox"]:checked + label,
  input[type="radio"]:checked + label {

    .o-icon-pen {

      opacity: 0;

    }

  }

  input[type="checkbox"]:checked + label + .c-choice-list__other,
  input[type="radio"]:checked + label + .c-choice-list__other {

    opacity: 1;
    visibility: visible;

  }

  &.is-filled {

    .c-choice-list__other .c-button {

      opacity: 1;

    }

  }

  &.is-empty {

    .c-choice-list__other .c-button {

      pointer-events: none;
      opacity: 0;

    }

  }

}

.c-choice-list__other {

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  min-height: $choice-list-height;

  opacity: 0;
  visibility: hidden;
  transition: $transition-default;

  label {

    @include hide-visually();

  }

  input[type="text"] {

    padding-right: $choice-list-icon-area + $unit-m;

    font-weight: $semibold;

    @include rtl {

      padding-right: $unit-m;
      padding-left: $choice-list-icon-area + $unit-m;

    }

  }

  .c-button {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    min-width: $choice-list-icon-area;
    padding-left: $unit-xs;
    padding-right: $unit-xs;

    border-radius: 0 $border-radius-small $border-radius-small 0;
    background-color: $color-primary-dark;
    color: $color-text-strong-invert;

    @include rtl {

      right: auto;
      left: 0;

      border-radius: $border-radius-small 0 0 $border-radius-small;

    }

  }

}