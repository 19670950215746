/* =Components: Question
===================================================== */

/*
 * Question
 *
 * Question and question containers
 */

.c-question {

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  @include rtl {

    direction: rtl;

  }

  @include breakpoint(medium) {

    max-width: $question-width-default;
    // width: $question-width-default;

    padding: $unit-l;

  }

  @include breakpoint(large) {

    padding: $unit-xl;

  }

}

.c-question__header:first-child,
.c-question__media:first-child,
.c-question__content:first-child,
.c-question__footer:first-child {

  margin-top: 0;

}


/*
 * Question Header
 */

.c-question__header {

  animation: fade-in .3s ease-in forwards;
  transition: $transition-default;

  @include breakpoint(to-medium) {

    position: relative;
    z-index: $z-index-question + 10;

    padding: $unit-m;

    background-color: #fff;

  }

  @include breakpoint(medium) {

    margin-top: $unit-l;

  }

  @include breakpoint(large) {

    margin-top: $unit-xl;

  }

}


/*
 * Question Media
 */

.c-question__media {

  padding: $unit-m;

  animation: fade-in .3s ease-in forwards;

  img {

    width: 100%;
    display: block;
    margin-bottom: 0;

    border-radius: $border-radius-small;

  }

  @include breakpoint(medium) {

    padding: 0;
    margin-top: $unit-l;

    &:first-child {

      padding-top: 0;

    }

  }

  @include breakpoint(large) {

    margin-top: $unit-xl;

  }

}

.c-question__media + .c-question__header {

  @include breakpoint(to-medium) {

    margin-top: 0 - $unit-m;
    background: transparent;

  }

}


/*
 * Question Content
 */

.c-question__content {

  display: flex;
  flex-direction: column;

  @include breakpoint(to-medium) {

    position: relative;
    z-index: $z-index-question;

    padding: $unit-m;

    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    
    //  =Info: Emanuel, 2024-04-16
    //  Due to an error on iOS 16 devices, where question content would be scrollable horizontally
    //  (documented in this issue https://github.com/sdesregistry/IH-Engage/issues/2155)
    //  we removed the possibility for horizontal scroll altogether. This shouldn't impact 
    //  overall functionality.
    overflow-x: hidden;

    > *:last-child {

      padding-bottom: $unit-m;

    }

  }

  @include breakpoint(medium) {

    margin-top: $unit-l;

  }

  @include breakpoint(large) {

    margin-top: $unit-xl;

  }

}

.c-question__header:empty {

  padding: 0;

}

.c-question__header:empty + * {

  margin-top: 0;

}


/*
 * Question Footer
 */

.c-question__footer {

  position: relative;
  z-index: $z-index-points;
  //  =info, 2022-06-27
  //  This was defined as points were not showing when feedback form
  //  in rating question was visible. In case of issues elsewhere,
  //  this needs to be tested in more details. Issues might be with how
  //  the footer buttons is positioned. From what I've tested, it's working
  //  as expected. (no visible side-effects)

  display: flex;
  align-items: center;
  margin-top: auto;
  min-height: 3rem;

  animation: fade-in .3s ease-in forwards;

  @include breakpoint(to-medium) {

    position: relative;
    z-index: $z-index-question + 10;
    background-color: #fff;

    border-bottom-left-radius: $border-radius-medium;
    border-bottom-right-radius: $border-radius-medium;

    .c-button {

      width: 100%;

      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius-medium;
      border-bottom-right-radius: $border-radius-medium;

    }

  }

  @include breakpoint(medium) {

    margin-top: $unit-l;

    justify-content: center;

    .c-button {

      min-width: 20rem;

    }

  }

  @include breakpoint(large) {

    margin-top: $unit-xl;

  }

}

.c-question__footer--alt {

  padding: $unit-s $unit-s $unit-s $unit-l;
  min-height: 4rem;

  p {

    margin-bottom: 0;

  }

  .c-button {

    border-radius: $border-radius-small;
    width: auto;
    margin-left: auto;
    
    @media only screen and (orientation: landscape) {
      z-index: 1000;
    }

  }

  @include breakpoint(medium) {

    padding: 0;
    min-height: auto;

  }

  @include breakpoint(large) {

    flex-direction: column;

    p {

      margin-bottom: $unit-m;

    }

    .c-button {

      margin-left: auto;
      margin-right: auto;

    }

  }

}

/*
 * Question title
 */

.c-question__title {

  display: flex;
  align-items: center;

  font-size: 1.125rem;
  letter-spacing: -.0125em;
  font-weight: $bold;
  overflow-wrap: break-word;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @media (min-width: 22em) {

    font-size: 1.25rem;

  }

  @include breakpoint(extra-small) {

    font-size: 1.5rem;

  }

  @include breakpoint(small) {

    font-size: 1.75rem;

  }

  @include breakpoint(medium) {

    font-size: 2rem;

    .c-question--side-header &,
    .c-question--side-media & {

      flex-direction: column;
      align-items: flex-start;

      font-size: 1.5rem;

    }

  }

  @include breakpoint(large) {

    .c-question--side-header &,
    .c-question--side-media & {

      font-size: 1.75rem;

    }

  }


  /*
   * Thumbs up image on results
   */

  img {

    transform-origin: bottom left;
    animation:
      thumbs-up .5s ease 1 backwards;

    height: 2rem;

    display: block;
    margin-bottom: $unit-xs;
    margin-right: $unit-m;

    @include rtl {

      margin-left: $unit-m;
      margin-right: 0;

    }

    @include breakpoint(small) {

      height: 3rem;
      margin-bottom: $unit-s;

    }

    @include breakpoint(medium) {

      height: 4rem;
      margin-bottom: $unit-m;

    }

  }

}


@keyframes thumbs-up {

  0% {

    opacity: 0;
    transform: rotate(-20deg);

  }

  100% {

    opacity: 1;
    transform: rotate(0);
  }

}

/*
 * Scrolling
 *
 * Adding `c-question--scroll` to `c-question` will automatically
 * show a shadow on the bottom
 */

.c-question__header {

  @include breakpoint(to-medium) {

    &:before {

      position: absolute;
      top: 100%;
      left: 0;
      right: 0;

      height: 1rem;

      background-image: radial-gradient(farthest-side at 50% 0, rgba($color-primary-dark,.1), rgba(0,0,0,0));

      opacity: 0;
      pointer-events: none;
      transition: $transition-default;

      content: "";

      @include breakpoint(medium) {

        opacity: 0;

      }

    }

    .c-question--scroll & {

      &:before {

        opacity: 1;

      }

    }

  }

}

.c-question__footer {

  @include breakpoint(to-medium) {

    &:before {

      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;

      height: 1rem;

      background-image: radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.1), rgba(0,0,0,0));

      opacity: 0;
      pointer-events: none;
      transition: $transition-default;

      content: "";

    }

    .c-question--scroll & {

      &:before {

        opacity: 1;

      }

    }

  }

}


/*
 * Change Scrolling behaviour on short devices
 *
 * Modify scrolling behaviour for short devices.
 * Make the whole question scrollable.
 */

@include breakpoint(to-medium-height) {

  .c-question {

    overflow-y: auto;

  }

  .c-question__header {

    /*
     * Previously we used negative margin on `.c-question__content`
     * but it conflicted with multiple image choice question.
     */

    // padding-bottom: 0; // This conflicts the three image choice

  }

  .c-question__content {

    overflow: initial;
    height: initial;
    flex-shrink: 0; // Fixes an iOS bug with height on small screens

  }

  .c-question__footer {

  }

}

/*
 * Show footer note on small devices
 *
 * With modified scrolling behaviour on small devices
 * the note and button are not visible. This part
 * changes the styles of elemets to show them fixed
 * until user starts to scroll.
 */

// Hide scrolling indicator by default
.c-question__note p .o-icon {

  display: none;

}

// Media query
// - Show until (height) there is a fixed footer
// - Show until (width) desktop layout kicks in
@media (max-height: $medium-height-value - .0625em) and (max-width: $medium-value - .0625em) {

  .c-question__note {

    transition: $transition-default;

  }

  /*
   * Only show until user starts to scroll the question
   */

  .c-question:not(.c-question--scroll) {

    .c-question__note {

      position: fixed;
      z-index: $z-index-points;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: $unit-xl $unit-m;
      height: 2rem;

      background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,.75) 50%, rgba(255,255,255,0));
      border-bottom-left-radius: $border-radius-medium;
      border-bottom-right-radius: $border-radius-medium;

      opacity: 1;

    }

    .c-question__note p {

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $unit-m;
      min-height: 2rem;

      font-size: .875rem;
      line-height: 1.4285714286;
      font-weight: $semibold;
      text-align: center;
      color: $color-text-strong;

      background-color: $color-accent-green;
      box-shadow: $box-shadow-medium;
      border-radius: 1rem;

      .o-icon {

        display: block;
        margin-left: $unit-xs;

      }

    }

  }

}

/*
 * Modifiers
 */

/*
 * Narrow
 *
 * Make question narrow, works for question
 * types that look best in a single column.
 * E.g. Feedback
 */

.c-question--narrow {

  @include breakpoint(medium) {

    max-width: $question-width-narrow;
    width: $question-width-narrow;

  }

}

/*
 * Wide
 *
 * Make question wider, works for question
 * types that look best in a single row.
 * E.g. Other, Four image choice...
 */

.c-question--wide {

  @include breakpoint(medium) {

    max-width: $question-width-wide;
    // width: $question-width-wide;

  }

  @include breakpoint(large) {

    // width: $question-width-wide;

  }

}

/*
 * Header on the side
 *
 * Make question wider and moves the header
 * to the left side. Works best with questions
 * that are simple. E.g. slider.
 */

.c-question--side-header {

  @include breakpoint(medium) {

    max-width: $question-width-wide;
    // width: $question-width-wide;
    display: block;
    display: flex;
    flex-flow: row wrap;

    .c-question__header,
    .c-question__media,
    .c-question__content {

      margin-top: 0;

    }

    .c-question__media {}

    .c-question__header {

      width: 50%;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: $unit-l;

      &:first-child {

        margin-top: auto;

      }

      @include rtl {

        padding-left: $unit-l;
        padding-right: 0;

      }

    }

    .c-question__content {

      width: 50%;
      padding-left: $unit-l;

      @include rtl {

        padding-right: $unit-l;
        padding-left: 0;

      }

    }

    .c-question__footer {

      width: 100%;
      // margin-left: -100%;

    }

  }

}

/*
 * Media on the side
 *
 * Make question wider and moves the media image
 * to the left side, while keeping header and content
 * on the right side. Works best with questions that
 * have prominent media images. E.g. three image choice.
 */

.c-question--side-media {

  /*
   * This solution was implement for all questions.
   */

  @include breakpoint(to-medium) {

    .c-question__content {

      overflow: initial;
      height: initial;
      margin-top: 0 - $unit-m;

    }

  }

  /*
   * Default grid
   *
   * Basic grid that works in all browsers.
   */

  @include breakpoint(medium) {

    max-width: $question-width-wide;
    // width: $question-width-wide;
    display: block;

    .c-question__media {

      width: 50%;
      float: left;
      padding-right: $unit-m;

      &:first-child {

        margin-top: auto;

      }

    }

    .c-question__header,
    .c-question__content {

      float: right;
      width: 50%;
      padding-left: $unit-m;
      margin-top: 0;
      margin-bottom: 0;

    }

    .c-question__content {

      margin-top: $unit-m;

    }

    .c-question__footer {

      clear: both;
      float: left;
      width: 100%;

    }

  }

  /*
   * CSS Grid
   *
   * Fancy grid for browsers that support it.
   */

  @supports (display: grid) {

    @include breakpoint(large) {

      display: grid;
      grid-gap: $unit-xl;
      grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .c-question__media {

        width: 100%;
        margin: auto 0;
        padding-right: 0;
        grid-row: 1 / span 2;

      }

      .c-question__header {

        width: 100%;
        padding-left: 0;
        margin-top: auto;

        grid-row: 1;
        grid-column: 2;

      }

      .c-question__content {

        width: 100%;
        padding-left: 0;
        margin-top: 0;

        grid-row: 2;
        grid-column: 2;

      }

      .c-question__footer {

        margin-top: 0;

        grid-row: 3;
        grid-column: 1 / span 2;

      }


    }

  }


}


/*
 * Center header
 *
 * Center header on mobile.
 * Used for questions that have short content area
 * so that it looks better on mobile!
 */

.c-question--center-header {

  @include breakpoint(to-medium) {

    .c-question__header:first-child {

      margin-top: auto;

    }

    .c-question__content {

      height: auto;
      margin-bottom: auto;

    }

  }

}

/*
 * Invert style
 *
 * If the modal is inverted (dark),
 * tweak the colors of children elements.
 */

.c-modal-poll--invert {

  .c-question__header {

    background-color: $color-primary-dark;

  }

  .c-question__title {

    color: $color-text-strong-invert;

  }

  .c-question__content p {

    color: $color-text-strong-invert;

  }

}