/* =Utilities: Headings
===================================================== */

/*
 * Headings
 *
 * Predefined heading sizes
 */

.u-h1,
.u-h2,
.u-h3,
.u-h4 {

  font-family: $font-headline;
  font-weight: $semibold;
  line-height: 1.25;
  letter-spacing: -.025em;

}

// .u-h1 {

//   font-size: .875rem;
//   line-height: 1.4285714286;

// }

// .u-h2 {

//   font-size: .875rem;
//   line-height: 1.4285714286;

// }

// .u-h3 {

//   font-size: .875rem;
//   line-height: 1.4285714286;

// }

// .u-h4 {

//   font-size: .875rem;
//   line-height: 1.4285714286;

// }