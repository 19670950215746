/* =Components: Poll modal
===================================================== */

/*
 * Parent container
 */

.c-modal-poll-container {

  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;

  padding: $unit-m;

  background: rgba($color-primary-light, .9);

  &[aria-hidden="true"] {

    display: none;

  }

}

@keyframes modal-animate {

  0% {

    opacity: 0;
    transform: scale(.5);

  }

  100% {

    opacity: 1;
    transform: scale(1);

  }

}

/*
 * Make body not scrolalble
 */

.is-modal-opened {

  overflow: hidden;
  
  //  Position and width were defined here, to compensate for iOS 
  //  mobile Safari issues present in iOS 15+ (with autohiding address bar).
  //  It shouldn't impact anything else.

  position: fixed;
  width: 100vw;

}

html {

  //  Height is defined for <html> element here, to compensate for iOS 
  //  mobile Safari issues present in iOS 15+ (with autohiding address bar).
  //  It shouldn't impact anything else.

  height: 100vh;

}

/*
 * Modal
 */

$modal-poll-header: 3rem;
$modal-poll-progress: .25rem;
$modal-poll-header-and-progress: $modal-poll-header + $modal-poll-progress;

.c-modal-poll {

  display: flex;
  flex-direction: column;

  margin: auto 0;
  max-width: $container-max-width;
  border: none;

  border-radius: $border-radius-medium;

  background-color: #fff;
  box-shadow: $box-shadow-large;

  animation: modal-animate .25s $transition-timing-cubic forwards;

  /*
   * Mobile positioning
   *
   * Make the modal window fill the small viewport area.
   */

  @include breakpoint(to-medium) {

    position: absolute;
    top: $unit-m;
    right: $unit-m;
    left: $unit-m;
    bottom: $unit-m;

    max-width: none;

    // Expand the element even if title is really
    // small (e.g. one word) and always fill the screen
    flex: 1;

  }

  /*
   * Page reflow tweak
   *
   * When the page has a short viewport height (e.g. zoom 400%)
   * make the whole window scrollable by removing any apsolute
   * positioning.
   */

  @include breakpoint(to-extra-small-height) {

    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;

  }

  &:focus {

    outline: none;

  }

  &:after {

    display: block;
    height: $unit-m;
    margin-bottom: 0 - $unit-m;

    content: "";

  }

}

.c-modal-poll__header {

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: $modal-poll-header;

  transition: $transition-default;

}

.c-modal-poll__label {

  min-width: 10rem;

  font-size: 1rem;
  line-height: 1.5;
  font-weight: $regular;
  text-align: center;

}

.c-modal-poll__powered-by {
  
  a {
    width: rem(110);
    height: rem(28);
    display: block;
    background: url("/assets-v2/images/powered-by-gmv@4x.png");
    background-size: rem(110) rem(28);
    
    span {
      @include hide-visually();
    }
  }
}

.c-modal-poll__close,
.c-modal-poll__skip {

  position: absolute;
  top: 0;

  border: none;
  min-height: $modal-poll-header;

  background-color: transparent;

  // &:focus {

  //   outline: none;
  //   box-shadow: 0 0 0 .1875rem $color-link-outline;

  // }

  @include states {

    background-color: transparent;
    border: none;

  }

}

.c-modal-poll__skip {

  right: 0;

  font-weight: $semibold;
  color: $color-text;
  // color: $color-button-alternate;

  @include states {

    // color: $color-button-alternate-hover;
    color: $color-text-strong;

  }

  @include rtl {

    right: auto;
    left: 0;

  }

}

.c-modal-poll__close {

  left: 0;

  color: $color-text;

  @include rtl {

    left: auto;
    right: 0;

  }

  .o-icon {

    font-size: 1.25rem;
    display: block;

  }

  .c-button__label {

    @include hide-visually();

  }

  @include states {

    color: $color-text-strong;

  }

}

.c-modal-poll__progress {

  margin-left: $unit-m;
  margin-right: $unit-m;

  min-height: $modal-poll-progress;

  border-radius: $border-radius-small;
  background-color: $color-grey-x-light;
  transition: $transition-default;

}

.c-modal-poll__progress-filled {

  display: block;
  height: .25rem;

  border-radius: $border-radius-small;
  background-color: $color-accent-green;
  transition: all 1s $transition-timing-cubic;

}

.c-modal-poll__content {

  position: relative;
  height: calc(100% - #{$modal-poll-header}); // Compensate for header

}

.c-modal-poll__progress + .c-modal-poll__content {

  height: calc(100% - #{$modal-poll-header-and-progress}); // Compensate for header and progress bar

}

/*
 * Modifiers
 */

.c-modal-poll--invert {

  background-color: $color-primary-dark;

  .c-modal-poll__label {

    color: $color-text-invert;

  }

  .c-modal-poll__close {

    color: $color-text-invert;

    @include states {

      color: $color-text-strong-invert;

    }

  }

  .c-modal-poll__skip {

    color: $color-button-alternate;

    @include states {

      color: $color-button-alternate-hover;

    }

  }

  .c-modal-poll__progress {

    background-color: rgba(255,255,255,.2);

  }

}

.c-modal-poll--demographic {

  .c-modal-poll__progress + .c-modal-poll__content {

    height: calc(100% - #{$modal-poll-header}); // Compensate for header and progress bar

  }

  .c-modal-poll__progress {

    display: none;

  }

}

/*
 * Annotation tweaks
 *
 * Annotation already has a scrollable map or image
 * so we arer limiting the height of the modal on
 * tablet and desktop to avoid modal scrolling.
 */

.c-modal-poll--annotate {

  .c-question {

    overflow-y: auto;

  }

  .c-question__content {

    height: 100%;
    flex-shrink: 1;

  }

  .c-question__footer {

    display: none;

  }

  @include breakpoint(medium) {

    max-height: 100%;
    height: 50rem;

    .c-question__footer {

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      margin-top: 0;
      padding-bottom: $unit-l;
      align-items: center;

    }

  }

  @include breakpoint(large) {

    // max-height: none;

    //  =info: 
    //  Max height definition above was removed as on smaller desktop screens 
    //  bottom reactions area wasn't properly visible to desktop users.

    .c-question__footer {

      padding-bottom: $unit-xl;

    }

  }

}

/*
 * Hiding elements on annotation with multiple different reactions
 *
 * Hiding:
 * - modal header
 * - progress bar
 * - question header
 *
 * Hiding with `max-height: 0` and `overflow: hidden;`
 * so need to reset top, bottom paddings and
 * min-height to zero.
 */

.c-modal-poll--annotate-edit.c-modal-poll--annotate-multiple {

  overflow: hidden;

  .c-modal-poll__header,
  .c-modal-poll__progress,
  .c-question__header {

    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    min-height: 0;
    transform: translateY(0 - $unit-m);
    overflow: hidden;

  }

  .c-modal-poll__content {

    height: 100%;

  }

  @include breakpoint(medium) {

    .c-question__content {

      margin-top: 0;

    }

  }

}

.c-modal-poll--annotate-done {

  .c-question__footer {

    display: flex;

  }

}

/*
 * Small devices fixes
 *
 * Problem explaination:
 * Modal windows and questions are scrollable on mobile
 * but since this might conflict with the pan/zoom
 * functionality of the annotation itself, we are making
 * several exceptions.
 *
 * 1) Annotate questions are positioned absolutely within
 *    `c-question__content` to take the maximum space.
 *    Check `components.c-annotate.scss` for details.
 * 2) On first annotate screen (where you can see the question
 *    title), we add a minimum height to make sure that the
 *    question is scrollable even on small mobile phones
 *    in landscape orientation.
 * 3) On second annotate screen (edit) we also add a minimum
 *    height.
 */


.c-modal-poll--annotate {

  .c-question__content {

    min-height: 16rem; // 256px

    @include breakpoint(medium) {

      min-height: 20rem; // 320px

    }

  }

}

.c-modal-poll--annotate-edit {

  .c-question__content {

    min-height: 16rem; // 256px

    @include breakpoint(medium) {

      min-height: 30rem; // 480px

    }

  }

}

/*
 * Android fixes
 *
 * 1) When keyboard is active on Android devices, the viewport
 *    height is shrinked due to keyboard being visible.
 *    We are adding minimum height on the edit screen to
 *    correct that, so that actions on the screen (+/-/Fit)
 *    don't overlay the bottom part of the screen/textarea.
 */

.c-modal-poll--annotate-edit.c-modal-poll--annotate-multiple {

  .c-question__content {

    min-height: 28rem; // 448px [1]

  }

}