/* =Components: Card project
===================================================== */

$project-logo-size: 5rem;
$project-logo-size-medium: 8rem;

/*
 * Card project
 *
 * Small card with project information and logo/background
 */

.c-card-project {

  border-radius: $border-radius-medium;
  background-color: #fff;
  box-shadow: $box-shadow-medium;
  transition: all .1s ease-in;

  @include breakpoint(large) {

    box-shadow: $box-shadow-medium;

  }

  a {

    display: block;
    height: 100%;

    border-radius: $border-radius-medium;

    &:focus {

      box-shadow: 0 0 0 .1875rem $color-accent-green;

    }

    &:hover {

      box-shadow: 0 0 0 .1875rem $color-accent-green, $box-shadow-large;

      .c-card-poll__figure img {

        transform: scale(1.025);

      }

    }

  }



}

.c-card-project__background {

  position: relative;

  width: 100%;
  margin-bottom: 0;
  padding-bottom: 25%; // Aspect ratio 4:1

  background-color: $color-primary-light;
  // background: linear-gradient(to bottom, $color-primary-light, rgba($color-primary-light, 0));
  border-top-left-radius: $border-radius-medium;
  border-top-right-radius: $border-radius-medium;

  img {

    position: absolute;
    left: 0;
    top: 0;
    right: 0;

    display: block;
    @include object-fit();

    max-width: none;
    width: 100%;
    margin-bottom: 0;

    border-top-left-radius: $border-radius-medium;
    border-top-right-radius: $border-radius-medium;

  }

}

.c-card-project__logo {

  position: relative;
  margin-top: (0 - ($project-logo-size * 0.5) - $unit-m);
  margin-bottom: $unit-s;
  max-width: $project-logo-size;
  max-height: $project-logo-size;

  border-radius: 50%;
  box-shadow: $box-shadow-large;

  img {

    display: block;
    width: 100%;
    margin-bottom: 0;

    border-radius: 50%;

  }

}

.c-card-project__notification {

  position: absolute;
  top: $unit-xs;
  right: $unit-xs;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;

  font-size: .875rem;
  line-height: 1;
  font-weight: $medium;
  color: $color-text-strong-invert;
  text-align: center;
  background-color: darken($color-accent-red, 10%);
  border-radius: 50%;

  @include breakpoint(small) {

    width: 1.125rem;
    height: 1.125rem;

  }

}

.c-card-project__content {

  padding: $unit-m;

  display: flex;
  flex-direction: column;
  align-items: center;

}

.c-card-project__title {

  margin-bottom: 0;

  font-size: ms(0);
  font-weight: $semibold;
  text-align: center;

  @include breakpoint(small) {

    font-size: ms(1);

  }

}

.c-card-project__tagline {

  margin-bottom: 0;

  font-size: ms(-2);
  font-family: $font-headline;
  font-weight: $regular;
  text-align: center;
  color: rgba($color-text-weak, .5);

  @include breakpoint(small) {

    font-size: ms(0);

  }

}