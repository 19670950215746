/*
 * Timeline teaser
 *
 * Compact version of the timeline overview
 */

 $timeline-teaser-indicator: 1.75rem;
 $timeline-teaser-indicator-l: 2.5rem;

.c-timeline-teaser {

  position: relative;

  margin-bottom: $container-unit-small * 2;

  @include breakpoint(medium) {

    margin-bottom: $container-unit-medium * 2;

  }

  @include breakpoint(large) {

    margin-bottom: $container-unit-large * 2;

  }

  &:last-child {

    margin-bottom: 0;

  }

}

.c-timeline-teaser__heading {

  margin-top: 0;
  margin-bottom: $unit-m;

  font-size: ms(0);
  line-height: ms(1);

  @include breakpoint(small) {

    font-size: ms(1);
    line-height: ms(2);

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-l;

  }

  .c-block--dark & {

    color: $color-text-strong-invert;

  }

}

.c-timeline-teaser__action {

  margin-top: $unit-m;

  @include breakpoint(small) {

    position: absolute;
    top: 0;
    right: 0;

    margin-top: 0;

  }
  
  @include rtl {
    right: auto;
    left: 0;
  }

}

.c-timeline-teaser__visual {

  display: flex;
  align-items: center;

  margin-top: $unit-xl;
  margin-bottom: $unit-xl;

  // padding: .5rem;
  // background-color: $color-primary-light;
  // border-radius: 1.5rem;

  @include breakpoint(small) {

    margin-top: $unit-xl * 1.5;
    margin-bottom: $unit-xl * 1.5;

  }

}

.c-timeline-teaser__stage {

  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;

  &:after {

    position: absolute;
    top: 50%;
    left: $timeline-teaser-indicator;
    right: 0;

    height: .1875rem;
    margin-top: -.09375rem;

    content: "";

    @include rtl {

      right: $timeline-teaser-indicator;
      left: 0;

    }

  }

  &:last-child {

    width: auto;
    width: 2.5rem;

    &:after {

      display: none;

    }

  }

  @include breakpoint(small) {

    &:after {

      top: 50%;
      left: $timeline-teaser-indicator-l;

      @include rtl {

        right: $timeline-teaser-indicator-l;
        left: 0;

      }

    }

  }

}

.c-timeline-teaser__indicator {

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  width: $timeline-teaser-indicator;
  height: $timeline-teaser-indicator;

  border-radius: 50%;

  .o-icon {

    display: block;

  }

  .o-icon-triangle {

    position: relative;
    top: -.125rem;

  }

  &:after {

    // display: none;

    // position: absolute;
    // bottom: -2rem;

    // border: .5rem solid transparent;

    // border-bottom-color: $color-primary-light;

    // content: "";

  }

  @include breakpoint(small) {

    width: $timeline-teaser-indicator-l;
    height: $timeline-teaser-indicator-l;

    font-size: 1.25rem;

  }

}

.c-timeline-teaser__stage--completed {

  &:after {

    background-color: $color-accent-green;

  }

  .c-timeline-teaser__indicator {

    color: $color-text-strong;
    background-color: $color-accent-green;

  }

}

.c-timeline-teaser__stage--active {

  &:after {

    background-color: $color-primary-dark;
    background-image: repeating-linear-gradient(
      to right,
      $color-accent-green,
      $color-accent-green .5rem,
      $color-primary-dark .5rem,
      $color-primary-dark 1rem
    );
    animation: 1.5s timeline-teaser-gradient linear infinite;
    background-size: 1rem 1rem;

  }

  .c-timeline-teaser__indicator {

    color: $color-text-strong-invert;
    background-color: $color-primary-dark;
    // animation: 2s stage-active-pulse infinite;

  }

}

.c-timeline-teaser__stage--upcoming {

  &:after {

    background-color: transparent;
    background-image: repeating-linear-gradient(90deg,
      transparentize($color-primary-light, 1),
      transparentize($color-primary-light, 1) 3px,
      darken($color-primary-light, 10%) 3px,
      darken($color-primary-light, 10%) 6px
    );

  }

  .c-timeline-teaser__indicator {

    background-color: $color-primary-light;

  }

}

.c-timeline-teaser__stage--disruptive {

  &:after {

    background-color: lighten($color-accent-red, 25%);

  }

  .c-timeline-teaser__indicator {

    color: $color-accent-red;
    background-color: lighten($color-accent-red, 25%);

  }

}

.c-timeline-teaser__stage:hover {

  .c-timeline-teaser__content {

    display: block;

  }
}




.c-timeline-teaser__details {

  margin-top: $unit-xl;

  text-align: center;

}

.c-timeline-teaser__title {

  margin-top: $unit-s;
  margin-bottom: $unit-s;

  font-size: ms(0);
  line-height: ms(2);
  text-align: center;

  font-weight: $semibold;
  color: $color-text-strong;

  @include breakpoint(small) {

    font-size: ms(1);
    line-height: ms(2);

  }

}

.c-timeline-teaser__description {

  margin-top: $unit-s;
  margin-bottom: 0;

  text-align: center;

  p {

    margin-top: $unit-xs;
    margin-bottom: 0;

    font-size: ms(-1);
    line-height: ms(2);

    &:first-child {

      margin-top: 0;

    }

    @include breakpoint(small) {

      font-size: ms(0);
      line-height: ms(2);

    }

  }

}

.c-timeline-teaser__meta {

  margin-top: 0;
  margin-bottom: 0;

  font-size: ms(0);
  line-height: ms(2);
  text-align: center;
  color: $color-text-strong;

}

.c-timeline-teaser__tag {

  position: relative;
  display: inline-block;

  padding-left: .75rem;
  margin-left: .375rem;

  border-radius: $border-radius-small;
  font-weight: $regular;
  color: $color-text-strong;

  @include rtl {

    padding-left: 0;
    margin-left: 0;

    padding-right: .75rem;
    margin-right: .375rem;

  }

  &:before {

    position: absolute;
    top: 50%;
    left: 0;

    width: .375rem;
    height: .375rem;
    margin-top: -.1875rem;

    vertical-align: middle;
    border-radius: 50%;
    background-color: $color-text-strong;

    // animation: 2s stage-active-pulse infinite;

    content: "";

    @include rtl {

      left: auto;
      right: 0;

    }

  }



}