/* =Components: Marketing text
===================================================== */

.c-m-text {

  text-align: left;
  
  @include rtl {
    text-align: right;
    direction: rtl;
  }

  h1,
  h2,
  h3,
  h4 {

    margin-top: 1.5rem;
    margin-bottom: .5rem;

    font-family: $font-headline;
    font-weight: $semibold;
    line-height: 1.25;
    color: $color-text-strong;

    &:first-child {

      margin-top: 0;

    }

  }

  h1 {

    font-size: ms(2);

    @include breakpoint(small) {

      font-size: ms(3);

    }

  }

  h2 {

    font-size: ms(1);

    @include breakpoint(small) {

      font-size: ms(2);

    }

  }

  h3 {

    font-size: ms(0);

    @include breakpoint(small) {

      font-size: ms(1);

    }

  }

  h4 {

    font-size: ms(-1);

    @include breakpoint(small) {

      font-size: ms(0);

    }

  }

  p,
  li {

    // font-size: 1rem;
    // line-height: 1.5;
    font-size: ms(-1);
    line-height: ms(2);

    @include breakpoint(small) {

      font-size: ms(0);
      line-height: ms(2);

    }

  }

  ul {

    margin-left: 1.5rem;
    margin-bottom: 1.5rem;

    list-style: disc;

  }

  a {

    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {

      text-decoration: none;

    }

  }

}