/* =Components: Poll finished
===================================================== */

/*
 * Poll finished
 *
 * Final step in the poll
 */

.c-poll-finished {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;

  text-align: center;

  @include breakpoint(small) {

    min-width: 30rem;

  }

  // @include breakpoint(medium) {

  //   padding: $unit-xl;

  // }

  // @include breakpoint(medium) {

  //   padding: $unit-h;

  // }

}

.c-poll-finished__title {

  margin-bottom: $unit-xs;

  font-size: 1.25rem;
  font-weight: $bold;
  letter-spacing: -.0125em;

  @include breakpoint(small) {

    font-size: 1.5rem;

  }
  @include breakpoint(medium) {

    font-size: 1.75rem;

  }

}

.c-poll-finished__illustration {

  margin-bottom: $unit-m;

  img {

    display: block;
    width: 28rem;
    max-width: 100%;
    margin-bottom: 0;

  }

  // Deprecating old SVG illustration with animation

  // width: 12rem;
  // margin-bottom: $unit-m;

  // transform-origin: center center;

  // @include breakpoint(small) {

  //   width: 16rem;

  // }

  // * {

  //   transform-origin: center;
  //   transform-box: fill-box;

  // }

  // #person,
  // #scene,
  // #confetti {

  //   animation: explainer-popup .5s ease-in-out;
  //   animation-fill-mode: backwards;

  // }

  // #scene {

  //   animation-delay: .5s;

  // }

  // #person {

  //   animation-delay: 1s;

  // }

  // @for $i from 1 through 11 {

  //   #confetti-#{$i} {

  //     animation:
  //       explainer-pulse 1s infinite ease-in;

  //     animation-delay: ($i * random())#{s};

  //   }

  // }

  // #person__eyes {

  //   animation: explainer-eyes-blink 2s infinite;

  // }

}

.c-poll-finished__description {}

.c-poll-finished__options {

  margin-top: $unit-m;

  display: flex;
  flex-direction: column;
  flex-shrink: 0; // Fixes an iOS bug with height on small screens

  .c-button {

    width: 100%;
    margin-top: $unit-s;

    &:first-child {

      margin-top: 0;

    }

  }

  @include breakpoint(small) {

    .c-button {

      min-width: 20rem;
      max-width: 36rem;

    }

  }

}