/* =Components: Header section
===================================================== */

.c-section-header {

  position: relative;
  z-index: $z-index-header;
  background-color: #fff;

}

.c-section-header__profile {

  position: absolute;
  top: $unit-m;
  right: $unit-m;

  display: flex;

  @include breakpoint(large) {

    top: $unit-l;
    right: $unit-l;

  }

}