/* =Components: Pan & Zoom
===================================================== */

/*
 * Pan & Zoom
 *
 * This component is used for pan & zoom functionalities.
 * For now it is solely used for pan&zooming of an image.
 *
 * Based on:
 * .c-annotate-area
 * .c-annotate-image
 *
 * Search for
 * =container
 * =preview
 * =area
 * =actions
 * =animations
 *
 * for quick access
 * 
 */




//  ----------------------------------------

//  =Container
.c-pan-zoom {
  position: relative;
  display: flex;
  height: auto;
  flex: 1;
}




//  ----------------------------------------
//  =Preview

.c-pan-zoom__preview {
  position: relative;
}

.c-pan-zoom__preview-media {
}

.c-pan-zoom__preview-text {
  $pan-zoom-spacing-small: $unit-s;
  $pan-zoom-spacing-large: $unit-m;
  
  display: block;
  position: absolute;
  
  //  =info 2024-05-14
  //  Due to uncontrolled overlaps with text in different languages, which eventually
  //  break preview text, we decided to remove translate and use absolute position
  //  instead. To be able to center the box on higher resolutions, we use left/right
  //  position with percentage.
  
  // bottom: $pan-zoom-spacing-small;
  // left: 50%;
  // transform: translateX(-50%);

  bottom: $pan-zoom-spacing-small;
  left: $pan-zoom-spacing-small;
  right: $pan-zoom-spacing-small;
  text-align: center;
  
  //  =info
  //  New code ends here! To revert, remove the lines above, and uncomment the three
  //  lines for absolute positioning and transform/translateX.

  background: rgba(255,255,255,0.75);
  border-radius: $border-radius-small;
  padding: $unit-xs $unit-s;
  font-size: rem(14);
  white-space: nowrap;
  
  
  //  We are removing pointer events for this element, so the underlying
  //  image can be clicked even when clicking on CTA text here.
  //  Issue: https://github.com/sdesregistry/IH-Engage/issues/1926#issuecomment-1267192986
  pointer-events: none;
  
  //  =info
  //  There will be an issue with wrapping longer text on small screen
  //  due to an problem with how <span> is calculating width, when absolutely
  //  positioned and centred (it takes 50% of the width, if white-space: nowrap
  //  is not defined. Needs to be researched further.
  
  //  Update 2024-04-16: Emanuel
  //  In combination with text-wrap: wrap; the above problem is resolved.
  //  It still doesn't look that pretty, but at least edge cases will be fine.
  //  Seems we could also remove "white-space: nowrap" but since there are no other
  //  issues, I'll leave it as is.
  text-wrap: wrap;
  
  .o-icon {
    position: relative;
    top: rem(1);
  }
  
  
  @include breakpoint(small) {
    
    //  This entire part is for the fixes from 2024-05-14. Remove if not needed!
    
    bottom: $pan-zoom-spacing-large;
    left: 10%;
    right: 10%;
  }
}




//  ----------------------------------------
//  =Area
.c-pan-zoom-area {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $color-primary-light;
  display: none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.pan-zoom-modal-active {
  // animation: none;
  // =fix =test =note
  //  the above animation (when it's not set to "none") positions pseudo-modal
  //  out of centre (in some cases). We had to make it "none" so it doesn't 
  //  break the layout. In the end we decided not to use it since the problem
  //  was difficult to reproduce and the animation was being repeated each time
  //  you would open/close a pseudo modal.
  
  .c-question__content,
  .c-question__header,
  .c-question__footer {
    // display: none;
    //  =note
    //  In the end we opted not to use this because it will have much bigger
    //  impact on the entire client than we wanted it to have. Instead of
    //  using CSS-only approach (and target every question type), we
    //  removed unnecessary elements within the client itself.
  }
  
  .c-rating {
    //  =note
    //  The below lines were added to make sure we had no issues
    //  with rating question type, which uses a different code structure
    //  when displaying lead image component. Ideally, we would hide
    //  .c-rating__group and .c-fields too, but this is done programatically.
    
    &:before,
    &:after {
      display: none;
    }
  }
  
  .c-pan-zoom .c-pan-zoom-area {
    display: block;
  }
  
  .c-question__content,
  .c-lead-image {
    z-index: 1000;
  }
  
  .slick-list,
  .c-question {
    overflow: visible;
  }
  
  .c-carousel__controls {
    display: none;
  }
  
  // .c-pan-zoom-area {
  //   pointer-events: all !important;
  //   touch-action: auto !important;
  // }
  
  .slick-slider {
    // touch-action: none !important;
    // pointer-events: none !important;
    
    .slick-list,
    .slick-track {
      transform: none !important;
    }
  }
}

.c-pan-zoom__content {
  position: absolute;
  top: $unit-m;
  left: $unit-m;
  right: $unit-m;
  bottom: $unit-m;
  overflow: hidden;
  background-color: $color-primary-light;
  background-image: $background-pattern-annotate;
  box-shadow: $box-shadow-large, 0 rem(5) rem(15) rgba(0,0,0,.15);
  border-radius: $border-radius-small;
  
  @include breakpoint(large) {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    width: rem(1200);
    height: rem(800);
    max-width: 80%;
    max-height: 80%;
  }
}

.c-pan-zoom__image {
  transform: $transition-default;
}

.c-pan-zoom-image {
  //  The following code is the same as in .c-annotate-area__content
  //  See that for a detailed description (if needed)

  position: absolute;
  transform-origin: 0 0;
  cursor: move;
  animation: pan-zoom-image-move 3.5s ease 1 1s;
}

.c-pan-zoom-image__group {
  position: relative;
  background: $color-primary-dark;
  background-image: linear-gradient(30deg, $color-accent-red, $color-accent-blue, $color-accent-green);
  background-size: cover;
  box-shadow: 0 0 $unit-xl 0 rgba($color-primary-dark, .25);
}

.c-pan-zoom-image__proportion {
  display: block;
  visibility: hidden;
}




//  ----------------------------------------
//  =Actions
.c-pan-zoom-actions__area {
  //  The following code is a copy of .c-annotate-actions
  //  with added close button styles (due to being opened in pseudo-modal)

  position: absolute;
  top: $unit-m;
  right: $unit-m;
  display: flex;
  flex-direction: column;

  // Hide zoom in/out/fill controls on mobile: Disabled
  @include breakpoint(to-medium) {
    // display: none;
  }

  @include breakpoint(medium) {
    top: $unit-l;
    right: $unit-l;
  }

  @include breakpoint(large) {
    top: $unit-xl;
    right: $unit-xl;
  }

  .c-button__label {
    @include hide-visually;
  }

  .c-button {
    padding: 0;
    min-width: 2.5rem;
    min-height: 2.5rem;
    font-size: 1rem;
    background-color: #fff;
    user-select: none;

    @include states {
      background-color: $color-button-regular-hover;
    }
    
    &:focus {
      box-shadow: none;
    }
  }

  @include rtl {
    right: auto;
    left: $unit-m;

    @include breakpoint(medium) {
      left: $unit-l;
    }

    @include breakpoint(large) {
      left: $unit-xl;
    }
  }
}

.c-pan-zoom-actions__zoom-in {
  border-radius: 1.25rem 1.25rem 0 0;
}

.c-pan-zoom-actions__zoom-out {
  border-radius: 0 0 1.25rem 1.25rem;
}

.c-pan-zoom-actions__center {
  margin-top: $unit-s;
  border-radius: 1.25rem;
}

.c-pan-zoom__close {
  padding: 0;
  min-width: 2.5rem;
  min-height: 2.5rem;
  font-size: 1rem;
  background-color: #fff;
  user-select: none;
  border-radius: rem(48);
  position: absolute;
  top: $unit-s;
  left: $unit-s;
  
  .c-button__label {
    @include hide-visually;
  }

  @include states {
    background-color: $color-button-regular-hover;
  }

  @include breakpoint(medium) {
    top: $unit-l;
    left: $unit-l;
  }

  @include breakpoint(large) {
    top: $unit-xl;
    left: $unit-xl;
  }

  @include rtl {
    left: auto;
    right: $unit-m;

    @include breakpoint(medium) {
      right: $unit-l;
    }

    @include breakpoint(large) {
      right: $unit-xl;
    }
  }
}


/*
 * Visual cue
 * =animations
 *
 * Indicating that user can interact with the image
 */

@keyframes pan-zoom-image-move {

  0%,
  50%,
  70% {

    left: 0;

  }

  40%,
  60% {

    left: -2rem;

  }

}

.u-a-pan-zoom-area-touch-cue {

  &:before {

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $z-index-question;
    transform: translate(-50%, -50%);

    width: $unit-xl;
    height: $unit-xl;

    background-color: $color-accent-green;
    border-radius: 50%;
    opacity: 0;
    transition: $transition-default;

    content: "";
    pointer-events: none;

    animation: pan-zoom-image-touch-point 3.5s ease 1 1s;

    // Hide if user clicks edit within the animation duration
    .c-modal-poll--annotate-edit & {

      opacity: 0!important;

    }

  }

}

@keyframes pan-zoom-image-touch-point {

  // Initial state
  0% {

    opacity: 1;
    transform: translate(-50%, -50%) scale(0);

  }

  // Popup
  5% {

    transform: translate(-50%, -50%) scale(1.5);

  }

  // Normalise
  10% {

    transform: translate(-50%, -50%) scale(1);

  }

  // Same value as annotation-image cue.
  0%,
  50%,
  70% {

    margin-left: 0;

  }

  40%,
  60% {

    margin-left: -2rem;

  }

  // Normalise
  80% {

    opacity: 1;
    transform: translate(-50%, -50%) scale(1);

  }

  // Popup again
  90% {

    transform: translate(-50%, -50%) scale(1.5);

  }

  // End, hide the pin
  100% {

    opacity: 0;
    transform: translate(-50%, -50%) scale(0);

  }

}


@include breakpoint(to-large) {
  
  .c-modal-poll.pan-zoom-modal-active {
    
    //  =info 2022-11-02
    //  The following rules were introduced as a way to fix wrong positioning
    //  of pan/zoom component on smartphones. More info about the issue can be
    //  found in the following comment https://github.com/sdesregistry/IH-Engage/issues/2065#issuecomment-1290385543
    
    &,
    .c-modal-poll__header,
    .c-modal-poll__progress,
    .c-question,
    .c-question__header,
    .c-question__content,
    .c-question__footer,
    .c-question__footer .c-button,
    .c-rating {
      
      //  We are making every of the following elements to be 0px width/height
      //  and are basically "resetting" everything to force underlying elements
      //  to position on top of the screen, to top left corner.
      //  Then, we are removing pan zoom preview, and also repositioning
      //  pan zoom content area 70px from the bottom.
      
      max-height: 0 !important;
      max-width: 0 !important;
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      margin: 0 !important;
      padding: 0 !important;
      overflow: visible !important;
      animation: none !important;
      opacity: 1 !important;
    }
    
    .c-pan-zoom-area {
      top: 0;
      left: 0;
      transform: none;
    }
    
    .c-pan-zoom__preview {
      display: none;
    }
    
    .c-pan-zoom__content {
      bottom: 90px;
      
      //  =info
      //  The above line had to be added since we had issues with bottom part
      //  of pan zoom content area being cut off on iOS/Android devices.
      //  This part is maybe due to some other element with the similar height
      //  but after a few days of troubleshooting and testing I gave up. 
      //  Blame me. :( Emanuel
    }
  }
}