/* =Components: Fields
===================================================== */

.c-field {

  position: relative;

  width: 100%;
  margin-bottom: $unit-m;

  text-align: left;

  &:last-child {

    margin-bottom: 0;

  }

  @include rtl {

    direction: rtl;

    text-align: right;

  }

}

.c-field__label {

  display: block;
  margin-bottom: $unit-s;

  font-size: .875rem;
  line-height: 1.4285714286;
  font-family: $font-body;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(medium) {

    font-size: 1rem;
    line-height: 1.5;

  }

}

.c-field__description {

  margin-top: $unit-s;
  margin-bottom: 0;

  font-size: .75rem;
  line-height: 1.4166666667;
  color: $color-text-weak;

}

.c-field__details {

  display: block;
  margin-top: $unit-s;

  font-family: $font-body;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  .o-icon-angle-down {

    transition: all .1s ease-in;

  }

  &[open] .o-icon-angle-down {

    transform: rotate(180deg);

  }

  summary {

    display: block;
    cursor: pointer;

    &:focus {

      color: $color-text-strong;
      outline: none;

    }

    &::-webkit-details-marker {

      display: none;

    }

  }

  p {

    margin-top: $unit-s;

  }

  summary,
  p {

    font-size: .75rem;
    line-height: 1.4166666667;
    color: $color-text-weak;

  }

}

.c-field__group {}

.c-field__count {

  position: absolute;
  top: 0;
  right: 0;

  margin: 0;

  font-size: .75rem;
  line-height: 1.5rem;
  color: $color-text-weak;
  text-align: center;
  border-radius: $border-radius-small;
  transition: all .15s ease-in;

  @include rtl {

    right: auto;
    left: 0;

  }

  &:before {

    display: inline-block;
    width: .375rem;
    height: .375rem;
    margin-right: .1875rem;

    background-color: $color-grey;
    border-radius: .25rem;
    vertical-align: middle;

    transform: scale(0);
    transition: all .15s ease-in;

    content: "";

    @include rtl {

      margin-right: auto;
      margin-left: .1875rem;

    }

  }

}

.c-field__count--limit {

  color: $color-text-strong;

  &:before {

    transform: scale(1);
    background-color: $color-warning;

  }

}

/*
 * Modifiers
 */

.c-field--label-hide {

  .c-field__label {

    @include hide-visually();

  }

  .c-field__count {

    position: absolute;
    top: auto;
    right: .5rem;
    bottom: 2.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 50%;
    text-align: center;
    background: $color-link-tint;

    &:before {

      display: none;

    }

  }

  .c-field__count.c-field__count--limit {

    background-color: $color-warning;

  }

}

.c-field--action {

  .c-field__group {

    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .c-button {

    @include breakpoint(to-medium) {

      width: 100%;

    }

  }

}

/*
 * Layout
 */

.l-form {

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

}

.l-form__item {}

.l-form__item--m {

  @include breakpoint(small) {

    width: calc(50% - 1rem);

  }

}

.l-form__item--xs {

  @include breakpoint(medium) {

    width: calc(25% - 1rem);

  }

}

.l-form__item--wider {
  width: 40em;
}

.l-form__item--wide {
  width: 60em;
}

/*
 * Form states
 */

.c-fields.is-disabled {

  opacity: .5;
  pointer-events: none;

}