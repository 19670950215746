/* =Utilities: Other
===================================================== */

/*
 * Hide and show
 *
 * Different classes for hiding elements.
 */

.u-hide {

  display: none;

}

.u-hide-visually,
.u-screen-reader-text {

  @include hide-visually();

}

/*
 * Hide element on mobile, but show it on
 * tablet and up.
 */

.u-screen-reader-text-only-mobile {

  @include hide-visually();

  @include breakpoint(small) {

    @include hide-visually(unhide);

  }

}

/*
 * Show element on mobile and tablet, but  hide
 * on desktop screens.
 */

.u-screen-reader-text-only-desktop {

  @include hide-visually(unhide);

  @include breakpoint(large) {

    @include hide-visually();

  }

}

.u-screen-reader-text-only-desktop {

  @include hide-visually(unhide);

  @include breakpoint(large) {

    @include hide-visually();

  }

}

/*
 * Rich text
 *
 * Adding basic styles to rich text content
 */

.u-rich-text {

  @include overflow-wrap;

  ol,
  ul {

    padding-left: $unit-l;

    @include rtl {

      padding-left: 0;
      padding-right: $unit-l;

      text-align: right;
      direction: rtl;

    }

  }

  ul {

    list-style-type: disc;

  }

  a:not(.c-button) {

    text-decoration: underline;
    text-decoration-color: $color-link;
    text-decoration-thickness: .05em;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;
      text-decoration-color: $color-accent-green;
      text-decoration-thickness: .1em;
      background-color: transparentize($color-accent-green, .8);
      box-shadow: 0 0 0 .25rem transparentize($color-accent-green, .8);
      border-radius: $border-radius-small;
      outline: none;

    }

  }

}

/*
 * Indent
 *
 * Indenting content when needed. Useful for pages with lots of content
 * like Terms & Conditions.
 */

.u-indent-1 {

  margin-left: 1.5rem;

}

.u-indent-2 {

  margin-left: 3rem;

}

/*
 * Text direction
 *
 * Override text direction for certain elements
 */

.u-force-rtl {

  // direction: ltr!important;
  // text-align: left!important;

}

/*
 * Confetti
 *
 * Positions the confetty (react component) div
 * in the centre of the parent. E.g. used on the
 * donate points to charity in the end of poll screen.
 */

.u-confetti {

  position: absolute;
  top: 50%;
  left: 50%;

}

/*
 * Media landscape
 *
 * Media item that can be used within
 * the question content or any other
 * element.
 */

.u-media-landscape {

  padding-bottom: 60%;
  margin-bottom: $unit-default;

  img {

    width: 100%;
    display: block;
    margin-bottom: -60%;

    border-radius: $border-radius-small;

  }

}

/*
 * Google Recaptcha: Hiding the badge
 */

.grecaptcha-badge {

  visibility: hidden;

}