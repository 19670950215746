/* =Components: Poll intro
===================================================== */

.c-poll-intro {

  position: relative;

  margin-top: 0 - $container-unit-small;
  // margin-bottom: $container-unit-small;
  padding-top: $container-unit-small;
  padding-bottom: $container-unit-small;

  &:before {

    position: absolute;
    z-index: -1;
    top: 0;
    left: -150%;
    right: -150%;
    bottom: 0;

    width: 400%;
    border-bottom: .0625rem solid transparent;

    background-color: #fff;

    transition: all .1s ease-in;

    content: "";

  }

  @include breakpoint(medium) {

    margin-top: 0 - $container-unit-medium;
    padding-top: $container-unit-medium;
    padding-bottom: $container-unit-medium;

  }

  @include breakpoint(large) {

    margin-top: 0 - $container-unit-large;
    padding-top: $container-unit-large;
    padding-bottom: $container-unit-large;

  }

}

.c-poll-intro__header {

  display: flex;
  align-items: center;

}

.c-poll-intro__content {}

.c-poll-intro__toggle {

  padding: .25rem .75rem;

  font-size: .875rem;
  line-height: 1.4285714286;

  background-color: $color-button-alternate;
  color: $color-text-strong-invert;
  border-radius: 1rem;

  .o-icon {

    position: relative;
    top: 0;
    height: 1.25rem;

    vertical-align: top;
    font-size: 1rem;

  }

  @include states {

    background-color: $color-button-alternate-hover;
    color: $color-text-strong-invert;

  }

  @include breakpoint(medium) {

    padding-left: 1.25rem;
    padding-right: 1.25rem;

    font-size: 1rem;
    line-height: 1.5;

    border-radius: 1.5rem;

    .o-icon {

      top: .125rem;

      font-size: 1.25rem;

    }

  }

}

.c-poll-intro__title {

  margin-top: 0;
  margin-bottom: 0;

  font-size: 1.25rem;
  font-weight: $semibold;

  @include breakpoint(medium) {

    font-size: 1.5rem;

  }

  @include breakpoint(large) {

    font-size: 1.75rem;

  }

}


.c-poll-intro__message {

  margin-top: $unit-l;

  text-align: center;

  p {

    font-size: .875rem;
    line-height: 1.5;

  }

  @include breakpoint(small) {

    font-size: 1rem;

  }

}

.c-poll-intro__actions {



  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  // align-items: center;

  .c-button {

    min-width: 10rem;
    margin: $unit-xs;

  }

}

.c-poll-intro__subtitle {

  margin-bottom: $unit-m;

  font-size: 1.25rem;

}

.c-poll-intro__toggle {

  margin-left: auto;

  &.is-active {

    color: $color-link;
    background-color: #fff;
    box-shadow: $box-shadow-small;

    .o-icon-angle-down {

      transform: rotate(180deg);

    }

  }

}

/*
 * Switchable
 */

.c-poll-intro.is-opened {

  margin-bottom: $container-unit-small;

  &:before {

    border-color: $color-grey-x-light;
    // background-color: $color-brand-primary-dark;

    // background-color: $color-grey-x-light;
    // background-color: $color-brand-primary-mid-dark;

  }

  @include breakpoint(medium) {

    margin-bottom: $container-unit-medium;

  }

  @include breakpoint(large) {

    margin-bottom: $container-unit-large;

  }

  .c-poll-intro__content {

    display: block;

    margin-top: $container-unit-small;
    // padding-top: $container-unit-small;

    @include breakpoint(medium) {

      margin-top: $container-unit-medium;
      // padding-top: $container-unit-medium;

    }

    @include breakpoint(large) {

      margin-top: $container-unit-large;
      // padding-top: $container-unit-large;

    }

    // border-top: .0625rem solid $color-grey-light;

  }

}

.c-poll-intro.is-closed {

  .c-poll-intro__content {

    display: none;

  }

}

/*
 * Modifiers
 */

.c-poll-intro--explainer.is-opened {

  .c-poll-intro__header {

    display: none;

  }

  .c-poll-intro__content {

    padding-top: 0;
    margin-top: 0;
    border-top: none;

  }

  &:before {

    // background-color: $color-brand-accent-1;

  }

}