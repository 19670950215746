/* Z-index */
$z-index-skip-links:              800;
$z-index-header:                  500;
$z-index-language:                600;
$z-index-navigation:              400;
$z-index-main:                    300;
$z-index-carousel:                300;
$z-index-modal:                   600;
$z-index-question:                610;
$z-index-points:                  620;
$z-index-cookie-notice:           800;
$z-index-cookie-panel:            800;
$z-index-timeline:                400;

/* Units */
$unit-xs:                    .25rem;
$unit-s:                     .5rem;
$unit-m:                     1rem;
$unit-l:                     1.5rem;
$unit-xl:                    2rem;
$unit-h:                     3rem;
$unit-default:               $unit-m;

/* Container padding */
$container-unit-small:          1rem;
$container-unit-medium:         1.5rem;
$container-unit-large:          2rem;

/* Container padding proportions */
$container-proportion:          1;
$container-proportion-large:    2;

/* Container max width */
$container-max-width:           60em; // 960 * 1.25 = 1200 on desktop

/* Border radius */
$border-radius-small:           .25rem;
$border-radius-medium:          .5rem;
$border-radius-large:           .75rem;

/* Box shadow */
$box-shadow-small:              0 .0625rem .1875rem 0 rgba($color-primary-dark, .1);
$box-shadow-medium:             0 .125rem .375rem 0 rgba($color-primary-dark, .1);
$box-shadow-large:              0 .5rem 2rem 0 rgba($color-primary-dark, .1);
$box-shadow-extra-large:        0 1rem 3rem 0 rgba($color-primary-dark, .1);

/* Transition */
$transition-default:            all .1s cubic-bezier(0.2, 0.6, 0.3, 1);
$transition-timing-cubic:       cubic-bezier(0.2, 0.6, 0.3, 1);

/* Media queries */

/* Width */
$extra-small-value:             24em; // 384px
$small-value:                   36em; // 576px
$medium-value:                  48em; // 768px
$large-value:                   62em; // 992px
$extra-large-value:             80em; // 1280px

/* Height */
$extra-small-height-value:      28em; // 448px
$small-height-value:            32em; // 512px
$medium-height-value:           40em; // 560px
$large-height-value:            48em; // 768px
$extra-large-height-value:      64em; // 1024px

/* Interface sizes */
$header-nav-height: 3.5rem;

/* Question sizes */
$question-width-extra-narrow:  32rem;
$question-width-narrow:        40rem;
$question-width-default:       50rem;
$question-width-wide:          60rem;

// Patterns
$background-pattern-annotate: url("data:image/svg+xml,%3Csvg width='56' height='42' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23070047' fill='none' fill-rule='evenodd' opacity='.075' stroke-linecap='round'%3E%3Cpath d='M4 10l10-8 10 8M4 24l10-8 10 8M4 38l10-8 10 8M52 16l-10 8-10-8M52 30l-10 8-10-8M52 2l-10 8-10-8'/%3E%3C/g%3E%3C/svg%3E%0A");