/* =Tables
===================================================== */

table {

  width: 100%;
  margin-bottom: $unit-m;

  text-align: left;

  border-spacing: 0;
  border-collapse: collapse;
  
  @include rtl {
    text-align: right;
    direction: rtl;
  }

}

caption {

  margin-bottom: $unit-xs;

}

th,
td {

  padding: .25rem .5rem;
  border-top: .0625rem solid $color-border-normal;

  font-size: 1rem;
  line-height: 1.5;

}