.c-skip-links {

  position: absolute;
  z-index: $z-index-skip-links;
  top: $unit-s;
  left: $unit-s;

}

.c-skip-links__link {

  @include hide-visually();

  display: block;
  padding: $unit-s $unit-m;

  font-size: ms(0);
  font-weight: $regular;
  color: $color-text-weak;

  background-color: #fff;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-medium;

  &:focus {

    @include hide-visually("unhide");

  }

  strong {

    color: $color-text-strong;

  }

}