/* =Components: Marketing Hero
===================================================== */

.c-m-hero {

  text-align: center;

}

.c-m-hero__title {

  margin-top: 0;
  margin-bottom: $unit-l;

  font-size: ms(2);
  font-weight: $semibold;
  text-align: center;
  letter-spacing: -.025em;

  @include breakpoint(small) {

    font-size: ms(3);

  }

  @include breakpoint(medium) {

    font-size: ms(4);

  }

  @include breakpoint(large) {

    font-size: ms(5);

  }

  .c-m-block--dark & {

    color: $color-text-strong-invert;

  }

}

.c-m-hero__summary {

  margin-bottom: $unit-l;

  .c-m-block--dark & {

    color: $color-text-weak-invert;

  }

  &:last-child {

    margin-bottom: 0;

  }

  p {

    margin-top: $unit-s;
    margin-bottom: 0;

    font-family: $font-body;
    font-size: 1rem;
    line-height: 1.5;
    color: inherit;

    &:first-child {

      margin-top: 0;

    }

    @include breakpoint(small) {

      font-size: 1.125rem;

    }

    @include breakpoint(medium) {

      font-size: 1.25rem;

    }

    @include breakpoint(large) {

      font-size: 1.3125rem;

    }

  }

}

.c-m-hero__action {

  margin-top: $unit-l;
  margin-bottom: $unit-xl;

}

.c-m-hero__illustration {

  margin-top: $unit-l;
  margin-bottom: $unit-xl;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img {

    display: block;
    max-width: 100%;
    max-width: 80vw;
    width: 44rem;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: $unit-l;
    margin-bottom: $unit-l;

  }

  svg {

    height: 160px;

  }

  * {

    transform-origin: center;
    transform-box: fill-box;

  }

  @include breakpoint(extra-small) {

    svg {

      height: 200px;

    }

  }

  @include breakpoint(small) {

    svg {

      height: 260px;

    }

  }

  @include breakpoint(extra-large) {

    margin-left: -5rem;
    margin-right: -5rem;

  }

}

@keyframes hero-illustration-popup {

  0% {

    opacity: 0;
    transform: scale(.2);

  }

  90% {

    transform: scale(1.1);

  }

  100% {

    opacity: 1;
    transform: scale(1);

  }

}

@keyframes hero-illustration-rating-slide {

  0%, 100% {

    transform: scale(.8)  translateX(-72px);

  }

  50% {

    transform: scale(1)  translateX(0);

  }

}

@keyframes hero-illustration-flow {

  0%, 100% {

    transform: translateY(0);

  }

  50% {

    transform: translateY(3px);

  }

}

#bubble {

  animation:
    hero-illustration-popup .5s ease-in-out,
    hero-illustration-flow 1.5s ease-in infinite;
  animation-fill-mode: backwards;

}

// #rating-emoji {

//   animation:
//     hero-illustration-rating-slide 5s infinite ease-in-out;

// }

// #emoji-eye,
// #emoji-eye1,
// #emoji-mouth, {

//   animation:
//     hero-illustration-popup 2s infinite ease-in-out;

// }

#illustration-people-1 #bubble {

  animation-delay: .25s;

}

#illustration-people-2 #bubble {

  animation-delay: .5s;

}

#illustration-people-3 #bubble {

  animation-delay: .75s;

}

#illustration-people-4 #bubble {

  animation-delay: 1s;

}

#illustration-people-5 #bubble {

  animation-delay: 1.25s;

}

.c-m-hero__illustration-3,
.c-m-hero__illustration-5 {

  display: none;

}

.c-m-hero__illustration-1,
.c-m-hero__illustration-2,
.c-m-hero__illustration-4 {

  display: block;

}

@include breakpoint(small) {

  .c-m-hero__illustration-3 {

    display: block;

  }

}

@include breakpoint(medium) {

  .c-m-hero__illustration-4,
  .c-m-hero__illustration-5 {

    display: block;

  }

}