/* =Components: Points
===================================================== */

/*
 * Points
 *
 * Points interaction on questions
 */

.c-points {


}

/*
 * Points label
 *
 * - Label visible
 * - Label changed
 * - Label hidden
 */

.c-points-label {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 0 $unit-m;

  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  font-family: $font-headline;
  white-space: nowrap;

  border-radius: 1rem;
  background-color: $color-accent-yellow;

  animation: points-label 4s ease forwards;

}

.c-points-label__earned {

  opacity: 0;

  animation: points-value-fade 2s ease forwards;

}

.c-points-label__total {

  margin-top: -1.5rem;
  opacity: 0;

  animation: points-value-fade 2s ease forwards 2s;

}

@keyframes points-label {

  0% {

    opacity: 0;
    margin-top: 1rem;

  }

  10% {

    opacity: 1;
    margin-top: 0;

  }

  90% {

    opacity: 1;
    transform: translate(-50%, -50%) scale(1);

  }

  95% {

    transform: translate(-50%, -50%) scale(1.1);

  }

  100% {

    opacity: 0;
    transform: translate(-50%, -50%) scale(1);

  }

}

@keyframes points-value-fade {

  0%, 100% {

    opacity: 0;

  }

  10%, 90% {

    opacity: 1;

  }

}

// 1: Hidden
// 2: Fades in
// 3: Shows points earned
// 4: Swaps out points earned with total points
// 5: Scales and dissapears

/*
 * Bounce
 *
 * Coin bouncing on the screen
 */

$points-icon-size: 5rem;

.c-points-bounce {

  position: absolute;
  z-index: $z-index-points;
  top: 50%;
  left: 50%;
  margin-left: 0 - ($points-icon-size * 0.5);
  margin-top: 0 - ($points-icon-size * 0.5);

  width: $points-icon-size;
  height: $points-icon-size;

  transition: all .1s $transition-timing-cubic;

  svg {

    width: $points-icon-size;
    height: $points-icon-size;
  }

  * {

    transform-origin: center;
    transform-box: fill-box;

  }

  #lines path {

    animation: points-bounce-lines 1s $transition-timing-cubic forwards;
    // stroke-width: 2;

  }

  #coin {

    animation: points-bounce-coin 1s $transition-timing-cubic forwards;

  }

}

.c-points-bounce--from-bottom {

  animation: points-bounce-from-bottom .2s $transition-timing-cubic forwards;

}


@keyframes points-bounce-coin {

  0% {

    opacity: 0;
    transform: scale(.5);

  }

  10% {

    opacity: 1;

  }

  30% {

    transform: scale(1.3);

  }

  60% {

    opacity: 1;
    transform: scale(1.3);

  }

  100% {

    opacity: 0;
    transform: scale(.7);

  }

}

@keyframes points-bounce-lines {

  0% {

    stroke-dasharray: 0, 24;
    opacity: 0;

  }

  10% {

    opacity: 1;

  }

  30% {

    stroke-dasharray: 24, 24;

  }

  60% {


    stroke-dasharray: 24, 24;
    stroke-dashoffset: 0;
    opacity: 1;

  }

  100% {

    opacity: 0;
    stroke-dasharray: 24, 24;
    stroke-dashoffset: -23;

  }

}

@keyframes points-bounce-from-bottom {

  0% {

    transform: translateY(0);

  }

  100% {

    transform: translateY(-6rem);

  }

}

// 1: Coin shows up, lines hidden
// 2: Coins scales up, lines start to fill from circle side
// 3: Coins maximum size, lines filled all the end
// 4: Coins start to scale down, lines unfill towards outer rim
// 5: Coin hidden, lines hidden