/* =Components: Preview
===================================================== */

/*
 * Embed
 *
 * If `?embed=true` is added at the end of the link that is
 * included in the <iframe>, the navigation elements will be
 * hidden from the user. This is useful for embedding a poll
 * or a specific only page on the parent website of the project.
 */

.l-app--embed {

  // Hide header + navigation
  .l-section-header,
  .l-section-nav {

    display: none;

  }

  // Removing space for sticky header
  .c-section-nav.is-sticky + * {

    padding-top: 0;

  }

  // Removing language
  .language_preferences {

    display: none;

  }

}

/*
 * Embed auto height
 *
 * If `?autoheight=true` is added at the end of the link that is
 * included in the <iframe>, the styling of the modal will change
 * considerably to enable auto resize of the iframe's height.
 *
 * Summary:
 * - Fixed/apsolute positioning is turned into static/relative
 * - Widths and paddings of the modal is adjusted
 * - Any scrolling in the modal/quesitons is removed
 * - While modal is opened, other content blocks are hidden
 */

.l-app--embed-auto {

  /*
   * Collapsing modals from apsolute or fixed to
   * fully scrollable elements in the body document.
   */

  .is-modal-opened & {

    /*
     * Adding background for the whole page, emulating a modal
     */

    background: rgba($color-primary-light, .9);

    /*
     * Removing scrolls and unsetting positioning of the modal container
     */

    .c-modal-poll-container {

      position: relative;
      overflow: initial;
      padding: 0;

      background: transparent;

    }

    /*
     * Adjusting modal for mobile only
     */

    @include breakpoint(to-medium) {

      .c-modal-poll {

        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;

        width: 100%;

      }

      /*
       * Add min height to the question to insure less
       * of a layout change on mobile.
       */

      .c-question {

        min-height: 20rem;

      }

    }

    /*
     * Hiding all other elements while modal is opened
     */

    .c-section-header,
    .c-section-nav,
    .c-section-footer {

      display: none;

    }

    .c-section-main {

      .l-container > *:not(.c-modal-poll-container) {

        display: none;

      }

    }

  }

  /*
   * Hide polls grid when explainer is visible
   */

  &.is-explainer-opened {

    .l-grid-polls {

      display: none;

    }
  }

}

/*
 * Preview
 *
 * When the app is in the preview mode, we might have
 * to override certain CSS rules to make it look more
 * realistic or prepare it for PDF export.
 */

.l-app--preview {



}

/*
 * PDF
 *
 * When the app is embedded for PDF export, we make
 * adjustements to the layout, hiding scrollbars, forcing
 * background color/image printing (-webkit only).
 */

.l-app--pdf {

  /*
   * Force printing of backgrounds
   *
   * The -webkit-print-color-adjust property is a non-standard CSS extension
   * that can be used to force printing of background colors and images
   * in browsers based on the WebKit engine.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-print-color-adjust
   */

  * {

    -webkit-print-color-adjust: exact;

  }

  /*
   * Disable interaction
   *
   * Disable all user interaction on the iframe.
   * User can't close the modals, click the questions or answer polls.
   */

  * {

    pointer-events: none!important;

  }

  /*
   * Hide scrollbars
   *
   * Removing scrollbars in 2 cases in `components.question.scss`
   * 1) Ultra small devices (320px x 480px) where the whole `.c-question` is scrollable.
   * 2) Scrollable `.c-question__content` in all questions (to hide the scrollbar)
   */

  .c-question__content {

    overflow: hidden;

  }

  @include breakpoint(to-medium-height) {

    .c-question {

      overflow-y: hidden;

    }

  }

  /*
   * Hide preview notice
   */

  .c-preview-notice {

    display: none;

  }

}