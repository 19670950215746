/* =Components: Marketing footer navigation
===================================================== */

.c-m-nav-footer {

  @include rtl {

    direction: rtl;

  }

}

.c-m-nav-footer__items {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;

}

.c-m-nav-footer__item {

  margin-top: 0;
  margin-right: $unit-m;
  display: flex;

  font-size: .875rem;
  line-height: 1.428571428571429;
  font-family: $font-headline;

  &:last-child {

    margin-right: 0;

  }

  @include rtl {

    margin-right: 0;
    margin-left: $unit-m;

    &:last-child {

      margin-left: 0;

    }

  }

}

.c-m-nav-footer__link {

  display: block;
  padding: .25rem 0;

  font-family: $font-headline;
  color: $color-text;

  @include states {

    color: $color-text-strong;

  }

}