/* =Components: Style guide
===================================================== */

/*
 * View style guide
 */

.c-sg-toolbar {

  display: flex;
  justify-content: center;

  padding: $unit-xl 0;

  @include breakpoint(large) {

    position: fixed;
    bottom: $unit-xl;
    right: $unit-xl;
    z-index: $z-index-modal;

    padding: 0;

    @include rtl {

      direction: rtl;

      left: $unit-xl;
      right: auto;

    }

    .l-style-guide & {

      bottom: auto;
      top: $unit-m;

    }

  }

}

.c-sg-toolbar__item {

  display: block;
  padding: 0;
  // padding: $unit-l $unit-m;
  margin: .25rem;

  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: $font-headline;
  font-size: .875rem;
  line-height: 1.5;
  color: $color-text-weak;
  text-align: center;
  // background-color: #fff;
  background-color: $color-primary-light;
  border-radius: 50%;

  .o-icon {

    font-size: 1.5rem;

  }

  .o-label {

    @include hide-visually();

   }

  // opacity: .5;

  @include breakpoint(medium) {

    // padding: .25rem .5rem;

    // border: .125rem solid $color-link;
    // background-color: transparent;

    // border-radius: .25rem;

    // @include states {

    //   border: .125rem solid $color-link;

    // }

  }

  &.is-active {

    color: $color-text-strong-invert;
    background-color: $color-link;

  }

}

/*
 * Style guide layout template
 */

.l-style-guide {

  min-height: 100vh;

  @include breakpoint(large) {

    display: grid;
    height: 100%;
    grid-template-columns: 20rem 1fr;

  }

}

.c-sg-nav {

  padding: $unit-l 0;

  background-color: $color-primary-light;

}

.c-sg-nav__logo {

  padding: 0 $unit-l;
  margin-bottom: $unit-s;

  svg {

    max-width: 10rem;
    height: auto;

  }

}

.c-sg-nav__items {

  list-style: none;
  margin: 0;
  padding: 0;


}

.c-sg-nav__submenu {

  list-style: none;
  margin: 0;
  padding: 0;

  // padding-top: $unit-xs;
  // padding-bottom: $unit-xs;

}

.c-sg-nav__item {

  margin-top: 0;

  font-family: $font-body;
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: $medium;

  &.is-active > .c-sg-nav__link {

    background-color: #fff;

  }

  .c-sg-nav__submenu & {

    font-size: 1rem;
    line-height: 1.5;

    &.is-active > .c-sg-nav__link {

      color: $color-text-strong;
      // background-color: transparent;
      background-color: $color-accent-green;

    }

  }

  &.is-disabled {

    opacity: .3;
    pointer-events: none;

  }

}

.c-sg-nav__link {

  display: block;
  padding: .875rem $unit-l;

  color: $color-text;

  @include states {

    color: $color-text-strong;
    background-color: #fff;

  }

  .c-sg-nav__submenu & {

    padding: $unit-s $unit-l;

    color: $color-text-weak;

    @include states {

      color: $color-text-strong;
      background-color: transparent;

    }

  }

}

.c-sg-main {

  @include responsive-spacing(padding);

}

.c-sg-section {

  border-top: .0625rem solid $color-grey-x-light;

}

.c-sg-title {

  margin-bottom: $unit-xs;

  font-size: 1.25rem;
  line-height: 1.5;

  @include breakpoint(medium) {

    font-size: 1.5rem;

  }

}

.c-sg-summary {

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  text-align: left;

}

.c-sg-grid-colors {

  display: grid;
  grid-gap: $unit-xl;

  @include breakpoint(extra-small) {

    grid-template-columns: 1fr 1fr;

  }

  @include breakpoint(small) {

    grid-template-columns: 1fr 1fr 1fr;

  }

}

.c-sg-color-token {

  padding: $unit-m;

  // box-shadow: $box-shadow-large;
  box-shadow: inset 0 0 0 .0625rem $color-input-regular;
  border-radius: $border-radius-medium;
  text-align: left;

}

.c-sg-color-token__display {

  position: relative;
  height: 6rem;

  margin-top: 0 - $unit-m;
  margin-left: 0 - $unit-m;
  margin-right: 0 - $unit-m;
  margin-bottom: $unit-m;

  border-top-left-radius: $border-radius-medium;
  border-top-right-radius: $border-radius-medium;

  &:before {

    content: "";

    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-top-left-radius: $border-radius-medium;
    border-top-right-radius: $border-radius-medium;

    background-image: linear-gradient(45deg, rgba(0,0,0,.1) 25%, transparent 25%), linear-gradient(-45deg, rgba(0,0,0,.1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0,0,0,.1) 75%), linear-gradient(-45deg, transparent 75%, rgba(0,0,0,.1) 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

  }

}

.c-sg-color-token__name {

  margin-bottom: $unit-s;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  font-weight: $semibold;

}

.c-sg-color-token__value,
.c-sg-color-token__variable {

  margin-bottom: 0;

  font-size: .875rem;
  line-height: 1.5;
  font-family: $font-body;
  font-family: $font-stack-monaco;

}

.c-sg-tag {

  display: inline-block;
  padding: .125rem .5rem;

  font-size: .625rem;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: $semibold;
  color: $color-text;
  letter-spacing: .025em;

  color: $color-text-weak;
  background-color: #eee;
  border-radius: $border-radius-medium;

}

.c-sg-tag--success {

  background-color: $color-accent-green;

}

.c-sg-tag--warning {

  background-color: $color-accent-yellow;

}

.c-sg-links {

  list-style: none;
  margin: 0;
  padding: 0;

  li {

    font-family: $font-body;

  }

  a {

    display: block;
    padding: $unit-xs 0;

  }

}

.c-sg-component {

  margin-top: $unit-m;

}

.c-sg-component__title {

  margin-top: $unit-xl;
  margin-bottom: $unit-m;

  font-size: .875rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: $color-text-weak;
  letter-spacing: .025em;

}

/*
 * V2
 *
 * Updates
 */

.c-sg-main__title {

  margin-top: 0;
  margin-bottom: $unit-l;

  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
  color: $color-text-weak;
  letter-spacing: .025em;

}

.c-sg-section {

  padding: $unit-l 0;
  // @include responsive-spacing(padding-top);
  // @include responsive-spacing(padding-bottom);

}

.c-sg-section__title {

  margin-bottom: $unit-xs;

  font-size: 1.375rem;
  font-weight: $bold;
  font-family: $font-body;

}

.c-sg-section__summary {

  max-width: 80ch;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  color: $color-text-weak;

}

.c-sg-section__content {

  // max-width: $container-max-width + 10em;
  max-width: $container-max-width;
  margin: $unit-xl 0;
  // padding: $unit-xl;
  // box-shadow: $box-shadow-large;
  // box-shadow: $box-shadow-large;
  // border: .125rem solid $color-accent-green;

  border-radius: $border-radius-medium;

}

.c-sg-section__demo {

  position: relative;
  min-height: 16rem;

  border-radius: $border-radius-small;
  // box-shadow: $box-shadow-large;

  background-image: linear-gradient(45deg, rgba(0,0,0,.05) 25%, transparent 25%), linear-gradient(-45deg, rgba(0,0,0,.05) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0,0,0,.05) 75%), linear-gradient(-45deg, transparent 75%, rgba(0,0,0,.05) 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  box-shadow: inset 0 0 0 .0625rem $color-primary-light;

  .c-cookie-notice,
  .c-preview-notice {

    position: absolute!important;

  }

}

.c-sg-section__demo--space {

  padding: $unit-xl;
  overflow: hidden;

}

.c-sg-section__demo--modal {

  padding: $unit-xl;
  min-height: 70rem;
  overflow: hidden;

  background-image: linear-gradient(45deg, rgba(0,0,0,.25) 25%, transparent 25%), linear-gradient(-45deg, rgba(0,0,0,.25) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0,0,0,.25) 75%), linear-gradient(-45deg, transparent 75%, rgba(0,0,0,.25) 75%);

  // Force modal  visibility
  .c-modal-poll-container {

    position: absolute!important;
    display: flex!important;

  }





}

.c-sg-icons {

  list-style: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem,1fr));

}

.c-sg-icons__item {

  display: flex;
  align-items: center;

  font-size: .875rem;
  color: $color-text-weak;
  font-family: $font-stack-monaco;

}

.c-sg-icons__display {

  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  margin-right: .5rem;

  font-size: 2rem;
  color: $color-link;

}

/*
 * Tokens: Spacing
 */

.c-sg-spacing {}

.c-sg-spacing__unit {

  margin-top: $unit-xl;

}

.c-sg-spacing__display {

  display: block;
  background: $color-accent-green;

  .c-sg-spacing__unit--xs & { width: $unit-xs; height: $unit-xs; }
  .c-sg-spacing__unit--s & { width: $unit-s; height: $unit-s; }
  .c-sg-spacing__unit--m & { width: $unit-m; height: $unit-m; }
  .c-sg-spacing__unit--l & { width: $unit-l; height: $unit-l; }
  .c-sg-spacing__unit--xl & { width: $unit-xl; height: $unit-xl; }
  .c-sg-spacing__unit--h & { width: $unit-h; height: $unit-h; }
  .c-sg-spacing__unit--default & { width: $unit-default; height: $unit-default; }

}

.c-sg-spacing__variable,
.c-sg-spacing__value {

  display: block;
  margin-top: $unit-s;

  font-size: .875rem;
  line-height: 1.5;
  color: $color-text-strong;
  font-family: $font-body;
  font-family: $font-stack-monaco;

}

.c-sg-spacing__value {

  margin-top: $unit-xs;
  color: $color-text-weak;

}

/*
 * Tokens: Shadows
 */

.c-sg-shadows__shadow {

  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: $unit-xl;

  padding-left: $unit-h + $unit-m;

  &:before {

    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    display: block;
    width: $unit-h;
    height: $unit-h;

    content: "";

  }

  &.c-sg-shadows__shadow--s:before { box-shadow: $box-shadow-small; }
  &.c-sg-shadows__shadow--m:before { box-shadow: $box-shadow-medium; }
  &.c-sg-shadows__shadow--l:before { box-shadow: $box-shadow-large; }
  &.c-sg-shadows__shadow--xl:before { box-shadow: $box-shadow-extra-large; }

}

.c-sg-shadows__variable,
.c-sg-shadows__value {

  display: block;
  margin-top: $unit-s;

  font-size: .875rem;
  line-height: 1.5;
  color: $color-text-strong;
  font-family: $font-body;
  font-family: $font-stack-monaco;

}

.c-sg-shadows__value {

  margin-top: $unit-xs;
  color: $color-text-weak;

}

/*
 * Tokens: Corners
 */

.c-sg-corners__corner {

  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: $unit-xl;

  padding-left: $unit-h + $unit-m;

  &:before {

    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    display: block;
    width: $unit-h;
    height: $unit-h;

    background-color: $color-accent-green;

    content: "";

  }

  &.c-sg-corners__corner--s:before { border-radius: $border-radius-small; }
  &.c-sg-corners__corner--m:before { border-radius: $border-radius-medium; }
  &.c-sg-corners__corner--l:before { border-radius: $border-radius-large; }

}

.c-sg-corners__variable,
.c-sg-corners__value {

  display: block;
  margin-top: $unit-s;

  font-size: .875rem;
  line-height: 1.5;
  color: $color-text-strong;
  font-family: $font-body;
  font-family: $font-stack-monaco;

}

.c-sg-corners__value {

  margin-top: $unit-xs;
  color: $color-text-weak;

}

/*
 * Tokens: Typeface
 */

.c-sg-typefaces {}

.c-sg-typefaces__typeface {

  margin-top: $unit-l;
  padding: $unit-xl;
  border: .0625rem solid $color-border-weak;

  border-radius: $border-radius-small;

}

.c-sg-typefaces__name {

  display: block;
  margin-top: 0;
  margin-bottom: $unit-s;

  font-size: 1rem;
  line-height: 1.5;
  // text-transform: uppercase;
  color: $color-text-weak;
  // letter-spacing: .025em;

}

.c-sg-typefaces__sample {

  margin: $unit-m 0;

  .c-sg-typefaces__typeface--headline & {

    font-family: $font-headline;
    font-weight: $semibold;
    line-height: 1.3;

  }

  .c-sg-typefaces__typeface--body & {

    font-family: $font-body;
    font-weight: normal;
    line-height: 1.3;

  }

}

.c-sg-typefaces__description {}

.c-sg-typefaces__variable {

  display: block;
  margin-top: $unit-s;
  margin-bottom: 0;

  font-size: .875rem;
  line-height: 1.5;
  color: $color-text-strong;
  font-family: $font-body;
  font-family: $font-stack-monaco;

}

.c-sg-typefaces__typeface--headline {}

.c-sg-typefaces__typeface--body {}



.c-sg-placeholder {

  padding: $unit-m;

  background-color: $color-primary-light;
  border-radius: $border-radius-small;
  // background-image: linear-gradient(45deg, rgba(0,0,0,.05) 25%, transparent 25%), linear-gradient(-45deg, rgba(0,0,0,.05) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(0,0,0,.05) 75%), linear-gradient(-45deg, transparent 75%, rgba(0,0,0,.05) 75%);
  // background-size: 20px 20px;
  // background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  // box-shadow: inset 0 0 0 .0625rem $color-primary-light;

}