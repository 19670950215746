/* =Components: News
===================================================== */

/*
 * News
 *
 * News listing
 */

.c-news {

  text-align: left;

  @include rtl {

    direction: rtl;
    text-align: right;

  }

}

.c-news__header {

}

.c-news__title {

  margin-top: 0;
  margin-bottom: $unit-m;

  font-size: ms(1);
  line-height: ms(1);

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(small) {

    font-size: ms(2);

  }

  @include breakpoint(medium) {

    font-size: ms(3);

  }

  @include breakpoint(large) {

    font-size: ms(4);

  }

}

.c-news__meta {

  margin-bottom: $unit-s;

  font-size: ms(-1);
  line-height: ms(2);
  text-transform: uppercase;
  font-weight: $semibold;
  color: $color-text-weak;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(small) {

    margin-bottom: $unit-m;

    font-size: ms(0);

  }

}

.c-news__summary {

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  p {

    font-size: ms(0);
    line-height: ms(2);

  }

  @include breakpoint(small) {

    p {

      font-size: ms(1);

    }

  }

  @include breakpoint(medium) {

    p {

      font-size: ms(2);

    }

  }

}

.c-news__content {

  margin-top: $unit-m;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  p,
  ol,
  ul {

    margin-bottom: $unit-m;

  }

  li,
  p {

    font-size: ms(0);
    line-height: ms(2);

  }

  li {

    margin-top: $unit-xs;

  }

  // a {

  //   text-decoration: underline;

  // }

  @include breakpoint(small) {

    li,
    p {

      font-size: ms(0);

    }

  }

  @include breakpoint(medium) {

    li,
    p {

      font-size: ms(1);

    }

  }

}

.c-news__figure {

  margin-top: $unit-m;
  margin-bottom: $unit-m;

  img {

    display: block;
    width: 100%;

    border-radius: $border-radius-small;

  }

  @include breakpoint(medium) {

    margin-top: $unit-l;
    margin-bottom: $unit-l;

  }

  @include breakpoint(large) {

    margin-top: $unit-xl;
    margin-bottom: $unit-xl;

  }

}

.c-news__action {

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: $unit-m;

  .c-social {

    margin-top: $unit-m;

    &:first-child {

      margin-top: 0;

    }

  }

  @include breakpoint(small) {

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: $unit-l;

    .c-social {

      margin-top: 0;
      margin-right: 0;

    }

    .c-button {

      margin-left: 0;

    }

    @include rtl {

      .c-button {

        margin-left: auto;
        margin-right: 0;

      }

    }

  }

  @include breakpoint(medium) {

    margin-top: $unit-xl;

  }

}