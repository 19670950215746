/* =Components: Homepage — Influenced decisions
===================================================== */

/*
 * Influenced decisions
 *
 * List of influenced decisions
 */

.c-influenced {

  position: relative;

  margin-bottom: $container-unit-small * 2;

  @include breakpoint(medium) {

    margin-bottom: $container-unit-medium * 2;

  }

  @include breakpoint(large) {

    margin-bottom: $container-unit-large * 2;

  }

  &:last-child {

    margin-bottom: 0;

  }

}

.c-influenced__heading {

  margin-top: 0;
  margin-bottom: $unit-m;

  font-size: ms(0);
  line-height: ms(1);

  @include breakpoint(small) {

    font-size: ms(1);
    line-height: ms(2);

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-l;

  }

  .c-block--dark & {

    color: $color-text-strong-invert;

  }


}

.c-influenced__carousel {



}

.c-influenced__items {

  overflow: hidden;

}

.c-influenced__item {

  position: relative;

  @include breakpoint(medium) {

    margin-bottom: $unit-xl;

  }

}

.c-influenced__figure {

  display: block;
  margin: 0;
  overflow: hidden;

  border-top-left-radius: $border-radius-small;
  border-top-right-radius: $border-radius-small;

  img {

    display: block;
    width: 100%;
    margin: 0;

  }

  @include breakpoint(medium) {

    border-radius: $border-radius-medium;

  }

}

.c-influenced__group {

  position: relative;

  padding: $unit-m;

  background-color: #fff;
  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;

  @include breakpoint(medium) {

    position: absolute;
    left: ($unit-xl*2);
    right: ($unit-xl*2);
    bottom: $unit-xl;

    margin: 0;

    border-radius: $border-radius-small;

  }

  @include breakpoint(large) {
    
    left: $unit-xl;
    right: $unit-xl;

    // position: absolute;
    // left: $unit-xl;
    // right: $unit-xl;
    // bottom: 0 - $unit-xl;

  }

}

.c-influenced__title {

  margin-top: 0;
  margin-bottom: $unit-s;

  font-size: ms(0);
  line-height: ms(2);

  text-align: left;

  &:last-child {

    margin-bottom: 0;

  }

  @include breakpoint(extra-small) {

    font-size: ms(0);
    line-height: ms(2);

  }

  @include breakpoint(small) {

    font-size: ms(1);
    line-height: ms(2);

  }

}

.c-influenced__summary {

  margin-bottom: 0;

  font-size: ms(-1);
  line-height: ms(2);

  text-align: left;

  @include breakpoint(small) {

    font-size: ms(0);
    line-height: ms(2);

  }

}

/*
 * Modifiers: No figure decision has a different layout
 */

.c-influenced__item--no-figure {

  margin-bottom: 0;

  .c-influenced__group {

    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;

    margin-bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border-bottom-left-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;

  }

  @include breakpoint(small) {

    .c-influenced__group {

      padding: $unit-h;

    }

    .c-influenced__title {

      text-align: center;

    }

    .c-influenced__summary {

      text-align: center;

    }

  }

  @include breakpoint(medium) {

    .c-influenced__group {

      min-height: 24rem;

    }

    .c-influenced__title {

      margin-bottom: $unit-m;

      font-size: ms(2);
      line-height: ms(1);

    }

    .c-influenced__summary {

      font-size: ms(1);
      line-height: ms(2);

    }

  }

}