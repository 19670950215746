/* =Components: FAQ
===================================================== */

/*
 * FAQ
 *
 * FAQ includes switchable title and rich text
 * content with CTA button.
 */

.c-faq {

  border-top: .0625rem solid $color-border-weak;

  margin-left: 0 - $container-unit-small;
  margin-right: 0 - $container-unit-small;
  padding-right: $container-unit-small;
  padding-left: $container-unit-small;

  @include breakpoint(small) {

    margin-left: 0 - $container-unit-medium;
    margin-right: 0 - $container-unit-medium;
    padding-right: $container-unit-medium;
    padding-left: $container-unit-medium;

  }

  @include breakpoint(medium) {

    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

  }

}

.c-faq__header {

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: $unit-m;
  padding-bottom: $unit-m;

  @include breakpoint(medium) {

    padding-top: $unit-l;
    padding-bottom: $unit-l;

  }

}

.c-faq__title {

  font-size: .875rem;
  line-height: 1.4285714286;

  @include rtl {

    direction: rtl;
    text-align: right;

  }


  @include breakpoint(small) {

    font-size: 1rem;
    line-height: 1.5;

  }

  @include breakpoint(medium) {

    font-size: 1.125rem;
    line-height: 1.555555555555556;

  }

}

.c-faq__content {

  margin-top: 0 - $unit-xs;
  padding-bottom: $unit-m;

  text-align: left;

  @include rtl {

    direction: rtl;
    text-align: right;

  }

  p,
  ul,
  ol {

    margin-top: $unit-xs;
    margin-bottom: 0;

  }

  li,
  p {


    font-size: .75rem;
    line-height: 1.5;

    &:first-child {

      margin-top: 0;

    }

  }

  @include breakpoint(medium) {

    margin-top: 0 - $unit-s;
    padding-bottom: $unit-l;

    p,
    ul,
    ol {

      margin-top: $unit-s;

    }

    p,
    li {

      font-size: .875rem;

    }

  }

  @include breakpoint(medium) {

    p,
    ul,
    ol {

      margin-top: $unit-m;

    }

    p,
    li {

      font-size: 1rem;

    }

  }

}

.c-faq__action {

  margin-top: $unit-m;

  @include breakpoint(medium) {

    margin-top: $unit-l;

  }

}

/*
 * Switchable
 *
 * Updates reflecting elements when the FAQ is switchable
 */

.c-faq--switchable {

  .c-faq__header {

    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;

  }

}

.c-faq__toggle {

  position: relative;

  padding-top: $unit-m;
  padding-bottom: $unit-m;
  padding-right: 2.5rem;
  padding-left: 0;
  width: 100%;
  border: none;

  text-align: left;
  background-color: transparent;
  border-radius: $border-radius-small;

  @include rtl {

    padding-right: 0;
    padding-left: 2.5rem;

  }

  &:hover,
  &:focus,
  &:active {

    border: none;
    outline: none;

    opacity: 1;
    background-color: transparent;
    cursor: pointer;

    .c-faq__toggle-indicator {

      color: $color-text-strong-invert;
      background-color: $color-primary-dark;

    }

  }

  .c-faq__toggle-indicator {

    position: absolute;
    top: 0;
    right: 0;
    top: $unit-m - .375rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    border-radius: 1rem;
    transition: $transition-default;

    @include rtl {

      right: auto;
      left: $unit-m;

    }

    .o-icon {

      transition: $transition-default;

    }

    .is-opened & {

      .o-icon {

        transform: rotate(180deg);

      }

    }

  }

  @include breakpoint(small) {

    .c-faq__toggle-indicator {

      top: $unit-m - .25rem;

    }

  }


  @include breakpoint(medium) {

    padding-top: $unit-m;
    padding-bottom: $unit-m;

    .c-faq__toggle-indicator {

      top: $unit-m + .375rem;

    }

  }

  @include breakpoint(medium) {

    padding-top: $unit-l;
    padding-bottom: $unit-l;

  }

}

.c-faq__content {

  .c-faq--switchable & {

    padding-top: 0;
    overflow: hidden;

    transition: all .2s $transition-timing-cubic;

  }

  .is-opened & {

    max-height: 50rem;
    margin-top: 0 - $unit-xs;
    padding-bottom: $unit-m;
    overflow: visible;

    opacity: 1;

    @include breakpoint(medium) {

      margin-top: 0 - $unit-s;
      padding-bottom: $unit-l;

    }

    @include breakpoint(large) {

      // margin-top: 0 - $unit-s;

    }

  }

  .is-closed & {

    margin-top: 0;
    padding-bottom: 0;
    max-height: 0;

    opacity: 0;

  }

}