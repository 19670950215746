/* =Lists
===================================================== */

// Lists
//
// Style guide: 4.lists

ul,
ol,
dl {

  text-align: left;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

}

// Ordered
//
// List examples
//
// Markup:
// <ol>
//   <li>List item</li>
//   <li>List item</li>
//   <li>List item</li>
//   <li>List item</li>
// </ol>
//
// Style guide: 4.lists.ordered

ol {

}

// Unordered
//
// List examples
//
// Markup:
// <ul>
//   <li>List item</li>
//   <li>List item</li>
//   <li>List item</li>
//   <li>List item</li>
// </ul>
//
// Style guide: 4.lists.unordered

li {

  margin-top: .25rem;

  color: $color-text;
  font-weight: $regular;

  &:first-child {

    margin-top: 0;

  }

}