/* =Components: Marketing header navigation
===================================================== */

.c-m-nav-header {

}

.c-m-nav-header__items {

  list-style: none;
  margin: 0;
  padding: 0;

}

.c-m-nav-header__item {

  font-size: 1rem;
  line-height: 1.5;

  font-family: $font-headline;

  @include breakpoint(medium) {

    font-size: 1.125rem;

  }

}

.c-m-nav-header__link {

  display: block;
  padding: .25rem .5rem;

  color: $color-text-invert;

  @include states {

    color: $color-text-strong-invert;

  }

}