/* =Components: Version number
===================================================== */

.c-version {

  position: fixed;
  bottom: 0;
  right: 0;

  padding: $unit-s $unit-m;

  border-top-left-radius: $border-radius-medium;

  transition: all .1s ease-in;
  opacity: 0;

  &:hover {

    opacity: 1;
    background-color: #fff;

  }

  p {

    margin: 0;

    font-size: .875rem;
    line-height: 1.5;
    color: $color-text-weak;

  }

}