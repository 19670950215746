/* =Components: Distribute
===================================================== */

/*
 * Distribute
 *
 * Distribute component that uses slider controls
 * to distribute a certain number between categories.
 */

.c-distribute {

  margin-top: auto;
  margin-bottom: auto;

  @include rtl {

    direction: rtl;

  }

}

.c-distribute__header {

  display: flex;
  align-items: center;
  padding-left: $unit-m;
  padding-right: $unit-m;
  margin-bottom: $unit-m;

  // Pull it out a little to make it look more centred
  // visually (in regards to the question inner padding)
  
  // margin-top: 0 - $unit-m;
  
  //  The above line was commented out due to a bug reported
  //  https://github.com/sdesregistry/IH-Engage/issues/1983#issuecomment-1304953580
  //  We will leave it as is (without visually centring it), as it impacts the
  //  usability of the entire component.

  @include breakpoint(medium-height) {

    margin-top: 0 - $unit-h;
    
    .is-cookie-notice-minimal & {
      margin-top: 0;
    }

  }

  @include breakpoint(medium) {

    margin-top: 0;

  }

}

.c-distribute__items {

  .c-slider + .c-slider {

    @include breakpoint(medium-height) {

      margin-top: $unit-l;

    }

  }

}

.c-distribute__label {

  margin-right: $unit-l;

  font-family: $font-body;
  font-size: .75rem; // 12
  line-height: 1.6666666667; // 20/12
  color: $color-text-weak;
  font-weight: $regular;
  text-align: center;

  @include rtl {

    margin-right: 0;
    margin-left: $unit-l;

  }

  @include breakpoint(small) {

    font-size: .875rem;

  }

}

.c-distribute__total {

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  padding-left: $unit-m;
  padding-right: $unit-m;
  min-width: 3rem;
  min-height: 3rem;

  font-size: 1rem;
  line-height: 1.5;
  text-align: center;

  background-color: darken($color-primary-light, 10%);
  border-radius: $border-radius-small;

  @include rtl {

    margin-right: auto;
    margin-left: 0;

  }

}

.u-a-warning-nudge {

  animation:
    total-nudge .75s $transition-timing-cubic 1 1s;

}

@keyframes total-nudge {

  0%, 100% {

    // transform: scale(1);
    transform: translateY(0);

  }

  50% {

    // transform: scale(1.2);
    transform: translateY(-.25rem);
    background-color: $color-accent-red;

  }

}