/* =Components: Demographic
===================================================== */

/*
 * Demographic
 *
 * Set of demographic questions with a carousel-like
 * mechanic to going through them on a single screen
 *
 * Demographic questions elements:
 * - Groups (intro, outro, questions)
 * - Title
 * - Choices
 */

$demographic-height-desktop: 680px;

.c-demographic {

  /*
   * No `position: relative`
   *
   * Progress and shadow on scroll are added from
   * this element, by adding the `position: relative` of
   * any kind, you interfere with the positioning
   * of these elements.
   */

  // position: relative;
  // z-index: $z-index-question;

  height: 100%;
  width: 100%;
  overflow-y: auto;

  @include rtl {

    direction: rtl;

  }

  /*
   * Soft snap
   * Only active on bigger screens. This is an enhancement only.
   * Resources:
   * - https://css-tricks.com/practical-css-scroll-snapping/
   * - https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-snap-type
   */

  // Currently disabled as it was creating problems
  // with scroll on the Safari (iOS and macOS)
  //
  // scroll-snap-type: y proximity;

  @include breakpoint(medium) {

    width: $question-width-narrow;
    max-width: $question-width-narrow;

    height: $demographic-height-desktop;
    max-height: calc(100vh - 5rem);

  }

}

.c-demographic__group {

  position: relative;
  padding: $unit-m;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;

  @include breakpoint(medium) {

    padding: $unit-l;
    // height: $demographic-height-desktop;
    // max-height: calc(100vh - 5rem);

    // Currently disabled as it was creating problems
    // with scroll on the Safari (iOS and macOS)

    // scroll-snap-align: start;

    // &:last-of-type {

    //   scroll-snap-align: none;

    // }

  }

  @include breakpoint(large) {

    padding: $unit-xl;

  }

}

.c-demographic__header {

  margin-bottom: $unit-m;

  @include breakpoint(medium) {

    margin-bottom: $unit-l;

  }

  @include breakpoint(large) {

    margin-bottom: $unit-xl;

  }

}

.c-demographic__title {

  margin: 0;

  font-size: 1.125rem;
  line-height: 1.333333;

  @include breakpoint(extra-small) {

    font-size: 1.25rem;

  }

  @include breakpoint(small) {

    font-size: 1.375rem;

  }

  @include breakpoint(medium) {

    font-size: 1.5rem;

  }

}

.c-demographic__media {

  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: $unit-m;

  // Base image styles
  svg,
  img {

    margin-left: auto;
    margin-right: auto;
    height: auto;

  }

  // Illustration styles
  svg,
  img[src*=".svg"] {

    margin-top: $unit-s;
    margin-bottom: $unit-s;
    min-width: 14rem;
    width: 70vw;
    max-width: 18rem;

  }

  img[src*=".png"] {

    // margin-top: $unit-s;
    // margin-bottom: $unit-s;
    // min-width: 14rem;
    // width: 70vw;
    // max-width: 18rem;
    max-width: 100%;

  }

  // For custom images
  picture {

    img {

      max-width: 100%;
      display: block;

      border-radius: $border-radius-small;

    }

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-l;

  }

  @include breakpoint(large) {

    margin-bottom: $unit-xl;

  }

}

.c-demographic__choices {}

/*
 * Modifiers
 */

/*
 * Household number
 *
 * Different layout for choices
 */

.c-demographic__group--household-number {

  .l-choice-grid__group {

    display: flex;

  }

  .c-choice-list--number {

    margin-top: 0;
    width: 100%;

    label {

      padding-top: $unit-m;
      padding-bottom: $unit-m;
      padding-left: $unit-xs;
      padding-right: $unit-xs;

      flex-flow: column-reverse;
      justify-content: center;

      border-radius: 0;

    }

    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label {

      .o-icon-male, // Remove after the icon is changed
      .o-icon-user {

        display: none;

      }

      .o-icon-male-filled,  // Remove after the icon is changed
      .o-icon-user-filled {

        display: block;

      }

    }

    .o-icon {

      margin-bottom: $unit-s;

      font-size: 1.25rem;

    }

    .o-icon-male-filled, // Remove after the icon is changed
    .o-icon-user-filled {

      display: none;

    }

    &:first-child label {

      border-top-left-radius: $border-radius-small;
      border-bottom-left-radius: $border-radius-small;

    }

    &:last-child label {

      border-top-right-radius: $border-radius-small;
      border-bottom-right-radius: $border-radius-small;

    }

    &.is-visually-checked label {

      // color: $color-text-strong-invert;
      // background-color: $color-primary-dark;
      color: $color-text-strong;
      background-color: $color-accent-green;

      .o-icon-male, // Remove after the icon is changed
      .o-icon-user {

        display: none;

      }

      .o-icon-male-filled, // Remove after the icon is changed
      .o-icon-user-filled {

        display: block;

      }

    }

  }

}

/*
 * Demographic intro
 */

.c-demographic__group--intro {

  align-items: stretch;

  .c-demographic__media {

    padding-top: 3rem;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $unit-m;

    @include breakpoint(to-medium-height) {

      padding-top: 1rem;

      svg,
      img[src*=".svg"] {

        margin-top: 0;
        margin-bottom: 0;

      }

    }

  }

  .c-demographic__info {

    text-align: center;

    p {

      font-size: 1rem;
      line-height: 1.5;

      &:last-child {

        margin-bottom: 0;

      }

    }

  }

  .c-demographic__start {

    margin-top: auto;
    padding-top: $unit-m;

    text-align: center;

    small {

      display: block;
      margin-bottom: $unit-m;

      color: $color-text-weak;

    }

    .o-icon-arrow-down {

      position: relative;
      margin-left: $unit-xs;
      // animation: scroll-indicator-icon 1s linear infinite;

    }

  }

  @include breakpoint(to-medium) {

    .c-demographic__start {

      margin-left: 0 - $unit-m;
      margin-right: 0 - $unit-m;
      margin-bottom: 0 - $unit-m;

      z-index: $z-index-question + 10;
      // background-color: #fff;

      border-bottom-left-radius: $border-radius-medium;
      border-bottom-right-radius: $border-radius-medium;

      .c-button {

        width: 100%;

        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $border-radius-medium;
        border-bottom-right-radius: $border-radius-medium;

      }

    }

  }

  @include breakpoint(medium) {

    .c-demographic__media {

      margin-bottom: $unit-m;

      svg {

        min-width: 16rem;

      }

    }

    .c-demographic__info {

      // margin-bottom: $unit-l;
      padding-left: $unit-l;
      padding-right: $unit-l;

      p {

        font-size: 1.25rem;
        line-height: 1.6; // 32/20

      }

    }

    .c-demographic__start {

      padding-top: $unit-l;

      .c-button {

        min-width: 20rem;

      }

    }

  }

}

.c-demographic__footer {

  @include breakpoint(to-medium) {

    z-index: $z-index-question + 10;
    background-color: #fff;

    border-bottom-left-radius: $border-radius-medium;
    border-bottom-right-radius: $border-radius-medium;

    .c-button {

      width: 100%;

      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius-medium;
      border-bottom-right-radius: $border-radius-medium;

    }

  }

  @include breakpoint(medium) {

    display: flex;
    align-items: center;
    justify-content: center;
    padding: $unit-l;

    .c-button {

      min-width: 20rem;

    }

  }

  @include breakpoint(large) {

    padding: $unit-xl;

  }

}


/*
 * Scrolling
 *
 * Adding `c-demographic--scroll` to `c-demographic` will automatically
 * show a shadow on the bottom
 */

.c-demographic {

  &:before {

    position: absolute;
    z-index: $z-index-question + 20;
    top: 0;
    left: 0;
    right: 0;

    height: 1rem;

    background-image: radial-gradient(farthest-side at 50% 0, rgba($color-primary-dark,.1), rgba(0,0,0,0));

    opacity: 0;
    pointer-events: none;
    transition: all .1s ease-in;

    content: "";

    @include breakpoint(medium) {

      opacity: 0;

    }

  }

  &.c-demographic--scroll {

    &:before {

      opacity: 1;

    }

  }

}

/*
 * Progress indicator
 *
 * Circle indicating number of answered questions
 */

.c-demographic__progress {

  position: absolute;
  bottom: 100%;
  right: $unit-s;

  margin-bottom: $unit-s;
  width: 2rem;
  height: 2rem;

  transition: $transition-default;

  @include rtl {

    right: auto;
    left: $unit-s;
    transform: scaleX(-1);

  }

  .o-icon-check {

    position: absolute;
    z-index: $z-index-question + 30;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.75);

    font-size: 1.25rem;
    opacity: 0;

    transition: all .25s $transition-timing-cubic;

  }

  &.is-updated {

    .c-demographic__progress-icon {

      transform: rotate(-90deg) scale(.85);

    }

    .c-demographic__progress-circle {

      stroke: $color-primary-dark;

    }

    .c-demographic__progress-background {}


  }

  &.is-completed {

    .o-icon-check {

      transform: translate(-50%, -50%) scale(1);
      color: $color-text-strong;
      opacity: 1;

    }

    .c-demographic__progress-icon {

      background: $color-accent-green;
      transform: rotate(-90deg) scale(1);

    }

    .c-demographic__progress-circle {

      stroke: $color-accent-green;

    }

    .c-demographic__progress-background {

      stroke: $color-accent-green;

    }

  }

}

.c-demographic__progress-icon {

  position: relative;
  transform: rotate(-90deg) scale(.75);
  border-radius: 50%;

  transition: all .25s $transition-timing-cubic;

}
.c-demographic__progress-circle {

  fill: transparent;
  stroke: $color-accent-green;
  stroke-width: 6;

  transition: all .75s $transition-timing-cubic;

}

.c-demographic__progress-background {

  fill: transparent;
  stroke: $color-primary-light;
  stroke-width: 6;

  transition: all .75s $transition-timing-cubic;

}

/*
 * Styles
 */

$decoration-dots-left:    "fact-dots-left-alt.svg";
$decoration-dots-right:   "fact-dots-right-alt.svg";
$decoration-dots-width:   240px;
$decoration-dots-height:  50px;

.c-demographic {

  border-bottom-left-radius: $border-radius-medium;
  border-bottom-right-radius: $border-radius-medium;
}

.c-demographic__group--intro {

  // Sax has a problem with compile if `position: relative` (or any style) is above background
  // so we are keeping it below in this reference.
  background:
    assets-path('/images/facts/#{$decoration-dots-right}') no-repeat top right
  ;
  background-size: $decoration-dots-width $decoration-dots-height;
  background-position: top $unit-xl right 0;

  &:after {

    $fact-clip-start: 8%;
    $fact-clip-end: 2%;

    position: absolute;
    z-index: -1;
    top: $unit-l;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: $color-primary-light;
    border-bottom-right-radius: $border-radius-medium;
    border-bottom-left-radius: $border-radius-medium;

    clip-path: polygon(0 $fact-clip-start, 100% $fact-clip-end, 100% 100%, 0 100%);

    content: "";

    .c-demographic--scroll & {

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

    }

  }

}

.c-demographic__footer {

  // background-color: $color-primary-light;

}

/*
 * Animations
 */

@keyframes scroll-indicator-icon {

  0%, 100% {

    top: -.1rem;

  }

  50% {

    top: .1rem;

  }

}
