/* =Components: Footer section
===================================================== */

.c-section-footer {

  background-color: #fff;
  // box-shadow: $box-shadow-medium;

}

$footer-logo-size: 2rem;
$footer-logo-size-l: 2.5rem;

.c-footer-info {

  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  margin-bottom: 0;

  font-size: .75rem;
  line-height: 1.5;

  @include breakpoint(small) {

    flex-direction: row;
    justify-content: center;

    font-size: .875rem;
    line-height: 1.4285714286;

  }

}

.c-footer-info__title {

  // Sax has a problem with compile if `position: relative` (or any style) is above background
  // so we are keeping it below in this reference.
  background-image: assets-path('/images/gmv-symbol-color.svg');

  text-align: left;

  display: flex;
  align-items: center;
  min-height: $footer-logo-size;
  padding-left: $footer-logo-size + $unit-s;

  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: $footer-logo-size $footer-logo-size;

  @include breakpoint(medium) {

    min-height: $footer-logo-size-l;
    padding-left: $footer-logo-size-l + $unit-s;

    background-size: $footer-logo-size-l $footer-logo-size-l;

  }

}

.c-footer-info__cookies {

  position: relative;
  // top: .0625rem;

  display: flex;
  align-items: center;

  padding: $unit-s $unit-m;
  min-height: $footer-logo-size;

  font-family: $font-body;
  color: $color-text-weak;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {

    text-decoration: none;

  }

  @include breakpoint(medium) {

    min-height: $footer-logo-size-l;

  }

}

.c-footer-info__note {

  width: 100%;
  display: block;
  margin-top: .5rem;

  font-size: .625rem;
  color: $color-text-weak;
  text-align: center;

  a {

    color: $color-text-weak;

    &:hover,
    &:focus,
    &:active {

      color: $color-text-strong;

    }


  }

}