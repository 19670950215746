/* =Components: Choice image
===================================================== */

/*
 * Choice image
 *
 * Questions with images
 */

.c-choice-image {

  position: relative;
  transition: all .2s ease-in;

  label {

    display: block;

    cursor: pointer;

  }

  // Hide original input element
  input[type="radio"],
  input[type="checkbox"] {

    position: absolute;

    opacity: 0;

  }

  input[type="checkbox"]:hover + label,
  input[type="checkbox"]:focus + label {



  }
  input[type="radio"]:focus + label,
  input[type="checkbox"]:focus + label {

    .c-choice-image__media {

      box-shadow: 0 0 0 .25rem $color-accent-green;

    }

  }

  // Basic visual styles on checked state
  input[type="radio"]:checked + label,
  input[type="checkbox"]:checked + label {

    .c-choice-image__media {

      box-shadow: 0 0 0 .25rem $color-accent-green;

      animation: choice-pulse .2s ease-out;

    }

  }

}

.c-choice-image__media {

  position: relative;

  display: block;

  border-radius: $border-radius-small;

  // Experimenting with shadow
  //
  // Default large shadow made a weird jittering effect
  // while scrolling selected choices on iOS.
  // Experimented with different sizes, .25rem seems to work
  // OK so keeping that for the moment.
  box-shadow: 0 0 .25rem 0 rgba($color-primary-dark, .2);

  transition: all .1s ease-in;

  img {

    margin-bottom: 0;
    display: block;
    width: 100%;

    border-radius: $border-radius-small;

  }

  // Parent keeping image proportions depending on aspect
  // ratio of the image that will go inside
  // Square images:
  .l-choice-grid--square-only-three &,
  .l-choice-grid--square-three &,
  .l-choice-grid--square-four &,
  .l-choice-grid--square-four &,
  .l-choice-grid--square-five & {

    padding-bottom: 100%;
    // background-color: $color-primary-light;

    img {

      margin-bottom: -100%;

    }

  }

  // Wide images:
  // 480/800 = 0.60 => 60%
  .l-choice-grid--wide-two &,
  .l-choice-grid--wide-three & {

    padding-bottom: 60%;
    // background-color: $color-primary-light;

    img {

      margin-bottom: -60%;

    }

  }

}

.c-choice-image__title {

  display: block;

  margin-top: .5rem;
  margin-bottom: 0;

  font-size: .875rem;
  line-height: 1.2857142857;
  font-family: $font-body;
  font-weight: $semibold;
  text-align: center;

  @include breakpoint(small) {

    font-size: 1rem;
    line-height: 1.25;

  }

}

.c-choice-image__value {

  font-family: $font-headline;
  font-size: .875rem;
  font-weight: $semibold;
  text-align: center;

  @include breakpoint(small) {

    font-size: 1rem;

  }

}

/*
 * Pie chart version
 *
 * The result state shows a simple pie chart
 */

.c-choice-image--pie {

  .c-choice-image__media {

    overflow: hidden;

  }

  // Set the scene for state change
  .c-choice-image__state {

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: $border-radius-small;

    // Green circle that grows on state change
    &:before {

      position: absolute;
      top: 50%;
      left: 50%;
      width: 2rem;
      height: 2rem;
      margin-left: -1rem;
      margin-top: -1rem;

      // width: 2rem;
      // height: 2rem;
      // margin-top: 50%;
      // margin-left: 50%;

      border-radius:  50%;
      background-color: #fff;
      opacity: 0;

      content: "";

      transition: all .1s ease-in;

    }

  }

  // Show green circle background when checked
  &.is-checked .c-choice-image__state:before {

    opacity: 1;
    transform: scale(1.5);
    background-color: $color-accent-green;

  }

  // Enlarge the green circle background to cover
  // the whole media
  &.is-result .c-choice-image__state:before {

    opacity: .8;
    width: 100%;
    padding-bottom: 100%; // Keep aspect ratio the same
    margin-left: -50%;
    margin-top: -50%;
    transform: scale(1.5);

  }

  // Percentage is centered
  .c-choice-image__value {

    position: relative;
    transform: scale(.8);

    opacity: 0;
    transition: all .3s ease-in;

  }

  // Simple interaction on state change
  &.is-result .c-choice-image__value {

    transform: scale(1);
    color: $color-link;
    opacity: 1;
    transition-delay: .5s;

  }

  // If highlighted show different color
  &.is-checked .c-choice-image__value {

    color: $color-text-strong-invert;

  }

  // If disabled (select maximum number of options)
  &.is-disabled {

    pointer-events: none;

    .c-choice-image__media,
    .c-choice-image__title {

      opacity: .4;

    }

  }

  // If disabled because it's a result
  &.is-disabled.is-result {

    .c-choice-image__media,
    .c-choice-image__title {

      opacity: 1;

    }

  }

  // Make sure user can't interact with the results
  &.is-result {

    pointer-events: none;

  }

}

.c-choice-image__svg {

  position: absolute;
  z-index: $z-index-question;
  top: 50%;
  left: 50%;
  margin-left: -2.5rem;
  margin-top: -2.5rem;

  width: 5rem;
  height: 5rem;

}

.c-choice-image__check,
.c-choice-image__chart,
.c-choice-image__chart-full {

  stroke: none;
  fill: none;
  stroke-width: 1.5;
  stroke-linecap: round;

  transition: all .2s ease-in;

  .is-result & {

    stroke: $color-link;

  }

}

.c-choice-image__check {

  stroke-dasharray: 0, 25;
  stroke: rgba(255,255,255,0);

  .is-result & {

    animation: check-progress .4s ease-in forwards;
    display: none;

  }

  .is-checked & {

    stroke: #fff;
    stroke-dasharray: 16, 25;

  }

  .is-result.is-checked & {

    display: block;

  }

}

.c-choice-image__chart {

  .is-result.is-checked & {

    stroke: #fff;

  }

  .is-result & {

    animation: chart-progress 1s ease-out forwards;

  }

}

.c-choice-image__chart-full {

  .is-result & {

    stroke: rgba($color-link, .2);

  }

  .is-result.is-checked & {

    stroke: rgba(255,255,255,.2);

  }

}

@keyframes chart-progress {

  0% { stroke-dasharray: 0 100; }

}

@keyframes check-progress {

  0% {
    stroke-dasharray: 16, 25;
  }
  50% {
    stroke-dasharray: 10, 25;
    stroke-dashoffset: -6;
  }
  50% {
    stroke: rgba(255,255,255,1);
  }
  100% {
    stroke-dasharray: 25, 25;
    stroke-dashoffset: -25;
    stroke: rgba(255,255,255,0);
  }

}

@keyframes choice-pulse {

  0%, 100% { transform: scale(1); }
  80% { transform: scale(1.025); }

}

@keyframes bar-progress {

  0% { height: 0; }

}

/*
 * Bar chart version
 *
 * The result state shows a simple pie chart
 */

.c-choice-image--bar {

  padding-top: 0;

  .c-choice-image__media {

    overflow: visible;

  }

  .c-choice-image__value {

    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin-bottom: 0 - $unit-s;
    transform: scale(.9);

    padding: $unit-s;

    opacity: 0;
    transition: all .2s ease-in;

  }

  &.is-result {

    padding-top: 2rem;
    transform: scale(.95);

    pointer-events: none;

    .c-choice-image__value {

      margin-bottom: 0;
      transform: scale(1);
      opacity: 1;

    }

    .c-choice-image__media,
    .c-choice-image__title {

      opacity: .4;

    }

  }

  &.is-result.is-highlight {

    // transform: scale(1.025);
    transform: scale(1);

    .c-choice-image__media,
    .c-choice-image__title {

      opacity: 1;

    }

  }

  @include breakpoint(medium) {

    label:hover {

      .c-choice-image__media {

        transform: scale(1.05);

      }

    }

  }

}

.c-choice-image__bar {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;

}

.c-choice-image__bar-full {

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // height: 0;

  transition: all .2s ease-in;


  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;

  .is-result & {

    background-color: rgba($color-accent-green, .8);
    animation: bar-progress .3s ease-out forwards;

  }

}