/* =Objects: Forms
===================================================== */

/*
 * Forms
 *
 * Small form objects:
 * - input with icon
 * - input with button
 * - input (disabled) with edit
 * - radio
 * - checkbox
 * - select
 * - upload
 * - map
 */


/*
 * Input
 *
 * Regular input styles for complex containers
 */

.o-input {

  position: relative;

}

.o-input--icon {

  #{$all-text-inputs} {

    padding-right: 3rem;

    @include rtl {

      padding-right: .9375rem;
      padding-left: 3rem;

    }

    @include force-ltr {

      text-align: left!important;
      direction: ltr!important;

    }

  }


  #{$all-text-inputs-focus} {

    + .o-icon {

    opacity: 1;

    }

  }

  .o-icon {

    position: absolute;
    top: .875rem;
    right: .875rem;

    font-size: 1.25rem;
    color: $color-text-weak;

    pointer-events: none;

    opacity: .4;

    transition: all .1s ease-in;

    @include rtl {

      right: auto;
      left: .875rem;

    }

  }

}

.o-input--edit {

  .c-button {

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;

    font-size: .875rem;
    color: $color-text-weak;
    background-color: $color-input-regular;
    text-transform: uppercase;
    font-family: $font-body;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:before {

      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      width: 1rem;
      background: red;
      background: linear-gradient(to right, rgba($color-input-regular,0), $color-input-regular);

      content: "";

    }

    @include states {

      background-color: $color-input-regular;
      color: $color-text-strong;
      border: none;
      outline: none;
      box-shadow: none;

    }

    @include rtl {

      right: auto;
      left: 0;

      border-top-left-radius: $border-radius-small;
      border-bottom-left-radius: $border-radius-small;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:before {

        left: 100%;
        right: auto;

        background: linear-gradient(to left, rgba($color-input-regular,0), $color-input-regular);

      }

    }

    @include force-ltr {

      right: 0;
      left: auto;

      border-top-right-radius: $border-radius-small;
      border-bottom-right-radius: $border-radius-small;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:before {

        left: auto;
        right: 100%;

        background: linear-gradient(to right, rgba($color-input-regular,0), $color-input-regular);

      }

    }

  }

}

.o-input--prefix {

  display: flex;

  #{$all-text-inputs} {

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // padding-right: 3rem;

    // @include rtl {

    //   padding-right: .9375rem;
    //   padding-left: 3rem;

    // }

    // @include force-ltr {

    //   text-align: left!important;
    //   direction: ltr!important;

    // }

  }

}

.o-input__prefix {

  padding-left: $unit-s;
  padding-right: $unit-s;
  min-width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  color: $color-text;
  background-color: $color-input-regular;
  border-top-left-radius: $border-radius-small;
  border-bottom-left-radius: $border-radius-small;

}

.o-select {

  position: relative;

  background-color: #fff;
  border-radius: $border-radius-small;
  box-shadow: inset 0 0 0 .0625rem $color-input-regular;

  .o-icon-angle-down {

    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);

    color: $color-text-weak;
    font-size: 1.25rem;
    
    pointer-events: none;

  }

  select {

    background: transparent;
    border: none;
    width: 100%;
    box-shadow: none;

    padding-right: 3rem;

    @include prefixer(appearance, none, ("webkit", "moz"));

    &:focus {

      outline: none;
      background-color: $color-primary-light;

    }

  }

  option {

    font-size: 1rem;
    line-height: 1.5rem;

    color: $color-text-strong;

  }

  &:hover {

    box-shadow: inset 0 0 0 .0625rem $color-input-focus;
    // box-shadow: inset 0 0 0 .125rem $color-input-focus;

  }

}

.o-select.is-disabled {

  background-color: $color-primary-light;
  box-shadow: none;

  pointer-events: none;

  .o-icon-angle-down {

    color: rgba($color-text-weak, .25);

  }

  select {

    color: rgba($color-text-weak, .25);

  }

}

/*
 * Radio + Checkbox
 *
 * Shared styles for both controls
 */

.o-radio,
.o-checkbox {

  position: relative;

  text-align: left;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  label {

    display: inline-block;
    cursor: pointer;
    padding: .5rem 0 .5rem 1.75rem;

    font-family: $font-body;
    font-size: .875rem;
    line-height: 1.5;
    color: $color-text;

    @include rtl {

      padding-right: 1.75rem;
      padding-left: 0;

    }

    @include breakpoint(small) {

      font-size: 1rem;

    }

  }

  label:before {

    position: absolute;
    top: .625rem;
    left: 0;

    width: 1.25rem;
    height: 1.25rem;
    border: .125rem solid $color-input-regular;

    border-radius: $border-radius-small;

    content: "";

    @include rtl {

      left: auto;
      right: 0;

    }

  }

  small {

    font-size: .875rem;
    color: $color-text-weak;

  }

}

.o-radio--inline,
.o-checkbox--inline {

  display: inline-block;
  margin-right: 1rem;

}

/*
 * Radio
 */

.o-radio {

  label:before {

    border-radius: 50%;

  }

  label:after {

    position: absolute;
    top: 1rem;
    left: .375rem;

    border-radius: 50%;
    width: .5rem;
    height: .5rem;
    background-color: #fff;

    opacity: 0;
    content: "";

    @include rtl {

      left: auto;
      right: .375rem;

    }

  }

  input[type="radio"] {

    position: absolute;

    opacity: 0;

  }

  input[type="radio"]:hover + label:before,
  input[type="radio"]:focus + label:before {

    box-shadow: 0 0 0 .25rem tint($color-primary-dark, 90%);
    border-color: $color-input-focus;

  }

  input[type="radio"]:checked + label:before {

    border: 2px solid $color-link;

    width: 1.25rem;
    height: 1.25rem;
    background: $color-link;

  }

  input[type="radio"]:checked + label:after {

    opacity: 1;

  }

  input[type="radio"]:hover + label:after,
  input[type="radio"]:focus + label:after {

    background-color: $color-input-focus;

    opacity: 1;

  }

}

.o-radio + .o-radio {

  margin-top: -.3125rem;

}

/*
 * Radio Button
 */

.o-radio--button {

  display: inline-block;

  label {

    display: block;

    padding: .25rem 1rem;
    width: 100%;

    font-size: .875rem;
    line-height: 1.4285714286;
    color: $color-text-weak;
    font-family: $font-headline;
    font-weight: $medium;
    text-align: center;

    border-radius: 1.5rem;

    .o-icon {

      position: relative;
      top: 0;
      height: 1.25rem;

      vertical-align: top;
      font-size: 1rem;

    }

  }

  label:after,
  label:before {

    display: none;

  }

  input[type="radio"]:checked + label {

    color: $color-text-strong-invert;
    background-color: $color-button-primary;

  }

  input[type="radio"]:hover + label,
  input[type="radio"]:focus + label {

    .o-icon {

      transform: scale(1.1);

    }

  }

}

.o-radio--tab {

  display: inline-block;

  label {

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: -.25rem;
    margin-bottom: -.25rem;
    padding: 0;
    min-width: 2.5rem;
    min-height: 2.5rem;

    font-family: $font-headline;
    font-weight: $semibold;
    font-size: .875rem;
    line-height: 1.4285714286;

    border-radius: 1.25rem;

    @include rtl {

      padding: 0;

    }

  }

  .o-icon {

    font-size: 1.125rem;

  }

  .o-radio__label {

    @include hide-visually();

  }

  label:after,
  label:before {

    display: none;

  }

  input[type="radio"]:hover + label,
  input[type="radio"]:focus + label {

    background-color: $color-grey-x-light;

  }

  input[type="radio"]:focus + label {

    box-shadow: 0 0 0 .1875rem lighten($color-accent-green, 20%);

  }

  input[type="radio"]:checked + label {

    color: $color-text-strong;
    background-color: $color-accent-green;

  }

  @include breakpoint(small) {

    label {

      margin-top: 0;
      margin-bottom: 0;
      padding: .25rem 1rem;
      min-height: 2rem;

      border-radius: 1rem;

      @include rtl {

        padding: .25rem 1rem;

      }

    }

    .o-icon {

      margin-right: $unit-xs;

      @include rtl {

        margin-right: 0;
        margin-left: $unit-xs;

      }

    }

    .o-radio__label {

      @include hide-visually(unhide);

    }

  }

  @include breakpoint(medium) {

    label {

      // font-size: 1rem;

    }

  }

}

/*
 * Checkbox
 */

.o-checkbox {

  label > .o-icon-check {

    position: absolute;
    top: .75rem;
    left: .125rem;

    font-size: 1rem;
    line-height: 1;
    color: $color-text-weak;

    opacity: 0;

    @include rtl {

      transform: scaleX(-1);
      left: auto;
      right: .125rem;

    }

  }

  input[type="checkbox"] {

    position: absolute;

    opacity: 0;

  }

  input[type="checkbox"]:hover + label:before,
  input[type="checkbox"]:focus + label:before {

    box-shadow: 0 0 0 .25rem tint($color-primary-dark, 90%);
    border-color: $color-input-focus;

  }

  input[type="checkbox"]:checked + label:before {

    border-color: transparent;
    background: $color-link;

  }

  input[type="checkbox"]:checked:disabled + label:before {

    border-color: transparent;
    background: $color-input-focus;
    box-shadow: none;

  }


  /*
   * Check icon
   */

  input[type="checkbox"]:hover + label > .o-icon-check,
  input[type="checkbox"]:focus + label > .o-icon-check {

    color: $color-input-regular;
    opacity: 1;

  }

  input[type="checkbox"]:checked + label > .o-icon-check {

    color: $color-text-strong-invert;
    opacity: 1;

  }

}

.o-checkbox + .o-checkbox {

  margin-top: -.3125rem;

}

/*
 * Checkbox toggle
 */

.o-checkbox--toggle {

  label > .o-icon-check {

    display: none;

  }

  label {

    padding-left: 4rem;

    @include rtl {

      padding-left: 0;
      padding-right: 4rem;

    }

  }

  label:before {

    width: 3rem;
    height: 1.5rem;

    border-radius: .875rem;

    transition: all .1s ease-in;

  }

  label:after {

    position: absolute;
    top: .875rem;
    left: .25rem;

    width: 1rem;
    height: 1rem;
    background-color: $color-input-regular;

    border-radius: .75rem;

    transition: all .1s ease-in;

    content: "";

    @include rtl {

      left: auto;
      right: .25rem;

    }

  }

  input[type="checkbox"]:checked + label:before {

    background-color: $color-link;

  }

  input[type="checkbox"]:disabled + label:before {

    border-color: transparent;
    box-shadow: none;
    background-color: $color-input-regular;

  }

  input[type="checkbox"]:checked + label:after {

    left: 1.75rem;
    border-color: transparent;
    background-color: $color-text-strong-invert;

    @include rtl {

      left: auto;
      right: 1.75rem;

    }

  }

  input[type="checkbox"]:disabled + label:after {

    background-color: $color-text-strong-invert;

  }

  &.is-danger {

    input[type="checkbox"]:checked + label:before {

      background-color: $color-danger;

    }

  }

}