/* =Components: Annotate
===================================================== */

/*
 * Annotate
 *
 * Main component for the annotate
 * question type
 */

.c-annotate {

  position: relative;

  display: flex;
  flex-flow: column;

  overflow: hidden;

  border-bottom-left-radius: $border-radius-medium;
  border-bottom-right-radius: $border-radius-medium;
  transition: all .25s $transition-timing-cubic;

  &:last-child {

    padding-bottom: 0;

  }

  &:before {

    position: absolute;
    z-index: $z-index-question;
    top: 0;
    left: 0;
    right: 0;

    height: 1rem;

    background-image: radial-gradient(farthest-side at 50% 0, rgba($color-primary-dark,.1), rgba(0,0,0,0));

    pointer-events: none;
    transition: $transition-default;

    content: "";

    .c-modal-poll--annotate-multiple.c-modal-poll--annotate-edit & {

      opacity: 0;

    }

  }

  @include breakpoint(to-medium) {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .c-modal-poll--annotate-edit.c-modal-poll--annotate-multiple & {

      border-top-left-radius: $border-radius-medium;
      border-top-right-radius: $border-radius-medium;

    }

  }

  /*
   * Taking into account `c-question` padding
   * and counteracting with negative margins and
   * calculated heighs.
   */

  @include breakpoint(medium) {

    margin-left: 0 - $unit-l;
    margin-right: 0 - $unit-l;
    margin-bottom: 0 - $unit-l;

    height: 100%;
    height: calc(100% + 1.5rem); // Doesn't work with $unit-l;

    .c-modal-poll--annotate-edit.c-modal-poll--annotate-multiple & {

      margin-top: 0 - ($unit-l);
      height: calc(100% + 3rem);

      border-top-left-radius: $border-radius-medium;
      border-top-right-radius: $border-radius-medium;

    }

  }

  @include breakpoint(large) {

    margin-left: 0 - $unit-xl;
    margin-right: 0 - $unit-xl;
    margin-bottom: 0 - $unit-xl;

    height: calc(100% + 2rem);  // Doesn't work with $unit-xl;

    .c-modal-poll--annotate-edit.c-modal-poll--annotate-multiple & {

      margin-top: 0 - $unit-xl;
      height: calc(100% + 4rem);

    }

  }

  .c-modal-poll--annotate-done & {

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include breakpoint(medium) {

      border-bottom-left-radius: $border-radius-medium;
      border-bottom-right-radius: $border-radius-medium;

    }

  }

}