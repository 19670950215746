/* =Objects: Layouts (Polls only)
===================================================== */

/*
 * Polls only
 *
 * These are styles that are used to hide certain parts of GMV frontend
 * if current view is "polls only".
 */

.l-app--polls-only {
  
  #appNav,
  .c-masthead__title,
  .c-masthead__tagline {
    display: none;
  }
  
  .c-masthead__logo a {
    cursor: none;
    pointer-events: none;
  }
  
}


.polls-only--hidden-page {
  //  This can be used to hide entire pages, though a better UX would be
  //  to use 301 redirect instead (Page permanently moved!) or 404 (Page not found!).
  //  Use it on <html> element!
  
  display: none !important;
}


.polls-only--hidden {
  //  Use this to hide certain page elements, when a visitor is browsing
  //  "polls only" app.
  
  display: none !important;
}


.polls-only--inactive {
  //  Use this to make certain page elements "inactive". These are elements that 
  //  we still want to have visible (like company logo) but want to make it
  //  not clickable ("inactive").

  cursor: none !important;
  pointer-events: none !important;
}