/* =Components: Reactions
===================================================== */

/*
 * Choice reactions
 *
 * Layout used for reactions
 */

.c-reactions {

  position: relative;

}

.c-reactions__controls {}

.c-reactions__next,
.c-reactions__prev {

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: $z-index-question + 10;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: .875rem 0;
  width: $carousel-button;
  border: none;

  color: $color-link;
  background-color: #fff;
  box-shadow: $box-shadow-large;
  border-radius: 50%;

  transition: all .1s ease-in;

  .o-icon {

    font-size: 1.25rem;
    line-height: 1;

  }

  @include states {

    color: $color-text-strong-invert;
    background-color: $color-primary-dark;

  }

  &:focus {

    box-shadow: 0 0 0 .1875rem darken($color-primary-light, 10%);
    outline: none;

  }

  &.slick-disabled {

    opacity: 0;

  }

  @include states {

    color: $color-text-strong-invert;
    background-color: $color-primary-dark;

  }

}

.c-reactions__next {

  right: 0;
  margin-right: 0 - $unit-l;

}

.c-reactions__prev {

  left: 0;
  margin-left: 0 - $unit-l;

}

.c-reactions--carousel {

  /*
   * Fade out elements
   *
   * Currently disabled as the slider doesn't
   * take into account left and right margins
   */


  // &:before,
  // &:after {

  //   position: absolute;
  //   z-index: $z-index-question;
  //   top: 0;
  //   bottom: 0;

  //   width: $unit-m;

  //   content: "";

  // }

  // &:before {

  //   left: 0;
  //   background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));

  // }

  // &:after {

  //   right: 0;

  //   background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));

  // }

}

/*
 * Choice reactions: Scroll
 *
 * Scrollable version of the reactions list
 */

.c-reactions--scroll {

  margin-left: 0 - $unit-m;
  margin-right: 0 - $unit-m;

  &:before,
  &:after {

    position: absolute;
    z-index: $z-index-question;
    top: 0;
    bottom: 0;

    width: $unit-m;

    content: "";

  }

  &:before {

    left: 0;
    background: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));

  }

  &:after {

    right: 0;

    background: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0));

  }

  .c-reactions__items {

    display: flex;
    overflow-x: auto;

  }

  .c-choice-icon {

    display: flex;
    flex: 1;

  }

  .c-choice-icon:first-child {

    margin-left: auto;

    &:before {

      display: block;
      width: $unit-m;

      content: "";

    }

  }

  .c-choice-icon:last-child {

    margin-right: auto;

    &:after {

      display: block;
      width: $unit-m;

      content: "";

    }

  }

}