/* =Components: Homepage — Team
===================================================== */

/*
 * Team
 *
 * Team component
 */

@use "sass:math";

.c-team {

  margin-bottom: $container-unit-small * 2;

  @include breakpoint(medium) {

    margin-bottom: $container-unit-medium * 2;

  }

  @include breakpoint(large) {

    margin-bottom: $container-unit-large * 2;

  }

  &:last-child {

    margin-bottom: 0;

  }

}

.c-team__heading {

  margin-top: 0;
  margin-bottom: $unit-m;

  font-size: ms(0);
  line-height: ms(1);

  @include breakpoint(small) {

    font-size: ms(1);
    line-height: ms(2);

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-l;

  }

  .c-block--dark & {

    color: $color-text-strong-invert;

  }

}

.c-team__items {

  display: flex;
  flex-flow: row wrap;
  margin-top: 0 - $unit-m;
  margin-bottom: 0 - $unit-m;
  margin-left: 0 - $unit-m;
  margin-right: 0 - $unit-m;

  @supports (display: grid) {

    display: grid;

    grid-gap: $unit-m;
    grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    margin: 0;

    @include breakpoint(small) {

      grid-gap: $unit-l;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

    }

  }

}

.c-team__item {

  padding: $unit-m;
  width: percentage(1*0.5);

  @include breakpoint(extra-small) {

    width: percentage(math.div(1, 3));

  }

  @include breakpoint(small) {

    width: percentage(1*0.25);

  }

  @include breakpoint(medium) {

    width: percentage(1*0.2);

  }

  @supports (display: grid) {

    width: 100%;
    padding: 0;

  }

  text-align: center;

  a {

    display: block;

    @include states {

      .c-team__logo {

        box-shadow: 0 0 0 .1875rem $color-accent-green;

      }

    }

  }

}

.c-team__logo {

  margin: 0;
  margin-bottom: $unit-s;

  border-radius: $border-radius-small;

  picture {

    display: block;

  }

  img {

    display: block;
    max-width: 100%;

    border-radius: $border-radius-small;
    box-shadow: $box-shadow-medium;

  }

  @include breakpoint(medium) {

    margin-bottom: $unit-m;

    img {

      box-shadow: $box-shadow-large;

    }

  }

}

.c-team__title {

  margin-top: 0;
  margin-bottom: 0;

  font-size: .875rem;
  line-height: 1.4285714286;
  text-align: inherit;

  @include breakpoint(small) {

    font-size: 1rem;
    line-height: 1.375;

  }

}

.c-team__meta {

  display: block;
  margin-top: 0;
  margin-bottom: 0;

  font-size: .75rem;
  line-height: 1.3333333333;
  color: $color-text-weak;

  @include breakpoint(small) {

    font-size: .875rem;
    line-height: 1.428571428571429;

  }

}




.c-team--list {

  @include breakpoint(to-small) {

    .c-team__items {

      display: flex;
      flex-direction: column;

      @supports (display: grid) {

        display: grid;
        grid-template-columns: 1fr;

      }

    }

    .c-team__item {

      display: flex;
      width: 100%;
      margin-top: $unit-m;

      &:first-chil {

        margin-top: 0;

      }

      @supports (display: grid) {

        margin-top: 0;

      }

    }

    .c-team__item a {

      display: flex;
      flex-direction: row;
      align-items: center;

    }

    .c-team__logo {

      width: 5rem;
      flex: 0 0 5rem;
      margin-bottom: 0;

    }

    .c-team__group {

      margin-left: $unit-m;

      text-align: left;

    }

    .c-team__title {

      font-size: 1rem;
      line-height: 1.5;

    }

    .c-team__meta {

      font-size: .875rem;
      line-height: 1.428571428571429;

    }

  }

}