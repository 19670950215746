/* =Utilities: Text
===================================================== */

/*
 * Text
 *
 * Specific text sizes
 */

.u-text-small {

  font-size: .875rem;
  line-height: 1.4285714286;

  color: $color-text-weak;

}

.u-text-center {

  text-align: center;

}

.u-text-left {

  text-align: left;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

}

.u-text-right {

  text-align: right;

  @include rtl {

    text-align: left;
    direction: rtl;

  }

}

