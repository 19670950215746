/* =Components: Explainer
===================================================== */

/*
 * Explainer
 *
 * Simple explainer for onboarding and polls
 */

$explainer-height: 24rem;
$explainer-height-medium: 26rem;
$explainer-height-large: 28rem;

.c-explainer {

  @include responsive-spacing(margin-top, $container-proportion, negative);
  @include responsive-spacing(margin-left, $container-proportion, negative);
  @include responsive-spacing(margin-right, $container-proportion, negative);
  @include responsive-spacing(margin-bottom, $container-proportion);

  animation: scale-in-show .25s $transition-timing-cubic forwards;
  transition: $transition-default;

  @include rtl {

    direction: rtl;

  }

  @include breakpoint(medium) {

    margin-left: 0;
    margin-right: 0;

  }

  &[aria-hidden="true"] {

    display: none;

  }

  &[aria-hidden="false"] {

    display: block;

  }

}

/*
 * Content group
 */

.c-explainer__group {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: $unit-l $unit-m;

  text-align: center;

  min-height: $explainer-height;

  @include breakpoint(small) {

    min-height: $explainer-height-medium;

  }

  @include breakpoint(medium) {

    min-height: $explainer-height-large;

  }

}

/*
 * Charities
 */

.c-explainer__charities {

  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: auto;

  @include breakpoint(medium) {

    padding-left: $unit-xl;
    padding-right: $unit-xl;

  }

  @include breakpoint(large) {

    flex-direction: row;
    margin-bottom: 0;

    .c-charity {

      margin-left: $unit-xl;

      &:first-child {

        margin-left: 0;

      }

      @include rtl {

        &:last-child {

          margin-left: 0;

        }

        &:first-child {

          margin-left: $unit-xl;

        }

      }

    }

  }

}

.c-explainer__title {

  margin-top: 0;
  margin-bottom: .25rem;

  font-size: 1.25rem;
  line-height: 1.2;
  color: $color-text-strong;

  @include breakpoint(small) {

    margin-bottom: .5rem;

    font-size: 1.5rem;
    line-height: 1.2;

  }

}

.c-explainer__actions {

  display: flex;
  flex-direction: column;

  margin-bottom: $unit-m;
  margin-top: $unit-xl;

  &:first-child {

    margin-top: 0;

  }

  &:last-child {

    margin-bottom: 0;

  }

  .c-button {

    min-width: 16rem;

  }

  .c-button + .c-button {

    margin-top: $unit-m;

  }

  @include breakpoint(medium) {

    flex-direction: row;

    .c-button + .c-button {

      margin-top: 0;
      margin-left: $unit-m;

    }

  }

}

.c-explainer__description {

  margin-bottom: 0;
  max-width: 30em;

  font-size: .875rem;
  line-height: 1.4285714286;

  color: $color-text-weak;

  @include breakpoint(extra-small) {

    font-size: 1rem;
    line-height: 1.5;

  }

  @include breakpoint(small) {

    font-size: 1.125rem;
    line-height: 1.4444444444;

  }

  @include breakpoint(medium) {

    font-size: 1.25rem;
    line-height: 1.4;

  }

}

.c-explainer__figure {

  margin-bottom: 0;

  svg {

    width: 12rem;
    height: 12rem;

  }

  * {

    transform-origin: center;
    transform-box: fill-box;

  }

  @include breakpoint(medium) {

    svg {

      margin-bottom: -1rem;
      width: 16rem;
      height: 16rem;

    }

  }

}

/*
 * Animations
 *
 * Animations related to explainer.
 * They are active when that specific slide is active
 * with `slick-active` class (default by the component).
 * Animation keyframes are defined in `_helpers.animation.scss`.
 */

#arm-1,
#arm-3 {

  transform-origin: bottom right;

}

#arm-2 {

  transform-origin: bottom left;

}

.c-explainer .slick-active {

  #cloud {

    animation:
      explainer-popup .5s ease-in-out,
      explainer-flow 1.5s infinite ease-in-out;
    animation-fill-mode: backwards;

  }

  #arm-1,
  #arm-2,
  #arm-3 {

    animation:
      explainer-flow 1.5s ease-in infinite;
    animation-fill-mode: backwards;

  }

  #arm-1-up,
  #arm-2-up,
  #arm-3-up {

    animation:
      explainer-pulse 2s infinite ease-in;

  }

  #coin-1,
  #coin-2,
  #coin-3 {

    animation:
      explainer-popup .5s ease-in-out,
      explainer-coin-flip 3s ease-in-out infinite;
    animation-fill-mode: backwards;

  }

  #coin-1 {

    animation-delay: .75s;

  }

  #coin-2 {

    animation-delay: 1s;

  }

  #coin-3 {

    animation-delay: 1.25s;

  }

  #person__eyes {

    animation: explainer-eyes-blink 2s infinite;

  }

  #building__flag-fill,
  #building__flag-outline {

    animation: explainer-flag-wave 3s infinite;
    transform-origin: 0 0;

  }

  @for $i from 1 through 11 {

    #confetti-#{$i} {

      animation:
        explainer-pulse 1s infinite ease-in;

      animation-delay: ($i * random())#{s};

    }

  }

}