/* =Components: Social
===================================================== */

.c-social {

  @include rtl {

    direction: rtl;

  }

}

.c-social__items {

  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;

}

.c-social__item {

  margin-top: 0;
  margin-left: $unit-m;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $semibold;
  font-family: $font-headline;

  &:first-child {

    margin-left: 0;

  }

  @include rtl {

    margin-left: 0;
    margin-right: $unit-m;

    &:first-child {

      margin-right: 0;

    }

  }

}

.c-social__label {

  @include hide-visually();

}

.c-social__link {

  display: flex;
  justify-content: center;

  padding: .625rem 1rem;
  border: .125rem solid transparent;

  display: flex;
  align-items: center;
  padding: .625rem;
  border: .125rem solid transparent;

  color: $color-text-strong-invert;
  background-color: $color-link;
  border-radius: 1.5rem;
  cursor: pointer;

  @include states {

    color: $color-text-strong-invert;

  }

  .o-icon {

    font-size: 1.5rem;

  }

  .c-social--small & {

    padding: .25rem;

    font-size: .875rem;
    line-height: 1.4285714286;

    .o-icon {

      font-size: 1.25rem;

    }

  }

  @include breakpoint(medium) {

    .c-social--large & {

      padding: .8125rem;

      font-size: 1.125rem;
      line-height: 1.4444444444;

      border-radius: 1.75rem;

    }

  }

}

.c-social__item--facebook {

  .c-social__link {

    background-color: $color-facebook;

    @include states {

      background-color: lighten($color-facebook, 10%);

    }

  }

}

.c-social__item--twitter {

  .c-social__link {

    background-color: $color-twitter;

    @include states {

      background-color: lighten($color-twitter, 10%);

    }

  }

}

.c-social__item--x {

  .c-social__link {

    background-color: $color-x;

    @include states {

      background-color: lighten($color-x, 10%);

    }

  }

}

.c-social__item--whatsapp {

  .c-social__link {

    background-color: $color-whatsapp;

    @include states {

      background-color: lighten($color-whatsapp, 10%);

    }

  }

}

.c-social__item--messenger {

  .c-social__link {

    background-color: $color-messenger;

    @include states {

      background-color: lighten($color-messenger, 10%);

    }

  }

}

/*
 * Modifiers
 */

.c-social--labels {

  .c-social__items {

    margin-left: 0 - $unit-s;
    margin-right: 0 - $unit-s;
    margin-top: 0 - $unit-s;
    margin-bottom: 0 - $unit-s;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;

  }

  .c-social__item {

    max-width: 14rem;
    width: 100%;

    padding:  $unit-s;
    margin: 0;


  }

  .c-social__link {

    padding-left: 1.25rem;
    padding-right: 1rem;

    @include rtl {

      padding-left: 1rem;
      padding-right: 1.25rem;

    }

  }

  .c-social__label {

    @include hide-visually(unhide);

    margin-right: auto;

    @include rtl {

      margin-right: 0;
      margin-left: auto;

    }

  }

  .o-icon {

    margin-left: $unit-s;

    @include rtl {

      margin-left: 0;
      margin-right: $unit-s;

    }

  }

  /* CSS Grid layout
   *
   * If the browser supports CSS Grid, we are using it
   * to enhance the layout (and override flexbox).
   */

  // @supports (display: grid) {

  //   .c-social__items {

  //     display: grid;
  //     grid-gap: $unit-s;
  //     grid-template-columns: 1fr;
  //     justify-items: center;

  //     margin: 0;

  //     @include breakpoint(extra-small) {

  //       grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

  //     }

  //   }

  //   .c-social__item {

  //     padding: 0;
  //     max-width: 100%;

  //   }

  // }

}