/* =Components: Homepage — About
===================================================== */

/*
 * About
 *
 * About component with show more/show less
 * type of control on the content.
 */


.c-about {

  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: all .1s ease-in;

}

.c-about__content {

  text-align: center;

}

.c-about__heading {

  margin-top: 0;
  margin-bottom: $unit-s;

  font-size: ms(0);
  text-align: center;

  &:last-child {

    margin-bottom: 0;

  }

  @include breakpoint(extra-small) {

    margin-bottom: $unit-m;
    align-self: center;

    font-size: ms(1);

  }

  @include breakpoint(small) {

    font-size: ms(2);

  }

  @include breakpoint(medium) {

    font-size: ms(3);

  }

}

.c-about__description {

  position: relative;

  margin-bottom: $unit-l;
  // padding-bottom: ms(2);

  &:last-child {

    margin-bottom: 0;

  }

  p {

    overflow: hidden;

    // overflow: hidden;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 2;
    //     -webkit-box-orient: vertical;

    margin-top: 0;
    margin-bottom: 0;

    font-size: ms(-1);
    line-height: ms(2);

    @include breakpoint(extra-small) {

      font-size: ms(0);

    }

    @include breakpoint(medium) {

      font-size: ms(1);

    }

  }

}

/*
 * Toggle more/less
 *
 * If there are more than 400 chars, we add a class
 * which limits the paragraph to Y lines (depends on resolution)
 * and shows a button to toggle the description.
 */

.c-about__description--toggle {

  .c-about__toggle {

    display: block;

    .o-icon {

      margin-left: $unit-xs;

    }

  }

  p {

    transition: $transition-default;

    max-height: ms(2) * 6; // Limit to 6 lines

    @include breakpoint(extra-small) {

      max-height: ms(2) * 4;  // Limit to 4 lines

    }

    @include breakpoint(medium) {

      max-height: ms(2) * 3;  // Limit to 3 lines

    }

  }

  p + p {

    max-height: 0;

  }

  &.is-opened {

    p {

      max-height: 100%;

    }

    p + p {

      margin-top: $unit-m;

    }

  }

}

.c-about__toggle {

  /* Hidden by default */
  display: none;

  padding: 0 $unit-s;
  border: none;
  margin-left: auto;
  margin-right: auto;

  font-size: ms(-1);
  line-height: ms(2);
  font-weight: $medium;
  font-family: $font-body;
  text-decoration-color: $color-accent-green;

  background-color: transparent;
  border-radius: 0;

  @include states {

    text-decoration: underline;
    border: none;

    color: $color-text-strong;
    text-decoration-color: $color-accent-green;
    text-decoration-thickness: .1em;
    background-color: transparentize($color-accent-green, .8);
    box-shadow: 0 0 0 .25rem transparentize($color-accent-green, .8);
    border-radius: $border-radius-small;

  }

  @include breakpoint(extra-small) {

    font-size: ms(0);

  }

  @include breakpoint(medium) {

    font-size: ms(1);

  }

  .is-opened & {

    .o-icon {

      transform: rotate(180deg);

    }

  }

}