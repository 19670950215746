/* =Components: Header navigation
===================================================== */

.c-nav-header {

  width: 100%;

  @include rtl {

    direction: rtl;

  }

}

.c-nav-header__items {

  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;

  @include breakpoint(to-medium) {

    margin-left: 0 - $unit-m;
    margin-right: 0 - $unit-m;

  }

  @include breakpoint(medium) {

    justify-content: space-between;

  }

}

.c-nav-header__item {

  margin: 0;
  height: $header-nav-height;

  font-size: 1.25rem;
  font-family: $font-headline;

  @include breakpoint(to-medium) {

    flex: 1;

  }

}

.c-nav-header__link {

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: $unit-xs $unit-xs;

  color: $color-text-weak;

  .o-icon {

    min-width: 1.5rem;

    font-size: 1.5rem;

  }

  @include rtl {

    .o-icon-nav-polls,
    .o-icon-nav-timeline,
    .o-icon-nav-news {

      transform: scaleX(-1);

    }

  }

  @include states {

    outline: none;

    color: $color-text-strong;

    box-shadow: inset 0 -.125rem 0 0 $color-accent-green;

  }

  @include breakpoint(small) {

    flex-direction: row;

    padding: $unit-m 0;

  }

  @include breakpoint(medium) {

    padding-left: 1rem;
    padding-right: 1rem;

  }

}

.c-nav-header__label {

  display: block;
  margin-top: .125rem;

  height: 1rem;
  overflow: hidden;

  font-size: .75rem;
  line-height: 1.3333333333;
  color: inherit;
  text-align: center;

  transition: $transition-default;

  @include breakpoint(to-small) {

    .is-sticky & {

      opacity: 0;
      height: 0;

    }

    // Currently hiddent until we decide
    // how to solve longer label problems
    display: none;

  }

  @include breakpoint(small) {

    margin-top: 0;
    margin-left: .5rem;

    font-size: .875rem;
    line-height: 1.4285714286;

    height: auto;

    @include rtl {

      margin-left: 0;
      margin-right: .5rem;

    }

  }

  @include breakpoint(medium) {

    font-size: 1rem;
    line-height: 1.5;

  }

  @include breakpoint(large) {

    font-size: 1.125rem;
    line-height: 1.3333333333;

  }

}

.c-nav-header__value {

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1rem;
  height: 1rem;

  font-size: .875rem;
  font-family: $font-body;
  line-height: 1;
  font-weight: $bold;
  color: $color-text-strong-invert;
  text-align: center;
  background-color: darken($color-accent-red, 20%);
  border-radius: 50%;

  @include breakpoint(to-medium) {

    position: absolute;
    top: $unit-xs;
    right: $unit-xs;

  }

  @include breakpoint(small) {

    width: 1.125rem;
    height: 1.125rem;

  }

  @include breakpoint(medium) {

    margin-left: $unit-xs;

    @include rtl {

      margin-left: 0;
      margin-right: $unit-xs;

    }

  }

  @include breakpoint(large) {

    width: 1.25rem;
    height: 1.25rem;

  }

}

.c-nav-header__link.is-active {

  color: $color-primary-dark;
  box-shadow: inset 0 -.125rem 0 0 $color-accent-green;

}

/*
 * Home icon
 *
 * We do couple of things here:
 * - SVG icon's contents are rendered transparent
 * - We load a SVG backgroudn image where the icon is
 * - We scale the icon with transform and animate the transition
 */

.c-nav-header__item--home {

  .o-icon {

    transition: background .1s $transition-timing-cubic;
    transition: fill 0 $transition-timing-cubic;

    background-repeat: no-repeat;
    background-size: 0 0;
    background-position: center center;

  }

  .is-sticky & {

    .o-icon {

      // Sax has a problem with compile if `position: relative` (or any style) is above background
      // so we are keeping it below in this reference.
      background-image: assets-path('/images/gmv-symbol-color.svg');

      background-size: 1.5rem 1.5rem;

      fill: transparent;

      transform: scale(1.1);

    }

  }

}
