/* =Components: Marketing blocks
===================================================== */

.c-m-block--dark {

  background-color: $color-primary-dark;

}

.c-m-block--light {

  background-color: $color-primary-light;

}

.c-m-block--hero {

  background: $color-primary-light assets-path('/images/marketing/illustration-skyline.svg') repeat-x 50% 50%;

}

.c-m-block--cta {

  .l-container {

    text-align: center;

    height: 100vh;
    max-height: 24rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium) {

      max-height: 48rem;

    }

  }

}

.c-m-block--cta {

  // sax issue #2191
  // background: assets-path('/images/marketing/background-people-montage.jpg') repeat 50% 50%;

  // Sax has a problem with compile if `position: relative` (or any style) is above background
  // so we are keeping it below in this reference.
  position: relative;

  background-size: cover;

  @include breakpoint(extra-large) {

    background-size: contain;

  }

  &:before {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($color-grey-dark, .5);

    content: "";

  }

}

.c-m-block__title {

  margin-bottom: $unit-l;

  font-size: ms(2);
  font-weight: $semibold;
  text-align: center;
  letter-spacing: -.025em;

  @include breakpoint(small) {

    font-size: ms(3);

  }

  @include breakpoint(medium) {

    font-size: ms(4);

  }

  @include breakpoint(large) {

    font-size: ms(5);

  }

  .c-m-block--dark & {

    color: $color-text-strong-invert;
    text-shadow: 0 .125em .5em rgba(0,0,0,.5);

  }

}

.c-m-block__action {

  margin-top: $unit-l;

}