/* =Components: Buttons
===================================================== */

/*
 * Buttons
 *
 * Default button styling
 */

button {

  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

}

.c-button {

  position: relative;

  display: inline-block;
  margin: 0;
  padding: .625rem 1rem;
  border: .125rem solid transparent;

  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-headline;
  font-weight: $semibold;
  vertical-align: middle;
  text-align: center;
  text-transform: none;

  color: $color-text;
  background-color: $color-button-regular;

  border-radius: $border-radius-small;
  transition: all .1s ease-in;

  cursor: pointer;

  &:focus {

    box-shadow: 0 0 0 .1875rem darken($color-primary-light, 10%);
    outline: none;

  }

  @include rtl {

    .o-icon-arrow-right {

      transform: scaleX(-1);

    }

  }

  @include states {

    color: $color-text-strong;
    background-color: $color-button-regular-hover;
    opacity: 1;  // For legacy reasons

    .o-icon-arrow-right {

      left: .125rem;

      @include rtl {

        left: 0;
        right: .125rem;

      }

    }

  }

  &:active {

    border: .125rem solid transparent;

  }

  // Future proof
  // Remove focus style on clicked buttons
  // https://css-tricks.com/a-complete-guide-to-links-and-buttons/
  &:focus:not(:focus-visible) {

    outline: 0;

  }

  .o-icon {

    position: relative;
    top: .125rem;
    left: 0;
    margin-left: .25rem;
    margin-right: .25rem;

    color: inherit;
    transition: all .1s ease-in;

  }

  &[disabled],
  &.is-disabled {

    opacity: .2;
    pointer-events: none;

  }

  &.c-button--number {

    min-width: 3rem;

  }

}

.c-button__label {

  color: inherit;

}


/*
 * Modifiers
 *
 * Different button sizes and styles
 */

.c-button--outline {

  border-color: $color-button-primary;
  background-color: transparent;
  color: $color-button-primary;

  @include states {

    color: $color-text-strong-invert;
    background-color: $color-button-primary-hover;
    border-color: transparent;

  }

}

.c-button--primary {

  background-color: $color-button-primary;
  color: $color-text-strong-invert;

  @include states {

    background-color: $color-button-primary-hover;
    color: $color-text-strong-invert;

  }

}

.c-button--alternate {

  background-color: $color-button-alternate;
  color: $color-text-strong;

  @include states {

    background-color: $color-button-alternate-hover;
    color: $color-text-strong;

  }

  &:focus {

    box-shadow: 0 0 0 .1875rem lighten($color-accent-green, 20%);

  }

}

.c-button--attention {

  background-color: $color-accent-red;
  color: #fff;

  @include states {

    background-color: darken($color-accent-red, 10%);
    color: #fff;

  }

  &:focus {

    box-shadow: 0 0 0 .1875rem lighten($color-accent-red, 20%);

  }

}

.c-button--pill {

  border-radius: 1.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &.c-button--number {

    padding-left: .25rem;
    padding-right: .25rem;

  }

}



.c-button--small {

  padding: .25rem .75rem;

  font-size: .875rem;
  line-height: 1.4285714286;

  .o-icon {

    position: relative;
    top: 0;
    height: 1.25rem;

    vertical-align: top;
    font-size: 1rem;

  }

  &.c-button--number {

    min-width: 2rem;

  }

}

.c-button--large {

  @include breakpoint(medium) {

    padding: .8125rem 1rem;

    font-size: 1.125rem;
    line-height: 1.4444444444;

    &.c-button--number {

      min-width: 3.5rem;

    }

    &.c-button--pill {

      padding-left: 1.75rem;
      padding-right: 1.75rem;

      border-radius: 1.75rem;

    }

  }

}

.c-button--block {

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(extra-small) {

    width: auto;
    min-width: 16rem;

  }

  @include breakpoint(small) {

    min-width: 20rem;

  }

}

.c-button--link {

  background-color: transparent;

  @include states {

    background-color: transparent;

  }

}

.c-button--details {

  display: flex;
  flex-shrink: 0;
  align-items: center;

  width: 100%;

  text-align: left;

  small {

    display: block;
    font-weight: $regular;

  }

  .o-label {

    margin-right: $unit-m;

  }

  .o-icon {

    margin-left: auto;

  }

  @include rtl {

    direction: rtl;

    text-align: right;

    .o-label {

      margin-right: 0;
      margin-left: $unit-m;

    }

    .o-icon {

      margin-left: 0;
      margin-right: auto;

      transform: rotate(180deg);

    }

  }

}