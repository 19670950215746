/* =Forms
===================================================== */

/*
 * Fieldset
 */

fieldset {

  border: none;
  margin: 0;
  padding: 0;

}

/*
 * Inputs
 */

#{$all-text-inputs} {

  /* Positioning */

  /* Box-model */
  display: block;
  width: 100%;
  padding: .6875rem .9375rem;
  margin: 0;
  // box-shadow: inset 0 0 0 .0625rem $color-input-regular;
  border: .0625rem solid $color-input-regular;

  /* Typography */
  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text-strong;

  /* Visual */
  background-color: #fff;
  border-radius: $border-radius-small;

  /* Misc */
  transition: all .1s ease-in;
  appearance: none;

  &:disabled {

    color: $color-text;
    background-color: $color-input-regular;
    border-color: rgba(255,255,255,0);
    box-shadow: none;

  }

}

#{$all-text-inputs-focus},
#{$all-text-inputs-hover} {

  outline: none;
  border-color: $color-input-focus;
  color: $color-text-strong;
  box-shadow: inset 0 0 0 .0625rem $color-input-focus;

  &:disabled {

    color: $color-text;
    background-color: $color-input-regular;
    border-color: rgba(255,255,255,0);
    box-shadow: none;


  }

}

#{$all-text-inputs-active} {

}


/*
 * Textarea
 */

textarea {

  resize: vertical;
  min-height: 8rem;
  display: block;

}

/*
 * Select
 */

select {

  /* Box-model */
  display: block;
  height: 3rem;
  width: 100%;
  padding: .625rem 1rem;
  margin: 0;
  border: none;
  box-shadow: inset 0 0 0 .125rem $color-input-regular;

  /* Typography */
  font-family: $font-body;
  font-size: 1rem;
  line-height: 1.5;
  color: $color-text;

  /* Visual */
  background-color: #fff;
  border-radius: $border-radius-small;

  /* Misc */
  cursor: pointer;

}