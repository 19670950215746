/* =Components: Reorder
===================================================== */

/*
 * Reorder
 *
 * Reorder component that uses choice reorder items.
 */

.c-reorder {

  margin-top: auto;
  margin-bottom: auto;

  @include rtl {

    direction: rtl;

  }

}

.c-reorder__label-top,
.c-reorder__label-bottom {

  display: flex;
  padding: $unit-s $unit-m;

  font-size: .75rem;
  line-height: 1.5;
  text-align: left;
  color: $color-text-weak;

  background-color: $color-primary-light;
  border-radius: $border-radius-small;

  @include rtl {

    text-align: right;
    direction: rtl;

  }

  @include breakpoint(small) {

    font-size: .875rem;

  }

}

.c-reorder__label-top {

  color: darken($color-accent-green, 10%);
  background-color: lighten($color-accent-green, 20%);

}

.c-reorder__label-bottom {

  color: darken($color-accent-red, 10%);
  background-color: lighten($color-accent-red, 20%);

}

.c-reorder__header {

  margin-top: 0;
  margin-bottom: $unit-m;

  @include breakpoint(to-medium-height) {

    @include hide-visually();
    margin-bottom: 0;

  }

}

.c-reorder__label {

  font-family: $font-body;
  font-size: .75rem; // 12
  line-height: 1.6666666667; // 20/12
  color: $color-text-weak;
  font-weight: $regular;
  text-align: center;

  @include breakpoint(small) {

    font-size: .875rem;

  }

}